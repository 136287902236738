import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components";

import { ActionPermissionsObject, IWorkspaceUserExtended, IWorkspaceUserInvite, LegacyWorkspaceGroupIds, PermissionActionType, TeamMemberRole, TeamMemberRoleLabels, WorkspaceUserRole } from "common/interfaces";
import { Button } from "js/Components/Button";
import { Icon } from "js/Components/Icon";
import { List, ListHeaderLine, ListHeaderSortable, ListLine, ListLineCellInfoText, ListLineDropdownCell, NoResultsListLine } from "js/Components/List";
import { SearchBar } from "js/Components/SearchBar";
import BillingController, { BillingControllerState } from "js/controllers/BillingController";
import WorkspaceController, { WorkspaceControllerState } from "js/controllers/WorkspaceController";
import getLogger, { LogGroup } from "js/core/logger";
import withProgressDialog from "js/core/utilities/withProgressDialog";
import { auth } from "js/firebase/auth";
import { ShowConfirmationDialog, ShowDialog, ShowDialogAsync, ShowErrorDialog, ShowSnackBar } from "js/react/components/Dialogs/BaseDialog";

import { AddSeatsDialog } from "./AddSeatsDialog";
import { Profile } from "./Profile";
import { RemoveUserFromWorkspaceDialog } from "./RemoveUserFromWorkspaceDialog";
import { ResendInviteDialog } from "./ResendInviteDialog";

const logger = getLogger(LogGroup.TEAMS);

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const ToolsContainer = styled.div`
    width: 100%;
    height: 43px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: flex-end;
    padding: 0 20px;
`;

function UserLine(props: { user: IWorkspaceUserExtended }) {
    const { user } = props;

    const canManageUsers =
        WorkspaceController.actionPermissions[ActionPermissionsObject.BILLING][PermissionActionType.MANAGE] &&
        WorkspaceController.actionPermissions[ActionPermissionsObject.USERS][PermissionActionType.MANAGE];

    const legacyRole = WorkspaceController.getUserLegacyRole(user);
    const rolesDropdownDisabled = !canManageUsers || user.uid === auth().currentUser.uid || legacyRole === TeamMemberRole.MEMBER;
    const licenseDropdownDisabled = !canManageUsers || user.uid === auth().currentUser.uid;
    const displayName = user.displayName ?? user.email;

    const handleRoleDropdownChange = async (value: TeamMemberRole) => {
        const accepted = await ShowConfirmationDialog({
            title: `Do you want to change ${displayName}'s role to ${TeamMemberRoleLabels[value]}?`,
            message: value === TeamMemberRole.LIBRARIAN
                ? "They will be able add and manage slide, presentations, themes, and assets for the team."
                : value === TeamMemberRole.OWNER
                    ? "They will be able to manage payment info, add/remove members, add/remove seats, and change team permissions on resources."
                    : "They will only have access to shared team content."
        });
        if (!accepted) {
            return;
        }

        withProgressDialog({
            title: "Updating member...",
            message: "Please wait while we update the member...",
            action: async () => {
                try {
                    const role = value === TeamMemberRole.OWNER
                        ? WorkspaceUserRole.OWNER
                        : value === TeamMemberRole.MEMBER
                            ? WorkspaceUserRole.GUEST
                            : WorkspaceUserRole.MEMBER;
                    const groupIds = value === TeamMemberRole.LIBRARIAN
                        ? [LegacyWorkspaceGroupIds.LIBRARIANS]
                        : [];

                    await WorkspaceController.updateWorkspaceUser({ uid: user.uid, update: { role, groupIds } });

                    ShowSnackBar({ message: `Member ${displayName} updated to ${TeamMemberRoleLabels[value]}` });
                } catch (err) {
                    logger.error(err, "[UserLine] Failed to update member");

                    ShowErrorDialog({
                        title: "Failed to update member",
                        message: err.message
                    });
                }
            }
        });
    };

    const handleLicenseDropdownChange = async (value: "remove" | "free" | "pro") => {
        if (value === "remove") {
            ShowDialog(RemoveUserFromWorkspaceDialog, { user });
            return;
        }

        if (value === "free") {
            const accepted = await ShowConfirmationDialog({
                title: "Are you sure?",
                message: `By downgrading to a free team member, ${user.id === auth().currentUser.uid ? "You" : (displayName)} will lose access to Pro features and your team's shared resources, including themes, assets, and templates. The vacant Team Pro seat will be available to assign to another member.`
            });
            if (!accepted) {
                return;
            }
        }

        if (value === "pro" && BillingController.availableSeatCount === 0) {
            await ShowDialogAsync(AddSeatsDialog);
        }

        if (value === "pro" && BillingController.availableSeatCount === 0) {
            return;
        }

        withProgressDialog({
            title: "Updating member...",
            message: "Please wait while we update the member...",
            action: async () => {
                try {
                    const role = value === "free" ? WorkspaceUserRole.GUEST : WorkspaceUserRole.MEMBER;
                    const groupIds = [];

                    await WorkspaceController.updateWorkspaceUser({ uid: user.uid, update: { role, groupIds } });

                    ShowSnackBar({ message: `Member ${displayName} updated to "${value}"` });
                } catch (err) {
                    logger.error(err, "[UserLine] Failed to update member");

                    ShowErrorDialog({
                        title: "Failed to update member",
                        message: err.message
                    });
                }
            }
        });
    };

    return (<ListLine data-test-id={`user-line-${user.email}`}>
        <Profile email={user.email}
            displayName={user.displayName}
            photoURL={user.photoURL}
        />
        <ListLineDropdownCell data-test-id={"role-dropdown"}
            value={legacyRole}
            disabled={rolesDropdownDisabled}
            items={rolesDropdownDisabled
                ? [{ label: TeamMemberRoleLabels[legacyRole], value: legacyRole }]
                : Object.entries(TeamMemberRoleLabels).map(([value, label]) => ({
                    label,
                    value
                    // @ts-ignore
                })).filter(({ value }) => value !== TeamMemberRole.MEMBER || value === legacyRole)}
            onChange={handleRoleDropdownChange}
        />
        <div></div>
        <ListLineDropdownCell data-test-id={"license-dropdown"}
            value={user.role === WorkspaceUserRole.GUEST ? "free" : "pro"}
            disabled={licenseDropdownDisabled}
            items={[
                { label: "Free", value: "free", disabled: legacyRole === TeamMemberRole.LIBRARIAN },
                { label: "Team Pro", value: "pro", disabled: legacyRole === TeamMemberRole.LIBRARIAN || (!BillingController.hasPaymentMethod && BillingController.availableSeatCount === 0) },
                { divider: true },
                { label: "Remove Member", value: "remove" }
            ]}
            onChange={handleLicenseDropdownChange}
        />
        <ListLineCellInfoText>{user.lastActive ? moment(user.lastActive).format("MMM D, YYYY") : "never"}</ListLineCellInfoText>
        <div></div>
    </ListLine>);
}

const ResendInviteButton = styled(Button)`
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
`;

const UserInviteListLine = styled(ListLine)`
    &:hover {
        ${ResendInviteButton} {
            opacity: 1;
        }
    }
`;

function UserInviteLine(props: { invite: IWorkspaceUserInvite }) {
    const { invite } = props;

    const canManageUsers =
        WorkspaceController.actionPermissions[ActionPermissionsObject.BILLING][PermissionActionType.MANAGE] &&
        WorkspaceController.actionPermissions[ActionPermissionsObject.USERS][PermissionActionType.MANAGE];

    const legacyRole = WorkspaceController.getUserLegacyRole(invite);

    const handleResendInvite = () => {
        ShowDialog(ResendInviteDialog, { invite });
    };

    const handleLicenseDropdownChange = async (value: string) => {
        if (value === "revoke") {
            withProgressDialog({
                title: "Revoking invite...",
                message: "Please wait while we revoke the invite...",
                action: async () => {
                    try {
                        await WorkspaceController.deleteUserInvite({ inviteId: invite.id });

                        ShowSnackBar({ message: `Invitation Revoked for ${invite.email}` });
                    } catch (err) {
                        logger.error(err, "[UserInviteLine] Failed to delete invite");

                        ShowErrorDialog({
                            title: "Failed to delete invite",
                            message: err.message
                        });
                    }
                }
            });

            return;
        }

        if (value === "free") {
            const accepted = await ShowConfirmationDialog({
                title: "Are you sure?",
                message: `By downgrading to a free team member, ${invite.email} will not have access to Pro features and your team's shared resources, including themes, assets, and templates. The vacant Team Pro seat will be available to assign to another member.`
            });
            if (!accepted) {
                return;
            }
        }

        if (value === "pro" && BillingController.availableSeatCount === 0) {
            await ShowDialogAsync(AddSeatsDialog);
        }

        if (value === "pro" && BillingController.availableSeatCount === 0) {
            return;
        }

        withProgressDialog({
            title: "Updating invite...",
            message: "Please wait while we update the invite...",
            action: async () => {
                try {
                    await WorkspaceController.updateUserInvite({ inviteId: invite.id, update: { role: value === "pro" ? WorkspaceUserRole.MEMBER : WorkspaceUserRole.GUEST } });

                    ShowSnackBar({ message: `Invite updated for ${invite.email}` });
                } catch (err) {
                    logger.error(err, "[UserInviteLine] Failed to update invite");

                    ShowErrorDialog({
                        title: "Failed to update invite",
                        message: err.message
                    });
                }
            }
        });
    };

    return (<UserInviteListLine data-test-id={`user-invite-line-${invite.email}`}>
        <Profile email={invite.email} />
        <ListLineDropdownCell data-test-id={"role-dropdown"}
            value={legacyRole}
            disabled
            items={[{ label: TeamMemberRoleLabels[legacyRole], value: legacyRole }]}
            onChange={() => { }}
        />
        <ListLineCellInfoText>pending invite</ListLineCellInfoText>
        <ListLineDropdownCell data-test-id={"license-dropdown"}
            value={invite.role === WorkspaceUserRole.GUEST ? "free" : "pro"}
            items={[
                { label: "Free", value: "free", disabled: legacyRole === TeamMemberRole.LIBRARIAN },
                { label: "Team Pro", value: "pro", disabled: legacyRole === TeamMemberRole.LIBRARIAN || (!BillingController.hasPaymentMethod && BillingController.availableSeatCount === 0) },
                { divider: true },
                { label: "Revoke Invite", value: "revoke" }
            ]}
            onChange={handleLicenseDropdownChange}
            disabled={!canManageUsers}
        />
        <ListLineCellInfoText>never</ListLineCellInfoText>
        <div>
            {canManageUsers && (<ResendInviteButton blue
                onClick={handleResendInvite}
            >
                Resend Invite
            </ResendInviteButton>)}
        </div>
    </UserInviteListLine>);
}

export const UsersList = WorkspaceController.withInitializedState(BillingController.withState(
    function UsersList(props: WorkspaceControllerState & BillingControllerState) {
        const { users, userInvites } = props;

        const canManageUsers =
        WorkspaceController.actionPermissions[ActionPermissionsObject.BILLING][PermissionActionType.MANAGE] &&
        WorkspaceController.actionPermissions[ActionPermissionsObject.USERS][PermissionActionType.MANAGE];

        const [searchQuery, setSearchQuery] = useState("");
        const [sort, setSort] = useState<"asc" | "desc">("asc");
        const [sortBy, setSortBy] = useState<"name" | "role" | "license" | "lastActive">("name");

        const handleSearchBarChange = (query: string) => {
            setSearchQuery(query);
        };

        const handleHeaderClick = (field: "name" | "role" | "license" | "lastActive") => {
            if (sortBy === field) {
                setSort(sort === "asc" ? "desc" : "asc");
            } else {
                setSort("asc");
                setSortBy(field);
            }
        };

        const handleDownloadUsers = () => {
            WorkspaceController.downloadUsersAndInvitesList();
        };

        const list = [
            ...users.map(user => ({ displayName: undefined, email: undefined, ...user, isInvite: false })),
            ...userInvites.map(invite => ({ displayName: undefined, email: undefined, ...invite, isInvite: true }))
        ]
            .map(item => ({
                ...item,
                name: `${item.displayName ?? ""} ${item.email ?? ""}`
            }))
            .filter(item => !searchQuery || item.name.toLowerCase().includes(searchQuery.toLowerCase()))
            .sort((a, b) => {
                if (sortBy === "name") {
                    return sort === "asc" ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
                }

                if (sortBy === "role") {
                    const roleA = TeamMemberRoleLabels[WorkspaceController.getUserLegacyRole(a)];
                    const roleB = TeamMemberRoleLabels[WorkspaceController.getUserLegacyRole(b)];
                    return sort === "asc" ? roleA.localeCompare(roleB) : roleB.localeCompare(roleA);
                }

                if (sortBy === "license") {
                    const licenseA = a.role === WorkspaceUserRole.GUEST ? "free" : "pro";
                    const licenseB = b.role === WorkspaceUserRole.GUEST ? "free" : "pro";
                    return sort === "asc" ? licenseA.localeCompare(licenseB) : licenseB.localeCompare(licenseA);
                }

                if (sortBy === "lastActive") {
                    const lastActiveA = a["lastActive"] ? moment(a["lastActive"]).unix() : 0;
                    const lastActiveB = b["lastActive"] ? moment(b["lastActive"]).unix() : 0;
                    return sort === "asc" ? lastActiveA - lastActiveB : lastActiveB - lastActiveA;
                }

                return 0;
            });

        return (<Container>
            <ToolsContainer>
                <SearchBar
                    placeholder="Search members..."
                    onChange={handleSearchBarChange}
                />
                {canManageUsers && (<Icon large
                    outlined={false}
                    onClick={handleDownloadUsers}
                >
                    download
                </Icon>)}
            </ToolsContainer>
            <List gridTemplateColumns="minmax(0, 2fr) 1fr 1fr 1fr 1fr 160px">
                <ListHeaderLine>
                    <ListHeaderSortable
                        sort={sort}
                        active={sortBy === "name"}
                        onClick={() => handleHeaderClick("name")}
                    >
                        Member
                    </ListHeaderSortable>
                    <ListHeaderSortable
                        sort={sort}
                        active={sortBy === "role"}
                        onClick={() => handleHeaderClick("role")}
                    >
                        Role
                    </ListHeaderSortable>
                    <div></div>
                    <ListHeaderSortable
                        sort={sort}
                        active={sortBy === "license"}
                        onClick={() => handleHeaderClick("license")}
                    >
                        License
                    </ListHeaderSortable>
                    <ListHeaderSortable
                        sort={sort}
                        active={sortBy === "lastActive"}
                        onClick={() => handleHeaderClick("lastActive")}
                    >
                        Last Active
                    </ListHeaderSortable>
                    <div></div>
                </ListHeaderLine>
                {list.map(item => (
                    <>
                        {item.isInvite &&
                        <UserInviteLine
                            invite={item as IWorkspaceUserInvite}
                            key={item.id}
                        />
                        }
                        {!item.isInvite &&
                        <UserLine
                            user={item as IWorkspaceUserExtended}
                            key={item.id}
                        />
                        }
                    </>
                ))}
                {list.length === 0 && <NoResultsListLine />}
            </List>
        </Container>);
    }
));
