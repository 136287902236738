import React, { Component } from "react";

import { AnalyticsRolesAndLicensesMap, TEAM_USER_LICENSE_STATUS, TEAM_USER_ROLES } from "common/constants";
import { ActionPermissionsObject } from "common/interfaces";
import { isDevelopment, isPPTAddin } from "js/config";
import WorkspaceController from "js/controllers/WorkspaceController";
import Api from "js/core/api";
import AppController from "js/core/AppController";
import getLogger from "js/core/logger";
import { ds } from "js/core/models/dataService";
import { Key } from "js/core/utilities/keys";
import { getQueryVariables, trackActivity } from "js/core/utilities/utilities";
import { AnimatedFullScreenContainer } from "js/editor/components/AnimatedFullScreenContainer";
import { app } from "js/namespaces";
import {
    ShowErrorDialog
} from "js/react/components/Dialogs/BaseDialog";
import { Gap20 } from "js/react/components/Gap";
import LogOutButton from "js/react/components/LogOutButton";
import {
    UINavigation,
    UINavigationItem,
    UINavigationItemHeader
} from "js/react/components/UiComponents";
import { $ } from "js/vendor";

import {
    AccountPane,
    AdvancedSettingPane,
    ApiPane,
    BillingPane,
    IntegrationsPane,
    ManageTeamPane,
    NotificationsPane,
    PreferencesPane,
    ReferralsPane
} from "./panes";

import "css/user-options.scss";

const logger = getLogger();

export function getUsedSeatCount(teamMembers) {
    let count = 0;
    teamMembers.forEach(member => {
        if (member.hasSeat) {
            count++;
        } else if (member.pending && member.license === TEAM_USER_LICENSE_STATUS.TEAM_PRO) {
            count++;
        }
    });
    return count;
}

class UserOptionsContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedPaneId: "profile",
            isOpen: true,
            paneClicked: 0,
            ...UserOptionsContainer.getFeatures(props.workspaceId),
            returnId: getQueryVariables("returnId"),
            slideIndex: getQueryVariables("slideIndex")
        };

        $(window).on("keydown", event => {
            if (event.which == Key.ESCAPE) {
                this.handleBackClicked();
            }
        });
    }

    async componentDidMount() {
        const { pane } = this.props;
        if (pane) {
            this.setState({ selectedPaneId: pane });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.pane !== prevProps.pane) {
            this.setState({ selectedPaneId: this.props.pane });
        }

        const { hasTeams, selectedPaneId, canManageAdvancedSettings, canManageIntegrations, canManageApi, canManageBilling } = this.state;
        if (selectedPaneId !== prevState.selectedPaneId) {
            switch (selectedPaneId) {
                case "manage-default-team":
                    if (!hasTeams) {
                        this.selectPane("profile");
                    }
                    break;
                case "billing":
                case "team-billing":
                    if (!canManageBilling) {
                        this.selectPane("profile");
                    }
                    break;
                case "integrations":
                    if (!canManageIntegrations) {
                        this.selectPane("profile");
                    }
                    break;
                case "advanced-settings":
                    if (!canManageAdvancedSettings) {
                        this.selectPane("profile");
                    }
                    break;
                case "api":
                    if (!canManageApi) {
                        this.selectPane("profile");
                    }
                    break;
            }
        }
    }

    static getFeatures(workspaceId) {
        return {
            hasTeams: WorkspaceController.isMultiUserWorkspace,
            canManageBilling: WorkspaceController.actionPermissions[ActionPermissionsObject.BILLING].manage,
            canManageUsers: WorkspaceController.actionPermissions[ActionPermissionsObject.USERS].manage,
            canManageApi: isDevelopment,
            canManageIntegrations: WorkspaceController.actionPermissions[ActionPermissionsObject.INTEGRATIONS].manage,
            canManageAdvancedSettings: WorkspaceController.actionPermissions[ActionPermissionsObject.USER_GROUPS].manage && WorkspaceController.actionPermissions[ActionPermissionsObject.PLAYER_SETTINGS].manage,
        };
    }

    handleBackClicked = () => {
        const { selectedPaneId, returnId, slideIndex } = this.state;
        const { closeDialog } = this.props;
        if (isPPTAddin) {
            closeDialog();
        } else {
            if (selectedPaneId === "manage-group") {
                this.setState({ selectedPaneId: "groups" });
            } else {
                if (returnId) {
                    AppController.showEditor({ presentationId: returnId, slideIndex: slideIndex ?? 0 });
                } else {
                    AppController.showLibrary();
                }
            }
        }
    };

    selectPane = pane => {
        if (isPPTAddin) {
            this.setState({ selectedPaneId: pane });
        } else {
            AppController.showAccountPane({ pane });
        }
    };

    handleManageGroupClicked = id => {
        this.setState({
            selectedPaneId: "manage-group",
            selectedPaneProps: {
                teamId: id
            }
        });
    };

    removeMember = async (adminMembers, team, user, childPane, onRemove, isDefaultTeam, allMembers, isRemovingSelf, newOwnerId, numPresentationsTransferred, isTransferringOwner, shouldRemovePresentations) => {
        const orgId = AppController.orgId;

        const teamId = team.id;
        if (isDefaultTeam && adminMembers.length === 1 && user.role.toLowerCase() === TEAM_USER_ROLES.OWNER) {
            ShowErrorDialog({
                error: "Unable to remove yourself from the team",
                message: "There needs to be one more admin in the team."
            });
            return;
        }
        try {
            const backboneTeam = ds.teams.get(teamId);
            if (backboneTeam && isRemovingSelf) {
                backboneTeam.disconnect();
            }
            await Api.teams.put({
                orgId,
                teamId: teamId,
                type: "remove_users",
                shouldRemovePresentations,
                userIds: [user.id]
            });

            if (isDefaultTeam) {
                const props = {
                    workspace_id: orgId,
                    affected_userid: user.id,
                    affected_user_role: AnalyticsRolesAndLicensesMap[user.role],
                    affected_user_license: AnalyticsRolesAndLicensesMap[user.license],
                    affected_user_presentations_owned: numPresentationsTransferred,
                    presentations_transferred_to_personal: isTransferringOwner ? 0 : numPresentationsTransferred,
                    presentations_transferred_to_team_member: isTransferringOwner ? numPresentationsTransferred : 0,
                    presentation_recipient_user_id: newOwnerId
                };
                trackActivity("Organization", "UserRemoved", null, null, props, { audit: true });
            } else {
                const props = {
                    workspace_id: orgId,
                    team_id: team.id,
                    team_name: team.name,
                    userid_added: null,
                    userid_removed: user.id,
                    num_team_members: allMembers.length - 1,
                    num_changed: -1,
                    change_mechanism: isRemovingSelf ? "user initiated" : "admin initiated"
                };
                trackActivity("OrgTeam", "MembershipChanged", null, null, props, { audit: true });
            }
            if (user.id === app.user.id && user.role.toLowerCase() !== TEAM_USER_ROLES.OWNER) {
                if (childPane === "manage") {
                    this.selectPane("groups");
                    return;
                }
            }
            onRemove && onRemove();
        } catch (err) {
            logger.error(err, "UserOptionsContainer removeMember() failed", { teamId, orgId, userId: user.id });
            ShowErrorDialog({
                error: "Failed to remove team invite",
                message: "Something went wrong. Please try again and reach out to support@beautiful.ai if the issue persists."
            });
        }
    };

    renderSelectedPane = () => {
        const { workspaceId } = this.props;
        const { selectedPaneId, selectedPaneProps, canManageAdvancedSettings, canManageBilling, canManageApi, canManageIntegrations, hasTeams } = this.state;

        switch (selectedPaneId) {
            case "billing":
            case "team-billing":
                if (!canManageBilling) {
                    return null;
                }
                return <BillingPane />;
            case "manage-default-team":
                if (!hasTeams) {
                    return null;
                }
                return (
                    <ManageTeamPane />
                );
            case "preferences":
                return <PreferencesPane {...selectedPaneProps} />;
            case "integrations":
                if (!canManageIntegrations) {
                    return null;
                }
                return <IntegrationsPane />;
            case "advanced-settings":
                if (!canManageAdvancedSettings) {
                    return null;
                }
                return <AdvancedSettingPane />;
            case "api":
                if (!canManageApi) {
                    return null;
                }
                return <ApiPane />;
            case "notifications":
                return <NotificationsPane workspaceId={workspaceId} />;
            case "referrals":
                return <ReferralsPane />;
            case "profile":
            default:
                return (
                    <AccountPane
                        onClose={app.isConstrained && this.handleBackClicked}
                        userMenu={this.props.userMenu}
                        {...selectedPaneProps}
                    />
                );
        }
    };

    handleLibrarySlideSelected = libraryItem => {
        this.setState({
            selectedPaneId: "library-slide",
            selectedPaneProps: {
                libraryItemId: libraryItem.id
            }
        });
    };

    handleLibrarySlideClose = () => {
        this.setState({
            selectedPaneId: "teams-libraries"
        });
    }

    render() {
        const { workspace, workspaceId, classes } = this.props;
        const { selectedPaneId, hasTeams, canManageUsers, canManageIntegrations, canManageApi, canManageBilling, canManageAdvancedSettings } = this.state;

        return (
            <>
                <AnimatedFullScreenContainer
                    isOpen={true}
                    className={`user-options ${classes}`}
                >

                    {
                        !app.isConstrained &&
                        <UINavigation
                            selected={selectedPaneId}
                            onSelect={id => {
                                this.selectPane(id);
                            }}
                            onBack={this.handleBackClicked}
                            fullHeight
                        >
                            <UINavigationItem
                                id="me"
                                title="My Account"
                                icon="person"
                            />
                            <UINavigationItem
                                id="preferences"
                                title="Preferences"
                                icon="settings"
                            />
                            {!isPPTAddin && (
                                <UINavigationItem
                                    id="notifications"
                                    title="Notifications"
                                    icon="notifications"
                                />
                            )}
                            {!isPPTAddin && canManageApi && (
                                <UINavigationItem
                                    id="api"
                                    title="API"
                                    icon="api"
                                />
                            )}
                            {!isPPTAddin && workspaceId === "personal" && (
                                <UINavigationItem
                                    id="billing"
                                    title="Billing"
                                    icon="credit_card"
                                />
                            )}
                            {!isPPTAddin && workspaceId === "personal" && (
                                <UINavigationItem
                                    id="referrals"
                                    title="Referrals"
                                    icon="grade"
                                />
                            )}
                            <Gap20 />
                            {hasTeams && (
                                <div className="workspace">
                                    <div className="title">TEAM</div>
                                    <div className="label">{workspace.name}</div>
                                </div>
                            )}
                            {!isPPTAddin && (
                                <UINavigationItemHeader title="" fullHeight>
                                    {hasTeams && (
                                        <UINavigationItem
                                            id="manage-default-team"
                                            title={canManageUsers ? "Manage Team" : "View Team"}
                                            icon="how_to_reg"
                                        />
                                    )}
                                    {hasTeams && canManageBilling && (
                                        <UINavigationItem
                                            id="team-billing"
                                            title="Billing"
                                            icon="credit_card"
                                        />
                                    )}
                                    {canManageIntegrations && (
                                        <UINavigationItem
                                            id="integrations"
                                            title="Integrations"
                                            icon="extension"
                                        />
                                    )}
                                    {canManageAdvancedSettings && (
                                        <UINavigationItem
                                            id="advanced-settings"
                                            title="Advanced"
                                            icon="tune"
                                        />
                                    )}
                                </UINavigationItemHeader>
                            )}
                            {!isPPTAddin && <LogOutButton />}
                        </UINavigation>
                    }
                    {this.renderSelectedPane()}
                </AnimatedFullScreenContainer>
            </>
        );
    }
}

export default AppController.withState(WorkspaceController.withInitializedState(UserOptionsContainer));
