import React, { useEffect, useState } from "react";

import {
    LegacyWorkspaceGroupIds,
    ActionPermissionsObject,
    PermissionActionType,
} from "common/interfaces";
import { Section } from "js/react/components/UiComponents";
import { BlueSwitch } from "js/react/components/Switch";
import { ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import getLogger, { LogGroup } from "js/core/logger";

import WorkspaceController, { type WorkspaceControllerState } from "js/controllers/WorkspaceController";

const logger = getLogger(LogGroup.WORKSPACES);

export default function ContentDistributionSettings({ userGroups }: WorkspaceControllerState) {
    const allMembersGroup = userGroups.find(group => group.id === LegacyWorkspaceGroupIds.MEMBERS);
    const permissions = allMembersGroup.actionPermissions;

    const [isFetching, setIsFetching] = useState(false);

    const [prohibitExternalWorkspaceMovement, setProhibitExternalWorkspaceMovement] = useState(!permissions[ActionPermissionsObject.EXTERNAL_WORKSPACES_RESOURCE_TRANSFER][PermissionActionType.USE]);
    const [prohibitExternalWorkspaceCollaboration, setProhibitExternalWorkspaceCollaboration] = useState(!permissions[ActionPermissionsObject.EXTERNAL_WORKSPACES_COLLABORATION][PermissionActionType.USE]);
    const [prohibitGenerativeAI, setProhibitGenerativeAI] = useState(!permissions[ActionPermissionsObject.GENERATIVE_AI][PermissionActionType.USE]);

    useEffect(() => {
        setProhibitExternalWorkspaceMovement(!permissions[ActionPermissionsObject.EXTERNAL_WORKSPACES_RESOURCE_TRANSFER][PermissionActionType.USE]);
        setProhibitExternalWorkspaceCollaboration(!permissions[ActionPermissionsObject.EXTERNAL_WORKSPACES_COLLABORATION][PermissionActionType.USE]);
        setProhibitGenerativeAI(!permissions[ActionPermissionsObject.GENERATIVE_AI][PermissionActionType.USE]);
    }, [allMembersGroup]);

    useEffect(() => {
        (async () => {
            const hasChanged = prohibitExternalWorkspaceMovement !== !permissions[ActionPermissionsObject.EXTERNAL_WORKSPACES_RESOURCE_TRANSFER][PermissionActionType.USE] ||
                prohibitExternalWorkspaceCollaboration !== !permissions[ActionPermissionsObject.EXTERNAL_WORKSPACES_COLLABORATION][PermissionActionType.USE] ||
                prohibitGenerativeAI !== !permissions[ActionPermissionsObject.GENERATIVE_AI][PermissionActionType.USE];

            if (hasChanged) {
                setIsFetching(true);

                try {
                    await WorkspaceController.updateWorkspaceUserGroups({
                        update: {
                            [LegacyWorkspaceGroupIds.MEMBERS]: {
                                actionPermissions: {
                                    [ActionPermissionsObject.EXTERNAL_WORKSPACES_RESOURCE_TRANSFER]: {
                                        [PermissionActionType.USE]: !prohibitExternalWorkspaceMovement,
                                    },
                                    [ActionPermissionsObject.EXTERNAL_WORKSPACES_COLLABORATION]: {
                                        [PermissionActionType.USE]: !prohibitExternalWorkspaceCollaboration
                                    },
                                    [ActionPermissionsObject.GENERATIVE_AI]: {
                                        [PermissionActionType.USE]: !prohibitGenerativeAI
                                    }
                                }
                            }
                        }
                    });
                } catch (err) {
                    logger.error(err, "Failed to update content distribution settings");
                    ShowErrorDialog({
                        title: "Failed to update content distribution settings",
                        message: "An error occurred while updating the content distribution settings. Please try again later.",
                    });
                } finally {
                    setIsFetching(false);
                }
            }
        })();
    }, [prohibitExternalWorkspaceMovement, prohibitExternalWorkspaceCollaboration, prohibitGenerativeAI]);

    return ( // @ts-ignore
        <Section title={"Content Distribution Settings"}>
            {/* @ts-ignore */}
            <BlueSwitch
                id="prohibitExternalWorkspaceMovement"
                disabled={isFetching}
                checked={prohibitExternalWorkspaceMovement}
                onChange={() => setProhibitExternalWorkspaceMovement(!prohibitExternalWorkspaceMovement)}
                label="Prohibit moving presentations from this workspace"
                tooltipTitle="Your team's presentations cannot be transferred to other workspaces, including Personal."
            />
            {/* @ts-ignore */}
            <BlueSwitch
                id="prohibitExternalWorkspaceCollaboration"
                disabled={isFetching}
                checked={prohibitExternalWorkspaceCollaboration}
                onChange={() => setProhibitExternalWorkspaceCollaboration(!prohibitExternalWorkspaceCollaboration)}
                label="Prohibit collaboration with users outside this workspace"
                tooltipTitle="Team Members can only invite team members to collaborate on presentations within this workspace."
            />
            {/* @ts-ignore */}
            <BlueSwitch
                id="prohibitGenerativeAI"
                disabled={isFetching}
                checked={!prohibitGenerativeAI}
                onChange={() => setProhibitGenerativeAI(!prohibitGenerativeAI)}
                label="Enable Generative AI"
                tooltipTitle="Allow your team access to generative AI features."
            />
        </Section>
    );
}
