import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import type Stripe from "stripe";
import styled from "styled-components";

import { workspaces as workspacesApi } from "apis/callables";
import BillingController, { BillingControllerState } from "js/controllers/BillingController";
import WorkspaceController, { WorkspaceControllerState } from "js/controllers/WorkspaceController";
import getLogger, { LogGroup } from "js/core/logger";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import FetchingClickShield from "js/react/components/FetchingClickShield";
import { themeColors } from "js/react/sharedStyles";
import { ChangePaymentMethodDialog } from "js/react/views/UserOptions/Billing/ChangePaymentMethodDialog";

export const logger = getLogger(LogGroup.BILLING);

const InvoiceInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    position: relative;
    width: 100%;

    background: #f1f1f1;
    font-size: 15px;
    padding: 15px 20px;
    color: #333;

    a {
        color: ${themeColors.ui_blue};
        cursor: pointer;
        text-decoration: none;
    }
`;

export interface AdditionalSeatsInvoiceInfoProps {
    additionalSeatCount: number;
}

export const AdditionalSeatsInvoiceInfo = WorkspaceController.withInitializedState(BillingController.withInitializedState(React.forwardRef(
    function AdditionalSeatsInvoiceInfo(props: AdditionalSeatsInvoiceInfoProps & WorkspaceControllerState & BillingControllerState, ref: React.Ref<any>) {
        const { workspace: { id: workspaceId }, stripeData: { subscription, paymentMethods }, additionalSeatCount, plan } = props;

        const [upcomingInvoice, setUpcomingInvoice] = useState<Stripe.UpcomingInvoice | null>(null);
        const [immediateInvoice, setImmediateInvoice] = useState<Stripe.Invoice | null>(null);
        const [invoicesFetching, setInvoicesFetching] = useState(false);

        const unmountedRef = useRef(false);

        const fetchInvoicesPromiseChain = useRef<Promise<void>>(Promise.resolve());
        const fetchInvoicesTimeout = useRef<any>(null);

        const totalQuantity = BillingController.paidSeatCount + additionalSeatCount;

        useEffect(() => {
            return () => {
                unmountedRef.current = true;
            };
        }, []);

        useEffect(() => {
            fetchInvoices();
        }, [additionalSeatCount]);

        const fetchInvoices = () => new Promise<void>((resolve, reject) => {
            clearTimeout(fetchInvoicesTimeout.current);
            setInvoicesFetching(true);
            fetchInvoicesTimeout.current = setTimeout(() => {
                fetchInvoicesPromiseChain.current = fetchInvoicesPromiseChain.current
                    .then(async () => {
                        if (unmountedRef.current) {
                            return;
                        }
                        const { immediate, upcoming } = await workspacesApi.previewInvoicesForQuantity({ workspaceId: workspaceId, quantity: totalQuantity });
                        if (unmountedRef.current) {
                            return;
                        }
                        setImmediateInvoice(immediate);
                        setUpcomingInvoice(upcoming);
                        setInvoicesFetching(false);
                    })
                    .then(resolve)
                    .catch(reject);
            }, 1000);
        });

        const handleUpdatePaymentMethod = () => {
            ShowDialog(ChangePaymentMethodDialog);
        };

        const paymentMethod = subscription.default_payment_method ?? Object.values(paymentMethods)[0];

        return (<InvoiceInfoContainer data-test-id="additional-seats-invoice-info">
            <FetchingClickShield visible={invoicesFetching} backgroundColor="#f1f1f1" />
            <div>You are adding <b>{additionalSeatCount} new {plan.name} Pro Seat{additionalSeatCount > 1 ? "s" : ""}</b>.</div>
            {immediateInvoice && <div>
                You will be charged <b>${(immediateInvoice?.total ?? 0) / 100}</b> today
                {paymentMethod?.card && <>&nbsp;on your <b>{paymentMethod.card.brand} ****{paymentMethod.card.last4}</b> (<a onClick={handleUpdatePaymentMethod}>change</a>).</>}
                {!paymentMethod?.card && <>&nbsp;on your payment method.</>}
            </div>}
            {upcomingInvoice && <div>
                Your next bill of <b>${(upcomingInvoice?.total ?? 0) / 100}</b> for {BillingController.paidSeatCount + additionalSeatCount} total seats will be due on <b>{moment(upcomingInvoice?.period_end * 1000).format("MMM Do YYYY")}</b>.
            </div>}
        </InvoiceInfoContainer>
        );
    }
)));
