import moment from "moment";
import React, { useState } from "react";
import type Stripe from "stripe";
import styled from "styled-components";

import { WorkspacePlanTier } from "common/interfaces";
import { Button } from "js/Components/Button";
import { Icon } from "js/Components/Icon";
import { Input } from "js/Components/Input";
import BillingController, { BillingControllerState } from "js/controllers/BillingController";
import WorkspaceController, { WorkspaceControllerState } from "js/controllers/WorkspaceController";
import getLogger, { LogGroup } from "js/core/logger";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import { themeColors } from "js/react/sharedStyles";
import { ChangePaymentMethodDialog } from "js/react/views/UserOptions/Billing/ChangePaymentMethodDialog";
import { UpdateBillingDetailsDialog } from "js/react/views/UserOptions/Billing/UpdateBillingDetailsDialog";
import FetchingClickShield from "js/react/components/FetchingClickShield";

import { CreditCardIcon } from "./CreditCardIcon";
import { BlockContainer, BlockContentContainer, BlockHeader } from "./Containers";

export const logger = getLogger(LogGroup.BILLING);

const PaymentMethodContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    text-transform: capitalize;
    color: #666;
    font-size: 15px;
`;

const CardInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
`;

const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: #eee;
    margin-bottom: 4px;
`;

const BillingDetailsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const BillingDetailsInfo = styled.div`
    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: #666;
    align-items: flex-start;
`;

const NextBillInfoContainer = styled.span`
    width: 100%;
    font-size: 14px;
`;

function Card(props: { card: Stripe.PaymentMethod.Card }) {
    const { card } = props;

    const handleEditPaymentMethod = () => {
        ShowDialog(ChangePaymentMethodDialog);
    };

    return (<>
        <CardInfoContainer>
            <CreditCardIcon card={card} />
            <div data-test-id="payment-method-card-info">{card.brand} ending in {card.last4}</div>
        </CardInfoContainer>
        <Button unframed large color={themeColors.ui_blue} onClick={handleEditPaymentMethod} data-test-id="edit-payment-method-button">edit</Button>
    </>);
}

const NextBillInfo = WorkspaceController.withInitializedState(BillingController.withInitializedState(function NextBillInfo(props: BillingControllerState & WorkspaceControllerState) {
    const { stripeData: { upcomingInvoice, subscription }, plan } = props;

    const nextBillDate = moment.unix(subscription.current_period_end).format("MMM DD YYYY");

    if (subscription.status === "trialing") {
        if (subscription.cancel_at_period_end) {
            if (plan.tier === WorkspacePlanTier.PRO) {
                return (<NextBillInfoContainer>
                    Your subscription has been canceled. You will be downgraded to the Basic (free)
                    plan when your trial ends on {nextBillDate}.
                </NextBillInfoContainer>);
            }

            return (<NextBillInfoContainer>Your trial has been canceled.</NextBillInfoContainer>);
        }

        if (upcomingInvoice) {
            return (<NextBillInfoContainer>
                Your first {subscription.scheduled_billing_interval}ly bill of <b>${(upcomingInvoice.amount_due / 100).toFixed(2)}</b> will be due when your free trial ends on <b>{nextBillDate}</b>.
            </NextBillInfoContainer>);
        }

        return null;
    }

    if (subscription.cancel_at_period_end) {
        if (plan.tier === WorkspacePlanTier.PRO) {
            return (<NextBillInfoContainer>
                Your subscription has been canceled. You will be downgraded to the Basic (free)
                plan at the end of your current billing period on <b>{nextBillDate}</b>.
            </NextBillInfoContainer>);
        }

        return (<NextBillInfoContainer>Your plan has been canceled.</NextBillInfoContainer>);
    }

    if (upcomingInvoice) {
        return (<NextBillInfoContainer>
            Your next {subscription.scheduled_billing_interval}ly bill of <b>${(upcomingInvoice.amount_due / 100).toFixed(2)}</b> is due on <b>{nextBillDate}</b>.
        </NextBillInfoContainer>);
    }

    return null;
}));

const PromoContainer = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
`;

const PromoInputContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
`;

const PromoInput = styled(Input)`
    width: 100%;
`;

const PromoTerms = styled.div`
    font-size: 14px;
    margin-top: 5px;
    color: #333;
    font-weight: 600;

    > a {
        text-decoration: none;
        color: ${themeColors.ui_blue};
    }
`;

const PromoError = styled.div`
    font-size: 14px;
    margin-top: 5px;
    color: ${themeColors.warning};
    font-weight: 600;
`;

const PromoCoupon = styled.div`
    display: flex;
    flex-flow: row;
    gap: 10px;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 900;
    color: #333;

    .bai-icon {
        font-size: 22.5px;
        margin-top: -1px;
    }
`;

const PastDueMessage = styled.div`
    font-size: 14px;
    margin-top: 5px;
    color: ${themeColors.warning};
    font-weight: 600;
    width: 100%;
    text-align: left;
`;

const Promo = BillingController.withInitializedState(function Promo(props: BillingControllerState) {
    const { stripeData: { upcomingInvoice } } = props;

    if (upcomingInvoice?.discount?.coupon) {
        const { coupon } = upcomingInvoice.discount;

        return (<PromoContainer data-test-id="promo-container">
            <PromoCoupon>
                <Icon color="rgb(143, 177, 48)">confirmation_number</Icon>
                {coupon.name}{" "}
                ({[
                    coupon.amount_off ? `$${coupon.amount_off / 100}` : `${coupon.percent_off}%`,
                    "off",
                    coupon.duration === "repeating" ? `for ${coupon.duration_in_months} months` : coupon.duration
                ].join(" ")})
            </PromoCoupon>
        </PromoContainer>);
    }

    const [isAddingPromo, setIsAddingPromo] = useState(false);
    const [isApplyingPromo, setIsApplyingPromo] = useState(false);
    const [promoError, setPromoError] = useState(null);
    const [promoCode, setPromoCode] = useState("");

    const handleAddPromo = () => {
        setIsAddingPromo(true);
    };

    const handleChangePromoCode = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newCode = e.target.value;

        setPromoCode(newCode);
        setPromoError(null);

        if (promoCode !== "" && newCode === "") {
            setIsAddingPromo(false);
        }
    };

    const handleApplyPromo = async () => {
        setIsApplyingPromo(true);

        try {
            await BillingController.applyPromotionCodeToSubscription(promoCode);
        } catch (err) {
            logger.error(err, `[PaymentMethodBlock] applyPromoCodeToSubscription() failed`);
            setPromoError(err.message);
        } finally {
            setIsApplyingPromo(false);
        }
    };

    if (!isAddingPromo) {
        return (<PromoContainer data-test-id="promo-container">
            <Button unframed large color={themeColors.ui_blue} onClick={handleAddPromo}>add promo code</Button>
        </PromoContainer>);
    }

    return (<>
        <PromoContainer data-test-id="promo-container">
            <FetchingClickShield visible={isApplyingPromo} backgroundColor="rgba(255,255,255,0.7)" />
            <PromoInputContainer>
                <PromoInput width="100%" value={promoCode} onChange={handleChangePromoCode} placeholder="Promo Code" />
                <Button unframed large color={themeColors.ui_blue} onClick={handleApplyPromo} disabled={!promoCode || promoError || isApplyingPromo}>apply</Button>
            </PromoInputContainer>
            {!promoError && <PromoTerms>
                By clicking apply you agree to the{" "}
                <a
                    href="/promotion-terms"
                    target="_blank"
                >Promotion Terms and Conditions
                </a>
            </PromoTerms>}
            {promoError && <PromoError>{promoError}</PromoError>}
        </PromoContainer>
    </>);
});

export const PaymentMethodBlock = WorkspaceController.withInitializedState(BillingController.withInitializedState(
    function PaymentMethodBlock(props: WorkspaceControllerState & BillingControllerState) {
        const { stripeData: { subscription, paymentMethods, customer }, plan } = props;

        if (!plan.requiresSubscription) {
            return null;
        }

        const paymentMethod = subscription.default_payment_method ?? Object.values(paymentMethods)[0] ?? null;

        const address = customer.address ?? paymentMethod?.billing_details.address ?? null;

        const handleEditBillingDetails = () => {
            ShowDialog(UpdateBillingDetailsDialog);
        };

        return (<BlockContainer data-test-id="payment-method-block">
            <BlockContentContainer top dense>
                {plan.tier !== WorkspacePlanTier.ENTERPRISE && <>
                    <BlockHeader data-test-id="payment-method-block-header">Payment Method</BlockHeader>
                    <PaymentMethodContainer>
                        {paymentMethod?.card && <Card card={paymentMethod.card} />}
                        {!paymentMethod?.card && <div>Credit card data missing</div>}
                    </PaymentMethodContainer>
                    <Divider />
                </>}
                <BlockHeader data-test-id="billing-details-block-header">Billing Details</BlockHeader>
                <BillingDetailsContainer>
                    <BillingDetailsInfo>
                        {customer.name && <div>{customer.name}</div>}
                        {customer.email && <div>{customer.email}</div>}
                        {address && <div>{[address.line1, address.line2, address.city, address.state, address.postal_code, address.country].filter(Boolean).join(", ")}</div>}
                    </BillingDetailsInfo>
                    <Button unframed large color={themeColors.ui_blue} onClick={handleEditBillingDetails}>edit</Button>
                </BillingDetailsContainer>
                <Divider />
                <NextBillInfo />
                {plan.tier !== WorkspacePlanTier.ENTERPRISE && <Promo />}
                {subscription.status === "past_due" && <PastDueMessage>Past due</PastDueMessage>}
            </BlockContentContainer>
        </BlockContainer>);
    }
));
