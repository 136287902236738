import React from "react";
import styled from "styled-components";

import { ActionPermissionsObject, PermissionActionType, WorkspacePlanTier } from "common/interfaces";
import { Button } from "js/Components/Button";
import { Icon } from "js/Components/Icon";
import BillingController, { BillingControllerState } from "js/controllers/BillingController";
import WorkspaceController, { WorkspaceControllerState } from "js/controllers/WorkspaceController";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";

import { RemoveUnusedSeatsDialog } from "./RemoveUnusedSeatsDialog";
import { WorkspaceNameInput } from "./WorkspaceNameInput";
import { InviteUsersDialog } from "./InviteUsersDialog";

const TopSectionContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    background-color: #fff;
    padding: 20px;
`;

const LeftSideContainer = styled.div`
    width: 20%;
    min-width: 450px;
`;

const AvailableSeatsInfoContainer = styled.div`
    font-size: 14px;
    margin-top: 20px;
    font-weight: 700;

    a {
        color: #11A9E2;
        margin-left: 4px;
        font-weight: bold;
        cursor: pointer;
    }
`;

export const TopSection = WorkspaceController.withInitializedState(BillingController.withState(
    function TopSection(props: WorkspaceControllerState & BillingControllerState) {
        const { plan } = props;

        const hasBillingAccess = WorkspaceController.actionPermissions[ActionPermissionsObject.BILLING][PermissionActionType.MANAGE];
        if (!hasBillingAccess) {
            return (<TopSectionContainer>
                <LeftSideContainer>
                    <WorkspaceNameInput />
                </LeftSideContainer>
            </TopSectionContainer>);
        }

        const availableSeatCount = BillingController.availableSeatCount;
        const paidSeatCount = BillingController.paidSeatCount;
        const canChangeSubscriptionQuantity = BillingController.canChangeSubscriptionQuantity;

        const handleManageSeats = () => {
            ShowDialog(RemoveUnusedSeatsDialog);
        };

        const handleInviteMembers = () => {
            ShowDialog(InviteUsersDialog);
        };

        return (<TopSectionContainer>
            <LeftSideContainer>
                <WorkspaceNameInput />
                <AvailableSeatsInfoContainer>
                    {availableSeatCount > 0 && <>
                        {availableSeatCount} of {paidSeatCount} {plan.name} Pro seats available.
                        {canChangeSubscriptionQuantity && <a onClick={handleManageSeats}>Manage seats</a>}
                    </>}
                    {availableSeatCount === 0 && <>
                        You have no available {plan.name} Pro seats.
                        {canChangeSubscriptionQuantity && <a onClick={handleInviteMembers}>Add {plan.name} Pro seats</a>}
                        {!canChangeSubscriptionQuantity && <a href={`mailto:${plan.tier === WorkspacePlanTier.ENTERPRISE ? "accounts@beautiful.ai" : "support@beautiful.ai"}`}>Contact us to add more seats.</a>}
                    </>}
                </AvailableSeatsInfoContainer>
            </LeftSideContainer>
            <Button large blue onClick={handleInviteMembers} id="add-member-button">
                <Icon>add</Icon>
                Add A Member
            </Button>
        </TopSectionContainer>);
    }
));
