import styled from "styled-components";

export const PaneContainer = styled.div`
    color: #333;
    position: relative;
    background: #eee;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 14px;
`;

export const PaneHeader = styled.div`
    width: 100%;
    height: 50px;
    background: #eee;
    color: #222;
    display: flex;
    align-items: center;
    padding: 0px 20px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.5px;
`;

export const PaneContentContainer = styled.div`
    padding: 4px 20px 20px;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;
