import { presentations as presentationsApi } from "apis/callables";
import { AppView, ArrowDirection, DEFAULT_PROGRESS_DURATION_MS } from "common/constants";
import { ActionPermissionsObject } from "common/interfaces";
import { getCanvasBundle } from "js/canvas";
import { appVersion } from "js/config";
import WorkspaceController from "js/controllers/WorkspaceController";
import AppController from "js/core/AppController";
import getLogger from "js/core/logger";
import { ds } from "js/core/models/dataService";
import * as browser from "js/core/utilities/browser";
import { ClipboardType } from "js/core/utilities/clipboard";
import * as geom from "js/core/utilities/geom";
import { Key } from "js/core/utilities/keys.js";
import { trackActivity } from "js/core/utilities/utilities";
import { ClipboardController } from "js/editor/PresentationEditor/ClipboardController";
import { controls } from "js/editor/ui";
import { ExportToPPT } from "js/exporter/exportToPPT";
import { app } from "js/namespaces.js";
import {
    ShowDialog,
    ShowUpgradeDialog
} from "js/react/components/Dialogs/BaseDialog";
import ProgressDialog from "js/react/components/Dialogs/ProgressDialog";
import { AddSlideContainer } from "js/react/views/AddSlide";
import { UpgradePlanDialogType } from "js/react/views/MarketingDialogs/UpgradePlanDialog";
import { $, _, Backbone } from "js/vendor";
import PresentationEditorController from "./PresentationEditor/PresentationEditorController";

const logger = getLogger();

const SlideGrid = Backbone.View.extend({

    className: "slide-grid overlay--arrange-presentation-slides",
    EVENT_NAMESPACE: ".slidegrid",
    DRAGGING_EVENT_NAMESPACE: ".slidegrid_dragging",

    initialize: function(options) {
        this.presentation = options.presentation;
        this.callback = options.callback;

        this.isVisible = true;
        this.librarySettings = app.user.getLibrarySettings();

        $(window).on("keydown" + this.EVENT_NAMESPACE, event => this.handleKeyShortcuts(event));

        this.listenTo(this.presentation, "slidesUpdated", () => {
            this.updateSlides();
        });
        this.listenTo(this.presentation, "updateSkippedSlides", this.updateSlides);
        this.listenTo(this.presentation, "slideorder", this.updateSlides);

        this.slideThumbnails = [];
        this.selectedSlides = [];

        $(window).on("resize" + this.EVENT_NAMESPACE, () => this.layout(false));

        this.collaborators = {};
        this.collaboratorsSubscription = ds.getObservables().collaborators$
            .subscribe(collaborators => {
                this.collaborators = collaborators;
                if (AppController.view === AppView.PRESENTATION_EDITOR) {
                    this.renderCollaborators();
                }
            });
    },

    remove: function() {
        this.slideThumbnails.forEach(slideThumbnail => slideThumbnail.remove());
        Backbone.View.prototype.remove.apply(this, arguments);
        $(window).off(this.DRAGGING_EVENT_NAMESPACE).off(this.EVENT_NAMESPACE);
        this.collaboratorsSubscription && this.collaboratorsSubscription.unsubscribe();
    },

    render: function() {
        this.$slides = this.$el.addEl($.div("slides"));
        this.$slides.on("mousedown", event => {
            this.onDrawRectStart(event);
        });

        this.dragRect = this.$slides.addEl($.div("drag-rect"));

        this.bottomBar = this.$el.addEl($.div("bottom-bar"));

        this.bottomBar.append(controls.createSlider(this, {
            icon: "remove",
            iconAfter: "add",
            model: this.librarySettings,
            property: "scaleInterp",
            min: 0.0,
            max: 1.0,
            step: 0.01,
            className: "zoom-slider",
            callback: scaleInterp => {
                this.librarySettings.scaleInterp = parseFloat(scaleInterp);
                this.layout(false);
                this.scrollToFocusedSlide();
            },
            onEnd: () => {
                app.user.update({
                    librarySettings: {
                        scaleInterp: this.librarySettings.scaleInterp,
                    }
                });
            }
        }));

        this.updateSlides();

        this.selectSlide(this.getSlideThumbnail(ds.selection.slide));

        // Delay the layout for after the DOM has finished mounting
        setTimeout(() => {
            this.layout(false);
            this.renderCollaborators();
            this.scrollToFocusedSlide();
        }, 0);

        return this;
    },

    renderCollaborators() {
        const collaborators = Object.values(this.collaborators)
            .filter(({ uid }) => uid !== app.user.id);

        const existingUserElements = {};

        this.slideThumbnails.forEach(slideGridItemView => {
            slideGridItemView.$collaborators.children().each((idx, element) => {
                const $element = $(element);
                // Note the slide id check
                if (!collaborators.some(({ uid, slideId }) => uid === $element.data("uid") && slideId === slideGridItemView.model.id)) {
                    $element.remove();
                } else {
                    existingUserElements[$element.data("uid")] = $element;
                }
            });
        });

        collaborators
            .forEach(({ uid, photoURL, displayName, slideId }) => {
                let $user = existingUserElements[uid];
                if (!$user) {
                    $user = $.div("user");
                    $user.data("uid", uid);
                    const $avatar = $.div("avatar").attr("title", displayName);
                    $user.addEl($avatar);

                    if (photoURL) {
                        $avatar.addEl($.img(photoURL));
                    } else {
                        $avatar.html("<i class='micon'>person</i>");
                    }

                    const slideGridItemView = this.slideThumbnails.find(slideGridItemView => slideGridItemView.model.id === slideId);
                    if (slideGridItemView) {
                        slideGridItemView.$collaborators.addEl($user);
                    }
                }

                if (ds.selection.slide?.id === slideId) {
                    $user.addClass("active");
                } else {
                    $user.removeClass("active");
                }

                // Removing old listeners (if any)
                $user.off("click");
                $user.on("click", () => {
                    app.mainView.editorView.goToSlide(app.mainView.editorView.getSlideById(slideId));
                });
            });
    },

    onBecameVisible: function() {
        this.isVisible = true;
        this.slideThumbnails.forEach(slideThumbnail => slideThumbnail.onSlideGridBecameVisible());
    },

    scrollToFocusedSlide: function() {
        this.$el.find(".slides").scrollTop(this.focusedSlide.y - 10);
    },

    getSlideThumbnail: function(slideModel) {
        return _.find(this.slideThumbnails, thumb => thumb.model.id == slideModel.id);
    },

    updateSlides: function() {
        for (let slide of this.presentation.slides) {
            let thumbnail = this.getSlideThumbnail(slide);
            if (!thumbnail) {
                thumbnail = this.createSlideThumbnail(slide);
                this.$slides.append(thumbnail.render().$el);
                if (this.isVisible) {
                    thumbnail.onSlideGridBecameVisible();
                }
                this.setupDragging(thumbnail);
            }
        }

        let removedThumbnails = [];
        for (let slideThumbnail of this.slideThumbnails) {
            if (!_.find(this.presentation.slides.models, model => model.id == slideThumbnail.model.id)) {
                slideThumbnail.$el.remove();
                removedThumbnails.push(slideThumbnail);
            }
        }

        for (let slideThumbnail of removedThumbnails) {
            this.slideThumbnails.remove(slideThumbnail);
        }

        this.slideThumbnails = _.sortBy(this.slideThumbnails, thumb => thumb.getSlideIndex());
        this.layout();

        if (this.slideThumbnails.some(({ model }) => !model.loaded)) {
            // If some of the slide models aren't loaded then load the metadata
            // to allow correnctly loading thumbnails and rendering skipped marks
            presentationsApi.getSlidesMetadata({ id: this.presentation.id })
                .then(slidesMetadata =>
                    this.slideThumbnails
                        .forEach(thumbnail => {
                            thumbnail.setSlideMetadata(slidesMetadata[thumbnail.model.id]);
                            thumbnail.renderThumbnail();
                        })
                );
        } else {
            this.slideThumbnails.forEach(thumbnail => thumbnail.renderThumbnail());
        }
    },

    getFocusedSlidePosition: function() {
        if (this.focusedSlide) {
            return new geom.Point(this.focusedSlide.$el.left(), this.focusedSlide.$el.top());
        }
    },

    handleKeyShortcuts: function(event) {
        //Protect against input fields so that we don't accidentally trigger these key events.
        if (event.target.tagName === "INPUT") {
            return;
        }
        let nextIndex = null;
        event.stopPropagation();
        switch (event.which) {
            case Key.KEY_Z:
                if (event.metaKey || event.ctrlKey) {
                    if (event.shiftKey) {
                        app.undoManager.redo();
                    } else {
                        app.undoManager.undo();
                    }
                    event.preventDefault();
                    return;
                }
                break;
            case Key.KEY_X:
                if (event.metaKey || event.ctrlKey) {
                    this.cutSlides();
                    event.preventDefault();
                    return;
                }
                break;
            case Key.KEY_C:
                if (event.metaKey || event.ctrlKey) {
                    this.copySlides();
                    event.preventDefault();
                    return;
                }
                break;
            case Key.KEY_V:
                if (event.metaKey || event.ctrlKey) {
                    this.pasteSlides(event);
                    event.preventDefault();
                    return;
                }
                break;
            case Key.LEFT_ARROW:
                nextIndex = this.moveSlideSelection(ArrowDirection.LEFT, event.shiftKey);
                break;
            case Key.RIGHT_ARROW:
                nextIndex = this.moveSlideSelection(ArrowDirection.RIGHT, event.shiftKey);
                break;
            case Key.UP_ARROW:
                nextIndex = this.moveSlideSelection(ArrowDirection.UP, event.shiftKey);
                break;
            case Key.DOWN_ARROW:
                nextIndex = this.moveSlideSelection(ArrowDirection.DOWN, event.shiftKey);
                break;
            case Key.SPACE:
            case Key.ESCAPE:
                if (!this.isDraggingRect && !this.isDragging) {
                    this.closeSlideGrid();
                }
                break;
        }
    },

    moveSlideSelection: function(direction, addToSelection) {
        let start = this.slideThumbnails.indexOf(this.focusedSlide);

        let nextIndex;

        switch (direction) {
            case ArrowDirection.LEFT:
                if (start > 0) {
                    nextIndex = start - 1;
                } else {
                    nextIndex = this.slideThumbnails.length - 1;
                }
                break;
            case ArrowDirection.RIGHT:
                if (start < this.presentation.slides.length - 1) {
                    nextIndex = start + 1;
                } else {
                    nextIndex = 0;
                }
                break;
            case ArrowDirection.DOWN:
                nextIndex = start + this.totalCols;
                if (nextIndex >= this.slideThumbnails.length) {
                    nextIndex = 0;
                }
                break;
            case ArrowDirection.UP:
                nextIndex = start - this.totalCols;
                if (nextIndex < 0) {
                    nextIndex = this.slideThumbnails.length - 1;
                }
                break;
        }
        this.selectSlide(this.slideThumbnails[nextIndex]);
        this.scrollToFocusedSlide();
    },

    setupDragging: function(thumbnail) {
        let gridWidth, totalCols;

        thumbnail.$el.draggable({
            distance: 5,
            containment: this.$el.find(".slides"),
            start: (event, ui) => {
                gridWidth = this.$el.find(".slides").innerWidth();
                totalCols = Math.floor(gridWidth / (this.colWidth + this.gridGap));

                // this.draggedSlides = [];
                for (let slide of this.selectedSlides) {
                    // this.draggedSlides.push(slide);
                    // this.slideThumbnails.remove(slide);
                    slide.$el.addClass("isDragging");
                    slide.isDragging = true;
                    if (slide != thumbnail) {
                        slide.$el.left(thumbnail.$el.left()).top(thumbnail.$el.top()).css("transform", `rotate(${(Math.random() * 10) - 5}deg)`).addClass("isDragFollow");
                    }
                }
            },
            drag: (event, ui) => {
                let col = Math.clamp(Math.floor((ui.position.left + this.colWidth / 2) / (this.colWidth + this.gridGap)), 0, totalCols);
                let row = Math.floor((ui.position.top + this.rowHeight / 2) / (this.rowHeight + this.gridGap));

                this.dropTargetIndex = row * totalCols + col;

                for (let slide of this.selectedSlides) {
                    if (slide != thumbnail) {
                        slide.$el.left(thumbnail.$el.left()).top(thumbnail.$el.top());
                    }
                }

                this.layout();
            },
            stop: (event, ui) => {
                for (let slide of this.selectedSlides) {
                    slide.$el.removeClass("isDragging isDragFollow");
                    slide.isDragging = false;
                }
                this.moveSlides(this.selectedSlides, this.dropTargetIndex);
                this.dropTargetIndex = null;
            }
        });
        thumbnail.$el.css("position", "absolute");
    },

    createSlideThumbnail: function(slide) {
        const slideThumbnail = new SlideGridThumbnail({
            grid: this,
            slide: slide,
        });
        this.slideThumbnails.push(slideThumbnail);
        return slideThumbnail;
    },

    gridGap: 30,
    THUMB_WIDTH: 200,
    LEFT_OFFSET: 259,
    PADDING: 80,

    layout: function(transition = true) {
        let fullWidth = this.$slides.innerWidth();

        let xGridGap = this.gridGap;
        if (fullWidth < 100 + xGridGap * 2) {
            xGridGap = 0;
        }

        // The scale ranges from 3^-2 to 3^0;
        //   or 1/27 to 1 with 1/3 being the midpoint.
        let interp = this.librarySettings.scaleInterp;
        let exponent = -2 + 2 * interp;
        let scale = Math.pow(3, exponent);

        let slidesWidth = fullWidth - xGridGap;
        let maxWidth = 600;
        let thumbnailWidth = maxWidth * scale;
        thumbnailWidth = Math.min(thumbnailWidth, slidesWidth);

        let colCount = Math.floor(slidesWidth / (thumbnailWidth + xGridGap));

        this.colWidth = (slidesWidth - (xGridGap * colCount)) / colCount;
        //(9 / 16) is the aspect ratio of slide thumb, 10 is for padding between slide thumb and slide number
        this.rowHeight = thumbnailWidth * 9 / 16 + 10;
        this.totalCols = colCount;

        let drawThumbs = _.filter(this.slideThumbnails, slide => !slide.isDragging);
        if (this.dropTargetIndex != null) {
            drawThumbs.insert("drop", this.dropTargetIndex);
        }

        let thumbnailMargin = (this.colWidth - thumbnailWidth) / 2;
        let x = xGridGap + thumbnailMargin;
        let y = this.gridGap;
        for (let thumb of drawThumbs) {
            if (thumb instanceof SlideGridThumbnail) {
                if (transition) {
                    thumb.$el.css("transition", "400ms");
                } else {
                    thumb.$el.css("transition", "0ms");
                }
                thumb.$el
                    .left(x)
                    .top(y)
                    .css("transform", "rotate(0deg)")
                    .width(thumbnailWidth)
                    .height(this.rowHeight);
                thumb.y = y;
                thumb.layout();
            }

            x += this.colWidth + xGridGap;

            if (x > fullWidth) {
                x = xGridGap + thumbnailMargin;
                y += this.rowHeight + this.gridGap;
            }
        }
    },

    selectSlide: function(slide, metaKey, shiftKey, force) {
        if (force) {
            this.selectedSlides = [slide];
            this.focusedSlide = slide;
        } else if (this.selectedSlides.contains(slide) && !metaKey) {
            return;
        } else if ((!metaKey && !shiftKey) || !this.focusedSlide) {
            this.focusedSlide = slide;
            this.selectedSlides = [this.focusedSlide];
        } else if (shiftKey) {
            this.selectedSlides = [];
            for (let i = Math.min(this.focusedSlide.getSlideIndex(), slide.getSlideIndex()); i <= Math.max(this.focusedSlide.getSlideIndex(), slide.getSlideIndex()); i++) {
                this.selectedSlides.push(this.slideThumbnails[i]);
            }
        } else if (metaKey) {
            if (this.selectedSlides.contains(slide)) {
                this.selectedSlides.remove(slide);
                if (this.focusedSlide == slide) {
                    if (this.selectedSlides.length) {
                        this.focusedSlide = this.selectedSlides[0];
                    } else {
                        this.focusedSlide = slide;
                        this.selectedSlides = [slide];
                    }
                }
            } else {
                this.selectedSlides.push(slide);
            }
        }

        this.renderSelection();
    },

    renderSelection: function() {
        this.$el.find(".slide-thumbnail").removeClass("selected").removeClass("focused");
        for (let selectedSlide of this.selectedSlides) {
            selectedSlide.$el.addClass("selected");
        }
        this.focusedSlide && this.focusedSlide.$el.addClass("focused");
    },

    closeSlideGrid: function() {
        // this.trigger("close");
        this.callback(this.focusedSlide?.model);
    },

    handleSkippedInContextMenu: function() {
        const isFocusSlideSkipped = this.focusedSlide.isSlideSkipped();
        let skipSlideOption = {
            icon: isFocusSlideSkipped ? "visibility" : "visibility_off",
            value: isFocusSlideSkipped ? "unskip" : "skip"
        };
        if (isFocusSlideSkipped) {
            skipSlideOption.label = (this.selectedSlides.length != 1) ? `Unskip ${this.selectedSlides.length} Slides` : "Unskip Slide";
        } else {
            skipSlideOption.label = (this.selectedSlides.length != 1) ? `Skip ${this.selectedSlides.length} Slides` : "Skip Slide";
        }
        return skipSlideOption;
    },

    renderExportToJpegInContextMenu: function() {
        const workspaceId = this.presentation.getWorkspaceId();
        if (this.selectedSlides.length === 1) {
            return [
                {
                    type: "divider"
                }, {
                    label: "Export To JPEG",
                    icon: "panorama",
                    value: "export_to_jpeg"
                }, {
                    label: "Export To PPTX",
                    icon: "cloud_download",
                    value: "export_to_pptx",
                    showProBadge: WorkspaceController.canUpgradeToRemoveActionRestriction(ActionPermissionsObject.EXPORT_PRESENTATION_TO_EDITABLE, "use"),
                    proBadgeAnalytics: { cta: "exportToPPT", ...this.presentation.getAnalytics() },
                    proBadgeWorkspaceId: workspaceId
                }
            ];
        }
        return [];
    },

    // region Context Menu

    showContextMenu: function(event, slideEl) {
        let cutLabel = "Cut Slide";
        if (this.selectedSlides.length > 1) {
            cutLabel = `Cut ${this.selectedSlides.length} Slides`;
        }
        let copyLabel = "Copy Slide";
        if (this.selectedSlides.length > 1) {
            copyLabel = `Copy ${this.selectedSlides.length} Slides`;
        }
        let pasteLabel = "Paste Slide";
        if (app.lastCopy?.[ClipboardType.SLIDES]?.slides?.length > 1) {
            pasteLabel = `Paste ${app.lastCopy[ClipboardType.SLIDES].slides.length} Slides`;
        }

        const skipSlideOption = this.handleSkippedInContextMenu();
        const items = [
            skipSlideOption
        ];

        if (!browser.isFirefox) {
            items.push({
                type: "divider"
            }, {
                type: "menu",
                label: "Copy/Paste",
                icon: "content_paste",
                menu: {
                    items: [
                        {
                            label: cutLabel,
                            icon: "content_cut",
                            value: "cut",
                        },
                        {
                            label: copyLabel,
                            icon: "content_copy",
                            value: "copy",
                        },
                        {
                            label: pasteLabel,
                            icon: "content_paste",
                            value: "paste",
                        },
                    ],
                    callback: type => this.optionsMenuClick(event, type),
                }
            });
        }

        items.push(
            {
                type: "divider"
            },
            {
                label: this.selectedSlides.length != 1 ? `Duplicate ${this.selectedSlides.length} Slides` : "Duplicate Slide",
                icon: "content_copy",
                value: "duplicate"
            },
            {
                label: this.selectedSlides.length != 1 ? `Delete ${this.selectedSlides.length} Slides` : "Delete Slide",
                icon: "delete",
                value: "delete"
            },
            {
                type: "divider"
            },
            {
                label: "Select All",
                icon: "select_all",
                value: "select_all"
            },
            ...this.renderExportToJpegInContextMenu()
        );

        controls._createMenu(slideEl, {
            items: items,
            callback: type => this.optionsMenuClick(event, type),
            overwriteTriggerHandler: true,
            preventBlurOnMenuClose: true
        });
    },

    optionsMenuClick: async function(event, type) {
        await Promise.all(this.selectedSlides.map(slideView => slideView.model.load()));
        switch (type) {
            case "add_slide":
                ShowDialog(AddSlideContainer);
                break;
            case "duplicate":
                this.duplicateSlides();
                break;
            case "cut":
                this.cutSlides();
                break;
            case "copy":
                this.copySlides();
                break;
            case "paste":
                this.pasteSlides(event);
                break;
            case "delete":
                this.deleteSlides();
                break;
            case "skip":
                this.skipSlides();
                break;
            case "unskip":
                this.unskipSlides();
                break;
            case "export_to_jpeg":
                this.exportSlideToJpeg();
                break;
            case "export_to_pptx":
                this.exportSlideToPPT();
                break;
            case "select_all":
                this.selectedSlides = [];
                this.focusedSlide = _.first(this.slideThumbnails);
                this.selectSlide(_.last(this.slideThumbnails), false, true);
                break;
        }
    },

    // endregion

    // region Update Slide Models

    moveSlides: function(slides, index) {
        app.undoManager.openGroup();
        this.presentation.moveSlides(_.map(slides, slide => slide.model.id), index).then(() => {
            app.undoManager.closeGroup();
        });
    },

    async duplicateSlides() {
        const slides = this.presentation.sortSlides(_.map(this.selectedSlides, slide => slide.model), slide => slide.id);
        const insertIndex = _.max(this.selectedSlides.map(slide => slide.model.getIndex())) + 1;

        app.undoManager.openGroup();
        const slideModels = await this.presentation.batchDuplicateSlides(slides, { insertIndex });
        app.undoManager.closeGroup();

        this.selectSlide(this.getSlideThumbnail(slideModels[0]), false, false, true);

        const duplicateSlideCount = slideModels.length;
        const { slideTemplates: { slideTemplates } } = await getCanvasBundle(appVersion);
        const eventProps = {
            "slide_ids": slideModels.map(s => s.get("id")),
            "source_slides": slides.map(s => s.get("id")),
            "slide_template_names": slideModels.map(s => slideTemplates[s.get("template_id")].title),
            "slides_created": duplicateSlideCount,
        };
        trackActivity("Slide", "Duplicate", null, duplicateSlideCount, eventProps, { audit: true });
    },

    cutSlides() {
        let firstSlideIndex = this.selectedSlides[0].getSlideIndex();
        const slides = this.presentation.sortSlides(_.map(this.selectedSlides, slide => slide.model), slide => slide.id);
        this.presentation.cutSlides(slides);
        this.selectSlide(this.slideThumbnails[Math.max(0, firstSlideIndex - 1)], false, false, true);
    },

    copySlides() {
        const slides = this.presentation.sortSlides(_.map(this.selectedSlides, slide => slide.model), slide => slide.id);
        this.presentation.copySlides(slides);
    },

    async pasteSlides(event = null) {
        const insertIndex = _.max(this.selectedSlides.map(slide => slide.model.getIndex())) + 1;
        const slideModels = await ClipboardController.pasteSlides(event, insertIndex);
        if (slideModels?.length) {
            this.selectSlide(this.getSlideThumbnail(slideModels[0]), false, false, true);
        }
    },

    deleteSlides() {
        let deleteSlides = _.clone(this.selectedSlides);
        // if trying to delete all slides, keep first slide
        if (this.selectedSlides.length === this.presentation.slides.length) {
            deleteSlides = deleteSlides.slice(1);
        }

        let firstSlideIndex = deleteSlides[0].getSlideIndex();

        app.undoManager.openGroup();

        let progressDialog = null;
        const timeoutId = setTimeout(() => {
            progressDialog = ShowDialog(ProgressDialog, {
                title: `Deleting slides...`,
            });
        }, DEFAULT_PROGRESS_DURATION_MS);

        this.presentation.destroySlides(deleteSlides.map(slide => slide.model.id)).then(() => {
            app.undoManager.closeGroup();
            this.selectSlide(this.slideThumbnails[Math.max(0, firstSlideIndex - 1)], false, false, true);
            clearTimeout(timeoutId);
            if (progressDialog) {
                progressDialog.close();
            }
        });
    },

    skipSlides: function() {
        const slideModels = this.selectedSlides.map(slide => slide.model);
        this.presentation.batchSkipSlides(slideModels);
    },

    unskipSlides: function() {
        const slideModels = this.selectedSlides.map(slide => slide.model);
        this.presentation.batchUnskipSlides(slideModels);
    },

    // endregion

    // region BoxSelect

    onDrawRectStart: function(event) {
        let scrollbarWidth = $.getScrollBarWidth() || 16; // 16 is for hovering scrollbars in osx which can't be measured
        if ($(event.target).hasScroll() && window.outerWidth - event.clientX < scrollbarWidth) {
            return;
        }
        this.drawRectStartEvent = event;
        this.isDraggingRect = false;
        this.dragRect.show().css({
            "left": event.pageX - this.$el.find(".slides").offset().left,
            "top": event.pageY - this.$el.find(".slides").offset().top,
            "width": 0,
            "height": 0
        });

        this.selectedSlides = [this.focusedSlide];
        this.renderSelection();

        this.hasToggled = [];
        $(window).off(this.DRAGGING_EVENT_NAMESPACE).on("mousemove" + this.DRAGGING_EVENT_NAMESPACE, this.onDrawRectMove.bind(this)).on("mouseup" + this.DRAGGING_EVENT_NAMESPACE, this.onDrawRectEnd.bind(this));
    },

    onDrawRectMove: function(event) {
        const slideOffset = this.$slides.offset();

        let x1 = this.drawRectStartEvent.pageX,
            y1 = this.drawRectStartEvent.pageY,
            x2 = event.originalEvent.pageX,
            y2 = event.originalEvent.pageY,
            tmp;

        if (!this.isDraggingRect && Math.abs(x1 - x2) <= 5 || Math.abs(y1 - y2) <= 5) {
            return;
        }

        this.isDraggingRect = true;

        if (x1 > x2) {
            tmp = x2;
            x2 = x1;
            x1 = tmp;
        }
        if (y1 > y2) {
            tmp = y2;
            y2 = y1;
            y1 = tmp;
        }
        this.dragRect.css({
            left: x1 - slideOffset.left,
            top: y1 - slideOffset.top + this.$slides.scrollTop(),
            width: x2 - x1,
            height: y2 - y1
        });

        let selectedSlides = this.slideThumbnails.filter(thumb => {
            let thumbRect = thumb.el.getBoundingClientRect();
            return !(thumbRect.left > x2 || thumbRect.right < x1 || thumbRect.top > y2 || thumbRect.bottom < y1);
        });

        if (selectedSlides.length) {
            this.selectedSlides = selectedSlides;
            this.focusedSlide = selectedSlides[0];
            this.renderSelection();
        }
    },

    onDrawRectEnd: function(event) {
        this.dragRect.hide();
        $(window).off(this.DRAGGING_EVENT_NAMESPACE);
        this.drawRectStartEvent = null;
        this.isDraggingRect = false;
    },

    // endregion

    exportSlideToJpeg() {
        this.focusedSlide.model.exportSlideToJpeg(this.$el, this.focusedSlide.$el);
    },

    async exportSlideToPPT() {
        const workspaceId = this.presentation.getWorkspaceId();
        if (WorkspaceController.actionPermissions[ActionPermissionsObject.EXPORT_PRESENTATION_TO_EDITABLE].use) {
            const exporter = new ExportToPPT();

            const canvasController = PresentationEditorController.getCanvasControllerForSlide({ id: this.focusedSlide.model.get("id") });
            await canvasController.renderCanvas();

            exporter.export({
                slideCanvases: [canvasController.canvas],
                includeSkippedSlides: true
            });
        } else if (WorkspaceController.canUpgradeToRemoveActionRestriction(ActionPermissionsObject.EXPORT_PRESENTATION_TO_EDITABLE, "use")) {
            ShowUpgradeDialog({
                type: UpgradePlanDialogType.UPGRADE_PLAN,
                analytics: { cta: "exportToPPT", ...ds.selection.presentation.getAnalytics() },
                workspaceId
            });
        }
    }

});

const SlideGridThumbnail = Backbone.View.extend({
    className: "slide-thumbnail",

    events: {
        "mousedown": "onMouseDown",
        "mouseup": "onMouseUp",
        "dblclick": "onDoubleClick",
        "contextmenu": "onContextMenu"
    },

    initialize: function(options) {
        this.grid = options.grid;
        this.model = options.slide;
        this.isDragging = false;
        this.isVisible = false;
    },

    getSlideIndex: function() {
        return this.model.presentation.getSlideIndex(this.model.id);
    },

    renderSkip: function(elem) {
        elem.addClass("thumbnail-skip-corner");
        elem.prepend($.icon("visibility_off", "thumbnail-skip"));
    },

    renderAssignedToCurrentUserIcon: function(elem) {
        const assignedToUserCorner = elem.addEl($.div("assigned-to-user-container"));
        assignedToUserCorner.append($.icon("person_pin", "assigned-to-user"));
    },

    renderInfo: function() {
        const addContentCorner = this.$el.addEl($.div("content-indicators"));

        if (this.model.dataState?.elements?.annotations?.videoOverlay) addContentCorner.append($.icon("video_camera_front"));
        if (this.model.get("audioAsset")) addContentCorner.append($.icon("mic"));
    },

    hideAssignedToCurrentUserIcon: function(elem) {
        elem.find(".assigned-to-user-container").remove();
    },

    hideSkip: function(elem) {
        elem.removeClass("thumbnail-skip-corner");
        elem.find(".thumbnail-skip").remove();
    },

    setSlideMetadata: function(slideMetadata) {
        this.slideMetadata = slideMetadata;
    },

    isSlideSkipped() {
        return (this.model.loaded && this.model.presentation.isSlideSkipped(this.model)) ||
            (!this.model.loaded && this.slideMetadata?.isSkipped);
    },

    renderThumbnail: function() {
        if (!this.isVisible) {
            return;
        }

        return this.model.getThumbnailUrl(this.model.presentation.id, "small", 0, true, this.slideMetadata?.modifiedAt)
            .then(url => {
                if (this.$image) {
                    this.$image.remove();
                }
                this.$image = $.img(url, this.model.get("template_id"));
                this.$el.append(this.$image);

                this.$frame.spinner(false);
                this.$frame.removeClass("loading");

                if (this.isSlideSkipped()) {
                    this.renderSkip(this.$frame);
                } else {
                    this.hideSkip(this.$frame);
                }

                if (this.model.get("assignedUser") === app.user.id) {
                    this.renderAssignedToCurrentUserIcon(this.$frame);
                } else {
                    this.hideAssignedToCurrentUserIcon(this.$frame);
                }
            }).catch(err => {
                logger.error(err, "[SlideGrid] this.model.getThumbnailUrl() failed", { presentationId: ds.selection.presentation.id });

                this.$frame.spinner(true);
                this.$frame.addClass("loading");
            });
    },

    render: function() {
        this.$el.data("id", this.model.id);

        this.$frame = this.$el.addEl($.div("frame"));
        this.$frame.spinner(true);
        this.$frame.addClass("loading");

        this.listenTo(this.model, "change:modifiedAt",
            () => {
                this.renderThumbnail();
            });

        this.listenTo(this.model, "change:assignedUser", () => {
            if (!this.isVisible) {
                return;
            }

            if (this.model.get("assignedUser") === app.user.id) {
                this.renderAssignedToCurrentUserIcon(this.$frame);
            } else {
                this.hideAssignedToCurrentUserIcon(this.$frame);
            }
        });

        this.$el.append($.div("slide-num", 1));

        this.renderInfo();
        this.$collaborators = this.$el.addEl($.div("collaboration-view"));
        return this;
    },

    onSlideGridBecameVisible: function() {
        //TODO mitch figure out why this broke
        // const observer = new IntersectionObserver(entries => {
        //     if (entries[0].isIntersecting === true) {
        //         observer.disconnect();
        this.isVisible = true;

        if (this.model.loaded || this.slideMetadata) {
            this.renderThumbnail();
        }
        // }
        // });
        //
        // observer.observe(this.$el[0], { root: this.grid.$slides[0] });
    },

    layout: function() {
        this.$el.find(".slide-num").text(this.getSlideIndex() + 1);
    },

    onMouseDown: function(event) {
        event.stopPropagation();
        this.grid.selectSlide(this, event.metaKey || event.ctrlKey, event.shiftKey);
    },

    onMouseUp: function(event) {
        if (!this.isDragging && !this.grid.isDraggingRect && !event.shiftKey && !event.metaKey && !event.ctrlKey && this.grid.selectedSlides.length > 1 && event.which !== 3) {
            this.grid.selectSlide(this, false, false, true);
        }
    },

    onDoubleClick: function(evemt) {
        this.grid.closeSlideGrid();
    },

    onContextMenu: function(event) {
        this.grid.selectSlide(this, false, false);
        this.grid.showContextMenu(event, this.$el);
    },

});

export { SlideGrid };
