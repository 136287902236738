import React from "react";
import styled from "styled-components";

import { Icon } from "js/Components/Icon";

const ProfileContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
`;

const ProfilePictureContainer = styled.div`
    width: 30px;
    height: 30px;
    background-color: #333;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    >img {
        width: 100%;
    }
`;

const ProfileEmailAndDisplayName = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    >div {
        user-select: text;
        font-size: 12px;
        font-weight: 400;
        color: #666;
    }

    >div:first-child {
        font-size: 15px;
        font-weight: 600;
        color: #333;
    }
`;

export function Profile(props: { email: string, displayName?: string, photoURL?: string }) {
    const { email, displayName, photoURL } = props;

    return (<ProfileContainer>
        <ProfilePictureContainer>
            {photoURL && <img src={photoURL} />}
            {!photoURL && <Icon color="#ccc" large outlined={false}>person</Icon>}
        </ProfilePictureContainer>
        <ProfileEmailAndDisplayName>
            <div>{displayName || email}</div>
            {displayName && <div>{email}</div>}
        </ProfileEmailAndDisplayName>
    </ProfileContainer>);
}
