import { Button } from "@material-ui/core";
import classNames from "classnames";
import moment from "moment";
import React, { Component, Fragment, setGlobal } from "reactn";
import styled, { css } from "styled-components";

import { CustomerType, SubscriptionStatus } from "common/constants";
import { ActionPermissionsObject } from "common/interfaces";
import { isPPTAddin } from "js/config";
import BillingController from "js/controllers/BillingController";
import WorkspaceController from "js/controllers/WorkspaceController";
import Api from "js/core/api";
import AppController from "js/core/AppController";
import getLogger, { LogGroup } from "js/core/logger";
import { ds } from "js/core/models/dataService";
import * as googleAuth from "js/core/oauth/googleAuth";
import { openPricingPage } from "js/core/utilities/externalLinks";
import * as gDrive from "js/core/utilities/gDrive";
import { removeUrlsFromText } from "js/core/utilities/htmlTextHelpers";
import { getPricingPageUrl } from "js/core/utilities/pricing";
import { trackActivity } from "js/core/utilities/utilities";
import { auth } from "js/firebase/auth";
import { app } from "js/namespaces";
import {
    ShowConfirmationDialog,
    ShowDialog,
    ShowErrorDialog,
    ShowMessageDialog,
    ShowUpgradeDialog
} from "js/react/components/Dialogs/BaseDialog";
import { Gap10, Gap20 } from "js/react/components/Gap";
import { FlexBox } from "js/react/components/LayoutGrid";
import Loadable from "js/react/components/Loadable";
import LogOutButton from "js/react/components/LogOutButton";
import Spinner from "js/react/components/Spinner";
import {
    BlueButton,
    ScaryButton,
    Section,
    UIPane,
    UIPaneContents,
    UIPaneHeader,
    YellowButton
} from "js/react/components/UiComponents";
import { withFirebaseAuth } from "js/react/views/Auth/FirebaseAuthContext";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";
import { ChangePasswordDialog } from "js/react/views/UserOptions/dialogs/ChangePasswordDialog";
import { ReauthPasswordDialog } from "js/react/views/UserOptions/dialogs/ReauthPasswordDialog";
import { ValidatedDeleteDialog } from "js/react/views/UserOptions/dialogs/ValidatedDeleteDialog";
import { GoogleButton } from "../../Auth/AuthUi";
import { UpgradePlanDialogType } from "../../MarketingDialogs/UpgradePlanDialog";
import CheckoutDialog from "../Billing/CheckoutDialog";
import { DowngradePlanDialog } from "../Billing/DowngradePlanDialog";
import TextInput from "../components/TextInput";
import UploadAvatar from "../components/UploadAvatar";

const logger = getLogger(LogGroup.AUTH);

function CancelSubscribtionMessage({
    isInDunning, nextBillDate, isInTrial, isOrganization
}) {
    if (!isOrganization) {
        if (isInDunning) {
            return (<Fragment>
                You will be immediately downgraded to the Basic plan.
            </Fragment>);
        } else {
            return (<Fragment>
                Your plan will remain active until the end of your current billing cycle on {nextBillDate} after which you will be downgraded to the Basic (free) plan.
            </Fragment>);
        }
    } else {
        if (isInTrial) {
            return (<Fragment>
                <p>
                    Your plan will remain active until the end of the trial period on {nextBillDate}.
                </p>
                <p>
                    Please ensure your team transfers any presentations you'd like to continue to access to another workspace before the end of your trial.
                </p>
            </Fragment>);
        } else {
            return (<Fragment>
                <p>
                    Your plan will remain active until the end of the current billing cycle on {nextBillDate}.
                </p>
                <p>
                    Please ensure your team transfers any presentations you'd like to continue to access to another workspace before the end of your subscription.
                </p>
            </Fragment>);
        }
    }
}

function checkIfUserIsOnlyAdminInTeams(teamModels, userId) {
    return teamModels.reduce((acc, team) => {
        if (team.has("customerId")) {
            const members = Object.values(team.get("members"));
            let adminCount = members.filter(member => member.role === "admin").length;
            if (adminCount === 1 && team.get("members")[userId].role === "admin") {
                return true;
            }
        }
        return acc;
    }, false);
}

async function checkIfUserHasPastDueSubscription() {
    const subscription = await Api.subscriptions.get({ customer_type: CustomerType.INDIVIDUAL });
    return (subscription && subscription[0] && subscription[0].status === SubscriptionStatus.PAST_DUE);
}

function hasPasswordProvider(user) {
    return !!user.providerData.find(p => p.providerId === "password");
}

function hasGoogleProvider(user) {
    return !!user.providerData.find(p => p.providerId === "google.com");
}

const StyledSubscriptionStatus = styled.div`
    text-transform: capitalize;
    font-size: 30px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0.5px;

    span {
        margin-left: 6px;
    }
`;

const StyledCancelButton = styled(Button)`
    &&& {
        color: #666666;
    }
`;

const StyledUpgradeButton = styled(Button)`
    &&& {
        color: #ffffff;
        background-color: #FFBB43;
    }
`;

const StyledUIPane = styled(UIPane)`
    &&& {
        // on Safari, when the url bar is at the bottom, the bottom of the pane is cut off
        ${props => props.isMobile && css`padding-bottom: 70px;`}
    }
`;

class AccountPane extends Component {
    constructor(props) {
        super(props);
        const firebaseUser = props.firebaseUser;
        this.state = {
            avatarUrl: firebaseUser.photoURL,
            currentPane: "account",
            uid: firebaseUser.uid,
            email: firebaseUser.email,
            emailVerified: firebaseUser.emailVerified,
            emailVerificationSent: false,
            emailVerificationStatusText: "Email is not verified - Click to send verification email",
            dialogMessage: null,
            name: firebaseUser.displayName || "",
            originalEmail: firebaseUser.email,
            previousEmail: firebaseUser.email,
            user: firebaseUser,
            userHasGoogleProvider: hasGoogleProvider(firebaseUser),
            userGoogleLinkFetching: false,
            userGDriveEnabled: app.user.get("isGDriveEnabled"),
            userGDriveFetching: false,
            canDeleteUser: app.user.workspacesMetadata.length === 1,
            ssoStatus: null,
            fetching: true
        };
    }

    componentDidMount() {
        const { uid } = this.state;

        Api.ssoStatus.get({ uid })
            .then(({ status }) => this.setState({ fetching: false, ssoStatus: status }))
            .catch(err => {
                logger.error(err, "Api.ssoStatus.get() failed");

                ShowErrorDialog({
                    error: "Uh oh, something isn't right",
                    message: "Looks like something unexpected has occurred. Please try again or contact us at support@beautiful.ai"
                });
            });
    }

    showPricingPage = (currentPlan = "basic") => {
        window.open(getPricingPageUrl(app && app.user.hasTakenTrial, app && app.user.has("hasTakenTeamTrial"), currentPlan), "_blank");
    };

    get hasSubscription() {
        const { plan, stripeData: { subscription } } = this.props;
        return plan.requiresSubscription && !!subscription;
    }

    get isInTrial() {
        const { stripeData: { subscription } } = this.props;
        return subscription?.status === "trialing";
    }

    updateUserGoogleLinkState() {
        const user = this.props.firebaseUser;
        const userHasGoogleProvider = hasGoogleProvider(this.props.firebaseUser);
        this.setState({ user, userHasGoogleProvider });
    }

    setUserGDriveFetching(userGDriveFetching) {
        this.setState({ userGDriveFetching });
    }

    setUserGoogleLinkFetching(userGoogleLinkFetching) {
        this.setState({ userGoogleLinkFetching });
    }

    handleChange(name) {
        return event => {
            this.setState({ [name]: event.target.value });
        };
    }

    handleChangePassword() {
        ShowDialog(ChangePasswordDialog);
    }

    handleDeleteAccount() {
        const { userGDriveEnabled } = this.state;

        ShowDialog(ValidatedDeleteDialog, {
            title: "Are you sure you want to delete your account?",
            message:
                "All your presentations and other data will be deleted from Beautiful.ai. You will not be able to undo this action.",
            prompt:
                "Please type your email address to confirm deletion of your account:",
            validateStr: app.user.getEmail(),
            callback: async isValidated => {
                if (isValidated) {
                    const userId = app.user.id;
                    const isPastDueFoundOnUser = await checkIfUserHasPastDueSubscription();
                    if (isPastDueFoundOnUser) {
                        ShowErrorDialog({
                            error: "Failed to delete user account",
                            message: "You cannot delete your account due to an outstanding payment on your subscription. " +
                                "Please email support@beautiful.ai for assistance."
                        });
                        return;
                    }
                    await ds.teams.loadModels();
                    if (checkIfUserIsOnlyAdminInTeams(ds.teams.models, userId)) {
                        ShowErrorDialog({
                            error: "Failed to delete user account",
                            message: "You cannot delete your account if you're the only admin of a pro team."
                        });
                        return;
                    }
                    if (userGDriveEnabled) {
                        await gDrive.disable();
                    }
                    ds.shutdown();
                    Api.deleteUser.delete().then(() => {
                        trackActivity("Auth", "DeletedAccount", null, null, { workspace_id: "all" }, { audit: true });
                        trackActivity("Auth", "Logout", null, null, { workspace_id: "all" }, { audit: true });
                        window.location = "/logout";
                    });
                }
            }
        });
    }

    async updateProfile(updates) {
        const { userMenu } = this.props;
        const { user } = this.state;
        await auth().updateProfile(user, updates)
            .then(() => {
                if (updates.photoURL !== undefined) {
                    //userMenu is passed from the editor which still uses a backbone menu so we need to call updateAvatar
                    if (userMenu) {
                        userMenu.updateAvatar();
                    } else {
                        //else the profile is updated from the presentation library which is using react
                        setGlobal({ accountAvatarUrl: updates.photoURL });
                    }
                }
            })
            .catch(err => {
                logger.error(err, "user.updateProfile() failed");
            });
    }

    async updateAvatar(obj) {
        this.setState({
            avatarUrl: obj.avatar
        });
        await this.updateProfile({ photoURL: obj.avatar });
    }

    checkForVerifiedEmail() {
        app.user.waitForVerifiedEmail().then(() => {
            this.setState({
                emailVerified: true
            });
        });
    }

    async updateEmail() {
        const { email, originalEmail, previousEmail, user } = this.state;

        // No spam requests with same email over and over
        if (previousEmail === email) {
            return false;
        }

        const defaultTeams = ds.teams.getDefaultTeams();
        for (const team of defaultTeams) {
            const teamInvitesResponse = await Api.teamInvites.get({
                type: "team",
                id: team.id
            });

            const pendingInvites = teamInvitesResponse.body || [];
            const isPendingEmail = pendingInvites.some(invite =>
                invite.email === email
            );

            if (isPendingEmail) {
                ShowErrorDialog({
                    error: "Email already in use",
                    message: "This email address is associated with a pending team invitation. Please use a different email address or have the team owner cancel the pending invitation."
                });
                this.setState({
                    email: originalEmail,
                    previousEmail: null
                });
                return;
            }
        }

        app.user
            .updateEmailAddress(email)
            .then(() => {
                this.setState({
                    emailVerificationStatusText: "Verification email sent.",
                    previousEmail: email
                });
                ShowMessageDialog({
                    title: "Verification email sent.",
                    message:
                        "Please check your email for a verification link and return to this page after you click the link."
                });
            })
            .catch(err => {
                switch (err.code) {
                    case "auth/requires-recent-login":
                        ShowDialog(ReauthPasswordDialog, {
                            callback: success => {
                                this.updateEmail();
                            },
                            onCancel: () => {
                                this.setState({
                                    email: originalEmail,
                                    previousEmail: null
                                });
                                ShowErrorDialog({
                                    error: "Unable to change email",
                                    message:
                                        "You must reauthenticate your account in order to change your email address."
                                });
                            }
                        });
                        break;
                    case "auth/invalid-email":
                    case "auth/email-already-in-use":
                    default:
                        this.setState({
                            email: originalEmail,
                            previousEmail: null
                        });
                        ShowErrorDialog({
                            error: "Error sending verification email.",
                            message: err.message
                        });
                        break;
                }
            });
    }

    renderVerifiedStatus(statusText) {
        const { emailVerified, emailVerificationSent, user } = this.state;
        if (user.emailVerified) {
            return null;
        } else {
            this.checkForVerifiedEmail();
            return (
                <div
                    className={classNames({
                        ["email-verification"]: true,
                        ["email-not-verified"]: true,
                        ["email-notice-button"]:
                            !emailVerificationSent && !emailVerified
                    })}
                    onClick={() => {
                        user.sendEmailVerification().then(() => {
                            this.setState({
                                emailVerificationStatusText:
                                    "Verification email sent",
                                emailVerificationSent: true
                            });
                        });
                    }}
                >
                    {statusText}
                </div>
            );
        }
    }

    getGDriveLink() {
        return (<a href="https://drive.google.com/drive" target="_blank">your Google Drive</a>);
    }

    handleLinkWithGoogle = async () => {
        this.setUserGoogleLinkFetching(true);
        // Do google sign in flow which will bind the firebase accoint to the google account
        // used for signing in
        googleAuth.authenticate("profile email", "/login");
    }

    handleUnlinkGoogle = async () => {
        const userGDriveEnabled = this.state.userGDriveEnabled;
        ShowConfirmationDialog({
            title: "Disconnect from Google",
            message: `In order to disconnect, you must have a fallback password for login, otherwise you will have to reset your password via email.${userGDriveEnabled ? " Your Google Drive account will also be disconnected." : ""}`,
            acceptCallback: async () => {
                this.setUserGoogleLinkFetching(true);

                try {
                    if (userGDriveEnabled) {
                        this.setUserGDriveFetching(true);
                        try {
                            await gDrive.disable();
                        } catch (err) {
                            // fail gracefully if token is invalid
                            logger.error(err, "gDrive.disable() failed");
                        }
                    }

                    const { auth, firebaseUser } = this.props;

                    logger.info("Unlinking Google account from Firebase");
                    await auth.unlinkProvider(firebaseUser, "google.com");

                    logger.info("Deleting credentials from server");
                    await Api.unlinkGoogleAuth.delete();

                    logger.info("Unlinking Google account successful");

                    if (!hasPasswordProvider(firebaseUser)) {
                        logger.info("No password provider, sending password reset email");
                        await auth.sendPasswordResetEmail(firebaseUser.email);
                    }

                    this.updateUserGoogleLinkState();
                } catch (err) {
                    logger.error(err, "unlink google failed");
                }

                if (userGDriveEnabled) {
                    this.setState({ userGDriveEnabled: false });
                    this.setUserGDriveFetching(false);
                }

                this.setUserGoogleLinkFetching(false);
            }
        });
    }

    currentUserGoogleEmail() {
        return (this.props.firebaseUser.providerData
            .find(p => p.providerId === "google.com") || {})
            .email;
    }

    renderPlanInfo() {
        const { stripeData: { subscription }, plan } = this.props;

        const canManageBilling = WorkspaceController.actionPermissions[ActionPermissionsObject.BILLING].manage;

        if (!subscription || !canManageBilling) {
            return null;
        }

        let productText = "";
        let intervalText = "";
        let upradeOrCancelPlan = "cancel";
        const { current_billing_interval: interval } = subscription;

        productText = plan.name;
        if (interval) {
            intervalText = `${interval}ly`;
            if (intervalText === "yearly") {
                intervalText = "annual";
            }
        } else {
            intervalText = "free";
            upradeOrCancelPlan = "upgrade";
        }

        return (<Section title="Current Plan">
            {<StyledSubscriptionStatus>
                {productText}
                <span>({intervalText})</span>
            </StyledSubscriptionStatus>}
            <Gap10 />
            {this.renderUpgradeCancelPlanButton(upradeOrCancelPlan)}
            <Gap20 />
            <div>
                For more billing options, including payment management and history, please log in to our desktop app and navigate to Billing.
            </div>
        </Section>);
    }

    renderUpgradeCancelPlanButton(upgradeOrCancelPlan) {
        const { stripeData: { subscription } } = this.props;

        if (upgradeOrCancelPlan === "cancel") {
            if (this.hasSubscription) {
                if (subscription && subscription.cancel_at_period_end) {
                    const currentPlan = AppController.orgId ? CustomerType.TEAM : CustomerType.INDIVIDUAL;
                    // in canceled subscription period
                    return (
                        <YellowButton fullWidth color="primary" onClick={this.handleReactiveSubscription}>
                            {currentPlan === "team" ? "Reactivate Team Plan" : "Reactivate Pro Plan"}
                        </YellowButton>
                    );
                }
            }

            if (this.isInTrial) {
                return (<BlueButton fullWidth onClick={this.handleCancelSubscription}>
                    Cancel free trial
                </BlueButton>);
            }

            return (<StyledCancelButton
                fullWidth
                variant="contained"
                onClick={this.handleCancelSubscription}
            >
                Cancel plan
            </StyledCancelButton>);
        } else {
            // organizationId is undefined, so this is a personal workspace
            return (<StyledUpgradeButton fullWidth onClick={this.handleUpgradePlanClick} >
                Upgrade plan
            </StyledUpgradeButton>);
        }
    }

    handleUpgradePlanClick = () => {
        const workspaceId = AppController.workspaceId;
        const { status } = app.user.trialStatus || {};

        if (workspaceId === "personal") {
            if (status === "trial_ended" || status === "trialing") {
                ShowDialog(CheckoutDialog);
            } else if (WorkspaceController.isBasicOrGuest) {
                const props = {
                    cta: "Menubar",
                };
                openPricingPage("basic", props);
            }
        } else {
            ShowUpgradeDialog({
                type: UpgradePlanDialogType.UPGRADE_PLAN,
                analytics: { cta: "Menubar" },
                workspaceId
            });
        }
    }

    handleReactiveSubscription = async () => {
        try {
            await BillingController.reactivateSubscription();
        } catch (err) {
            logger.error(err, "handleReactiveSubscription() failed");

            ShowErrorDialog({
                error: "An error occurred while reactivating your subscription",
                message: <Fragment>
                    <p>
                        <strong>Error: </strong>
                        {err.message}
                    </p>
                    <p>We apologize for the inconvenience. Please contact us at support@beautiful.ai.</p>
                </Fragment>
            });
        }
    };

    handleCancelSubscription = async event => {
        const organizationId = AppController.orgId;
        const { stripeData: { subscription } } = this.props;
        const isOrganization = organizationId !== null && organizationId !== undefined;
        const isInDunning = subscription.status === SubscriptionStatus.PAST_DUE;
        const nextBillDate = moment.unix(subscription.current_period_end).format("MMM DD, YYYY");

        ShowConfirmationDialog({
            title: `Are you sure you want to cancel your ${this.isInTrial ? "trial" : organizationId ? "Team plan" : "Pro plan"}?`,
            message: CancelSubscribtionMessage({ isInDunning, nextBillDate, isInTrial: this.isInTrial, isOrganization }),
            cancelButtonLabel: "Never mind",
            okButtonLabel: `Cancel ${this.isInTrial ? "trial" : "plan"}`
        })
            .then(async accept => {
                if (!accept) {
                    return;
                }

                await BillingController.cancelSubscription();

                ShowDialog(DowngradePlanDialog, {
                    workspaceId: organizationId || "personal",
                    fullScreen: true
                });
            })
            .catch(err => {
                logger.error(err, "handleCancelSubscription() failed", { subscriptionId: subscription?.id, workspaceId: organizationId ?? "personal" });

                ShowErrorDialog({
                    error: "An error occurred while canceling your subscription",
                    message: (
                        <Fragment>
                            <p>
                                <strong>Error: </strong>
                                {err.message}
                            </p>
                            <p>We apologize for the inconvenience. Please contact us at support@beautiful.ai.</p>
                        </Fragment>
                    )
                });
            });
    };

    render() {
        const {
            onClose,
            firebaseUser
        } = this.props;
        const {
            avatarUrl,
            email,
            emailVerificationStatusText,
            name,
            user,
            userHasGoogleProvider,
            userGoogleLinkFetching,
            userGDriveEnabled,
            userGDriveFetching,
            canDeleteUser,
            ssoStatus,
            fetching
        } = this.state;

        return (
            <StyledUIPane isMobile={app.isConstrained}>
                <UIPaneHeader onClose={onClose}>My Account</UIPaneHeader>
                <UIPaneContents>
                    <Loadable isLoading={fetching}>
                        <Section title="Profile Settings">
                            <FlexBox horizontalAlign="left" verticalAlign="top" style={{ display: app.isConstrained ? "block" : "flex" }}>
                                <div className="avatar-editor">
                                    <UploadAvatar
                                        src={avatarUrl || ""}
                                        label="Avatar"
                                        update={obj => this.updateAvatar(obj)}
                                        attribute="avatar"
                                        showSilhouette
                                    />
                                </div>
                                <div className="input-column account-info">
                                    <TextInput
                                        label="Name"
                                        id="user-name"
                                        curValue={name}
                                        handleChange={this.handleChange("name")}
                                        handleBlur={async () => {
                                            const displayName = removeUrlsFromText(name);
                                            if (displayName !== "") {
                                                await this.updateProfile({
                                                    displayName
                                                });

                                                // Reflect the change in the state
                                                this.setState({ name: displayName });
                                            } else {
                                                ShowErrorDialog({
                                                    error:
                                                        "Unable to change display name",
                                                    message: `The display name can't be blank.`
                                                });
                                            }
                                        }}
                                    />
                                    <Gap20 />
                                    <TextInput
                                        label="Email"
                                        classNames={["email-field"]}
                                        id="email"
                                        disabled={ssoStatus === "enabled" || ssoStatus === "strict"}
                                        curValue={email}
                                        handleChange={this.handleChange("email")}
                                        handleBlur={async () => {
                                            await this.updateEmail();
                                        }}
                                    >
                                        {this.renderVerifiedStatus(
                                            emailVerificationStatusText
                                        )}
                                    </TextInput>
                                    <Gap20 />
                                    {hasPasswordProvider(user) && ssoStatus !== "strict" && (
                                        <BlueButton
                                            onClick={this.handleChangePassword}
                                        >
                                            Change Password...
                                        </BlueButton>
                                    )}
                                </div>
                            </FlexBox>
                        </Section>

                        {!app.isConstrained && !isPPTAddin &&
                            <Fragment>
                                <Section title="Account Linking">
                                    {userHasGoogleProvider && (
                                        <div style={{ marginBottom: ".5em", fontSize: "1.1em" }}>
                                            {/* when the user account's email and Google email differ, show it */}
                                            {firebaseUser.email != this.currentUserGoogleEmail() && (
                                                <div className="gdrive-notice-text" style={{ marginBottom: ".75em" }}>
                                                    Linked with <b>{this.currentUserGoogleEmail()}</b>
                                                </div>
                                            )}

                                            <GoogleButton
                                                fullWidth={false}
                                                label="Disconnect Account From Google"
                                                disabled={userGoogleLinkFetching}
                                                onClick={this.handleUnlinkGoogle}>
                                                {userGoogleLinkFetching && <Spinner />}
                                            </GoogleButton>
                                        </div>
                                    )}
                                    {!userHasGoogleProvider && (
                                        <GoogleButton
                                            label="Link Account With Google"
                                            fullWidth={false}
                                            disabled={userGoogleLinkFetching}
                                            onClick={this.handleLinkWithGoogle}>
                                            {userGoogleLinkFetching && <Spinner />}
                                        </GoogleButton>
                                    )}
                                </Section>

                                <Section title="Google Drive">
                                    {userHasGoogleProvider && userGDriveEnabled &&
                                        <div className="gdrive-notice-text">
                                            Your presentations are synced with your Google Drive. You can find your presentations in {this.getGDriveLink()}.
                                        </div>
                                    }
                                    {userHasGoogleProvider && !userGDriveEnabled &&
                                        <div className="gdrive-notice-text">
                                            Connect your Google Drive to have all your presentations available in your Google Drive.
                                        </div>
                                    }
                                    {!userHasGoogleProvider &&
                                        <div className="gdrive-notice-text">
                                            Link your account with Google to sync your presentations with Google Drive.
                                        </div>
                                    }
                                    {userHasGoogleProvider && userGDriveEnabled &&
                                        <GoogleButton
                                            label="Disconnect Google Drive"
                                            fullWidth={false}
                                            disabled={userGDriveFetching}
                                            onClick={() => {
                                                this.setUserGDriveFetching(true);
                                                gDrive.disable()
                                                    .then(() => {
                                                        this.setState({ userGDriveEnabled: false }, () => {
                                                            ShowMessageDialog({
                                                                title: "Disconnect Google Drive",
                                                                message: <span>Your account was successfully disconnected from your Google Drive.</span>
                                                            });
                                                            trackActivity("GDrive", "DisconnectSuccess", null, null, {}, { audit: true });
                                                        });
                                                    })
                                                    .catch(err => logger.error(err, "gDrive.disable() failed"))
                                                    .finally(() => {
                                                        this.setUserGDriveFetching(false);
                                                    });
                                            }}>
                                            {userGDriveFetching && <Spinner />}
                                        </GoogleButton>
                                    }
                                    {userHasGoogleProvider && !userGDriveEnabled &&
                                        <GoogleButton
                                            label="Connect Google Drive"
                                            fullWidth={false}
                                            disabled={userGDriveFetching}
                                            onClick={() => {
                                                trackActivity("GDrive", "ConnectCTAClick", null, null, {}, { audit: true });
                                                this.setUserGDriveFetching(true);

                                                gDrive.getState()
                                                    .then(({ hasOfflineAccess }) => {
                                                        if (!hasOfflineAccess) {
                                                            return gDrive.grantOfflineAccessWithDialogIfNeeded(firebaseUser.uid);
                                                        }
                                                    })
                                                    .then(() => gDrive.enable())
                                                    .then(() => {
                                                        trackActivity("GDrive", "ConnectSuccess", null, null, {}, { audit: true });
                                                        this.setState({ userGDriveEnabled: true }, () => {
                                                            ShowMessageDialog({
                                                                title: "Connect Google Drive",
                                                                message: <span>Your account was successfully connected with your Google Drive. You can find your presentations in {this.getGDriveLink()}.</span>
                                                            });
                                                        });
                                                    })
                                                    .catch(err => {
                                                        if (err instanceof googleAuth.GoogleAuthFlowWasInterruptedError) {
                                                            // User cancelled, ignore
                                                            return;
                                                        }

                                                        if (err.message === "Wrong user") {
                                                            ShowMessageDialog({
                                                                title: "Error",
                                                                message: <span>You must have selected a wrong account, your Google Drive account must match with the Google account you linked with Beautiful.ai</span>
                                                            });
                                                            return;
                                                        }

                                                        logger.error(err, "failed to enable google drive integration");
                                                    })
                                                    .finally(() => {
                                                        this.setUserGDriveFetching(false);
                                                    });
                                            }}>
                                            {userGDriveFetching && <Spinner />}
                                        </GoogleButton>
                                    }
                                </Section>

                                {canDeleteUser && <Section title="Account Deletion">
                                    <ScaryButton onClick={() => this.handleDeleteAccount()}>
                                        Delete My Account...
                                    </ScaryButton>
                                </Section>}
                                {!canDeleteUser && <Section title="Account Deletion">
                                    <div className="delete-notice-text">
                                        Note: you cannot delete your account because you are a member of a team.
                                        If you wish to delete your account, first cancel your team subscription or ask your Team Owner to remove you from the team.
                                    </div>
                                    <ScaryButton disabled>
                                        Delete My Account...
                                    </ScaryButton>
                                </Section>}
                            </Fragment>}
                    </Loadable>
                    {
                        app.isConstrained &&
                        <>
                            {this.renderPlanInfo()}
                            <div style={{ marginTop: "20px" }}>
                                <FlexBox center>
                                    <LogOutButton />
                                </FlexBox>
                                <Gap20 />
                            </div>
                        </>
                    }
                </UIPaneContents>
            </StyledUIPane>
        );
    }
}

export default WorkspaceController.withInitializedState(BillingController.withState(withFirebaseAuth(withFirebaseUser(AccountPane))));
