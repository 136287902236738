import moment from "moment";
import React from "react";
import styled from "styled-components";

import { IStripeInvoiceExtended, WorkspacePlanTier } from "common/interfaces";
import { Icon } from "js/Components/Icon";
import BillingController, { BillingControllerState } from "js/controllers/BillingController";
import WorkspaceController, { WorkspaceControllerState } from "js/controllers/WorkspaceController";
import { themeColors } from "js/react/sharedStyles";
import { UpdateBillingDetailsDialog } from "js/react/views/UserOptions/Billing/UpdateBillingDetailsDialog";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";

import { CreditCardIcon } from "./CreditCardIcon";
import { BlockContainer, BlockHeader } from "./Containers";

const CustomerEmailContainer = styled.div`
    position: absolute;
    top: 22px;
    right: 20px;
    font-size: 12px;
    color: rgb(103, 103, 103);

    > span {
        cursor: pointer;
        color: #11a9e2;
        text-decoration: underline;
    }
`;

const PaymentHistoryTable = styled.table`
    width: 100%;
    color: #333;
    border-collapse: collapse;

    > thead > tr {
        height: 30px;
        color: #555;
        font-size: 12px;
        border-bottom: solid 2px #11a9e2;
        text-transform: uppercase;
        
        > th {
            text-align: left;
            padding: 10px 12px;

            &:nth-last-child(2) {
                text-align: right;
            }

            &:last-child {
                text-align: center;
            }
        }
    }

    > tbody > tr {
        height: 30px;
        text-align: left;
        font-size: 15px;
        text-transform: capitalize;

        > td {
            padding: 10px 12px;

            &:first-child {
                margin-left: -2px;
                
                .icon {
                    width: 24px; 
                }
            }

            &:nth-last-child(2) {
                text-align: right;
            }
        }

        &:nth-child(even) {
            background: #fafafa;
        }
    }
`;

const OpenInvoiceIcon = styled(Icon)`
    cursor: pointer;

    .bai-icon { 
        color: ${themeColors.ui_blue} !important;
        font-size: 24px;
    }
`;

function InvoiceStatusIcon({ invoice }: { invoice: IStripeInvoiceExtended }) {
    let icon: string = "";
    let color: string = themeColors.ui_blue;

    if (invoice.charge) {
        switch (invoice.charge.status) {
            case "succeeded":
                icon = "done";
                color = "rgb(151, 170, 15)";
                break;
            case "pending":
                icon = "report_problem";
                color = themeColors.yellow;
                break;
            case "failed":
                icon = "close";
                color = themeColors.warning;
                break;
        }
    } else {
        if (invoice.status === "draft") {
            icon = "cancel";
            color = themeColors.ui_red;
        } else if (invoice.status === "open") {
            icon = "report_problem";
            color = themeColors.yellow;
        } else if (invoice.status === "uncollectible") {
            icon = "close";
            color = themeColors.warning;
        } else if (invoice.status === "void") {
            icon = "close";
            color = themeColors.warning;
        } else if (invoice.status === "paid") {
            icon = "check";
            color = "rgb(151, 170, 15)";
        }
    }

    return <Icon large color={color}>{icon}</Icon>;
}

const InvoiceDescriptionErrorMessage = styled.div`
    color: ${themeColors.warning};
    font-size: 12px;
    font-weight: 600;
`;

function InvoiceDescription({ invoice }: { invoice: IStripeInvoiceExtended }) {
    const lines = invoice.lines.data.map(item => {
        if (!item.description) {
            return "";
        } else if (item.description.includes("seat") && !item.description.includes("×") && item.quantity > 1) {
            return `${item.quantity} × ${item.description}`;
        } else {
            return item.description;
        }
    });

    return (<>
        {lines.map(line => <div key={line}>{line}  </div>)}
        {invoice.charge?.failure_message && <InvoiceDescriptionErrorMessage>{invoice.charge.failure_message}</InvoiceDescriptionErrorMessage>}
    </>);
}

const InvoiceDiscount = styled.div`
    font-size: 12px;
    color: ${themeColors.gray};
    white-space: nowrap;
    text-transform: none;
`;

function InvoiceTotal({ invoice }: { invoice: IExtendedStripeInvoice }) {
    const total = invoice.charge?.amount ?? invoice.total ?? null;
    const percentOff = invoice.discount?.coupon.percent_off;
    const amountOff = invoice.discount?.coupon.amount_off;

    return (<>
        {total !== null && <div>${total / 100}</div>}
        {percentOff && <InvoiceDiscount>({percentOff}% off)</InvoiceDiscount>}
        {amountOff && <InvoiceDiscount>(${amountOff / 100} off)</InvoiceDiscount>}
    </>);
}

const PaymentMethodContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 5px;
`;

function PaymentMethod({ invoice }: { invoice: IStripeInvoiceExtended }) {
    if (!invoice.charge?.payment_method_details?.card) {
        return <span>N/A</span>;
    }

    return (<PaymentMethodContainer>
        <CreditCardIcon small card={invoice.charge.payment_method_details.card} />
        ****{invoice.charge.payment_method_details.card.last4}
    </PaymentMethodContainer>);
}

export const PaymentHistory = WorkspaceController.withInitializedState(BillingController.withInitializedState(function PaymentHistory(props: BillingControllerState & WorkspaceControllerState) {
    const { stripeData: { invoices, customer }, plan } = props;

    if (plan.tier === WorkspacePlanTier.ENTERPRISE) {
        return null;
    }

    const filteredInvoices = Object.values(invoices)
        .filter(invoice => !invoice.lines.data.some(line => line.description?.includes("Trial period")))
        .sort((a, b) => b.created - a.created);

    if (filteredInvoices.length === 0) {
        return null;
    }

    const handleEditEmail = () => {
        ShowDialog(UpdateBillingDetailsDialog);
    };

    return (<BlockContainer>
        <BlockHeader>Payment History</BlockHeader>
        <CustomerEmailContainer>Receipts will be sent to: {customer.email} <span onClick={handleEditEmail}>Edit email</span></CustomerEmailContainer>
        <PaymentHistoryTable>
            <thead>
                <tr>
                    <th>Status</th>
                    <th>Date</th>
                    <th>Description</th>
                    <th>Payment Method</th>
                    <th>Charge Id</th>
                    <th>Amount</th>
                    <th>Receipt</th>
                </tr>
            </thead>
            <tbody>
                {filteredInvoices.map(invoice => (
                    <tr key={invoice.id}>
                        {invoice.charge && <>
                            <td><InvoiceStatusIcon invoice={invoice} /></td>
                            <td>{moment((invoice.created) * 1000).format("MM/DD/YYYY")}</td>
                            <td><InvoiceDescription invoice={invoice} /></td>
                            <td><PaymentMethod invoice={invoice} /></td>
                            <td>{invoice.charge.id}</td>
                            <td><InvoiceTotal invoice={invoice} /></td>
                        </>}
                        {!invoice.charge && <>
                            <td><InvoiceStatusIcon invoice={invoice} /></td>
                            <td>{moment(invoice.created * 1000).format("MM/DD/YYYY")}</td>
                            <td><InvoiceDescription invoice={invoice} /></td>
                            <td></td>
                            <td></td>
                            <td><InvoiceTotal invoice={invoice} /></td>
                        </>}
                        {invoice.hosted_invoice_url &&
                            <td><OpenInvoiceIcon onClick={() => window.open(invoice.hosted_invoice_url, "_blank")}>get_app</OpenInvoiceIcon></td>
                        }
                        {!invoice.hosted_invoice_url && <td></td>}
                    </tr>
                ))}
            </tbody>
        </PaymentHistoryTable>
    </BlockContainer>);
}));
