import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { Icon } from "js/Components/Icon";
import { themeColors } from "js/react/sharedStyles";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
`;

const Input = styled.input<{ shorten?: boolean }>`
    border: none;
    background-color: transparent;
    font-size: 18px;
    font-weight: 600;
    margin-right: ${props => props.shorten ? "30px" : undefined};

    &:active,
    &:focus {
        outline: none;
    }
`;

const IconButton = styled(Icon)`
    cursor: pointer;
`;

export interface SearchBarProps {
    placeholder: string;
    onChange?: (query: string) => void;
    onExpand?: (query: string) => void;
    onCollapse?: (query: string) => void;
}

export function SearchBar(props: SearchBarProps) {
    const {
        placeholder,
        onChange,
        onExpand,
        onCollapse
    } = props;

    const [query, setQuery] = useState("");
    const [collapsed, setCollapsed] = useState(true);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (collapsed) {
            onCollapse?.(query);
        } else {
            onExpand?.(query);
            inputRef.current?.focus();
        }
    }, [collapsed]);

    useEffect(() => {
        onChange?.(query);
    }, [query]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
    };

    const handleClear = () => {
        setQuery("");
    };

    const handleCollapse = () => {
        if (query && !collapsed) {
            return;
        }

        setCollapsed(!collapsed);
    };

    const handleBlur = () => {
        if (query) {
            return;
        }

        setCollapsed(true);
    };

    return (
        <Container>
            <IconButton
                large
                color={themeColors.ui_blue}
                onClick={handleCollapse}
            >
                search
            </IconButton>
            {!collapsed && (<Input
                placeholder={placeholder}
                value={query}
                onChange={handleChange}
                shorten={!query}
                onBlur={handleBlur}
                ref={inputRef}
            />)}
            {query && (<IconButton color="#999"
                onClick={handleClear}
            >
                close
            </IconButton>)}
        </Container>
    );
}
