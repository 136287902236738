import React from "react";

import WorkspaceController, { type WorkspaceControllerState } from "js/controllers/WorkspaceController";
import { UIPane, UIPaneContents, UIPaneHeader } from "js/react/components/UiComponents";

import ContentDistributionSettings from "./Components/ContentDistributionSettings";
import CustomPlayerUrl from "./Components/CustomPlayerUrl";
import LogsViewer from "../LogsViewer";

function AdvancedSettingPane(props: WorkspaceControllerState) {
    return (
        <UIPane>
            <UIPaneHeader>Advanced Settings</UIPaneHeader>
            <UIPaneContents>
                <ContentDistributionSettings {...props} />
                <CustomPlayerUrl {...props} />
                {/* @ts-ignore */}
                <LogsViewer organizationId={props.workspace.id} />
            </UIPaneContents>
        </UIPane>
    );
}

export default WorkspaceController.withInitializedState(AdvancedSettingPane);
