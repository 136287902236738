import { Icon, MenuItem } from "@material-ui/core";
import React, { Component } from "react";

import { ActionPermissionsObject } from "common/interfaces";
import { appVersion } from "js/config";
import WorkspaceController from "js/controllers/WorkspaceController";
import { ds } from "js/core/models/dataService";
import * as browser from "js/core/utilities/browser";
import { ClipboardType } from "js/core/utilities/clipboard";
import { CanvasExportType } from "js/editor/PresentationEditor/CanvasController";
import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";
import { app } from "js/namespaces";
import { NestedMenuItem } from "js/react/components/NestedMenuItem";
import { PopupMenu } from "js/react/components/PopupMenu";

import { ClipboardController } from "../ClipboardController";

class SlideActionsMenu extends Component {
    get pasteLabel() {
        if (app.lastCopy?.[ClipboardType.SLIDES]?.slides?.length > 1) {
            return `Paste ${app.lastCopy[ClipboardType.SLIDES].slides.length} Slides`;
        }

        return "Paste Slide";
    }

    render() {
        const { presentation, currentCanvasController, currentSlide } = this.props;

        const isLibrarySlide = currentSlide.isLibrarySlide();
        const isLibOrOwner = ds.teams.isUserLibrarianOrOwner();

        const canManageSharedSlides = WorkspaceController.actionPermissions[ActionPermissionsObject.SHARED_RESOURCES].manage;
        const canConvertToClassic = WorkspaceController.actionPermissions[ActionPermissionsObject.CONVERT_TO_CLASSIC].use;
        const canExportPresentation = WorkspaceController.actionPermissions[ActionPermissionsObject.EXPORT_PRESENTATION].use;
        const currentCanvas = currentCanvasController.canvas;

        if (!currentCanvas) {
            return (
                <PopupMenu {...this.props} disabled>
                </PopupMenu>
            );
        }

        const isOldVersion = currentCanvas.bundleVersion < appVersion && currentCanvas.canMigrate() && !isLibrarySlide;

        const isSlideSkipped = presentation.isSlideSkipped(currentSlide);

        return (
            <PopupMenu {...this.props} childrenAreMenuItems showHoverEffect={false}>
                {isOldVersion &&
                    <MenuItem
                        divider
                        onClick={() => {
                            PresentationEditorController.updateSlideVersion(currentSlide, appVersion);
                        }}
                    >
                        <Icon>upgrade</Icon>Update to Latest Slide Version
                    </MenuItem>
                }

                <MenuItem
                    divider
                    disabled={!currentCanvas.slideTemplate.canSwitchTemplate || isLibrarySlide}
                    onClick={currentCanvasController.switchTemplate}
                >
                    <Icon>change_circle</Icon>Switch Smart Slide...
                </MenuItem>

                {canConvertToClassic &&
                    <MenuItem divider
                        disabled={!currentCanvas.slideTemplate.canConvertToAuthoring || isLibrarySlide}
                        onClick={currentCanvasController.convertToClassic}
                    >
                        <Icon>category</Icon>Convert to Classic Slide
                    </MenuItem>
                }

                {
                    // show only if the user can actually edit the library items
                    // If a user is a member of a team, but a member, they can't share slide with team
                }
                {!isLibrarySlide && canManageSharedSlides &&
                    <MenuItem divider
                        disabled={currentSlide.get("template_id") == "slidePlaceholder"}
                        onClick={currentCanvasController.convertToSharedSlide}
                    >
                        <Icon>present_to_all</Icon>Share Slide with Team
                    </MenuItem>
                }
                {isLibrarySlide &&
                    <MenuItem divider
                        onClick={currentCanvasController.viewSharedSlideProperties}
                        disabled={!isLibOrOwner}
                    >
                        <Icon>info</Icon>View Shared Slide Settings...
                    </MenuItem>
                }

                {isSlideSkipped &&
                    <MenuItem
                        divider
                        onClick={() => PresentationEditorController.setSkipSlide(currentSlide, false)}
                    >
                        <Icon>visibility</Icon>Unskip Slide
                    </MenuItem>
                }
                {!isSlideSkipped &&
                    <MenuItem
                        divider
                        onClick={() => PresentationEditorController.setSkipSlide(currentSlide, true)}
                    >
                        <Icon>visibility_off</Icon>Skip Slide
                    </MenuItem>
                }

                {!browser.isFirefox &&
                    <NestedMenuItem
                        label="Copy/Paste Slides"
                        icon={<Icon>content_paste</Icon>}
                        divider
                    >
                        <MenuItem onClick={() => ClipboardController.cutSlides()}>
                            <Icon>content_cut</Icon>Cut Slide
                        </MenuItem>
                        <MenuItem onClick={() => ClipboardController.copySlides()}>
                            <Icon>content_copy</Icon>Copy Slide
                        </MenuItem>
                        <MenuItem onClick={event => ClipboardController.pasteSlides(event)}>
                            <Icon>content_paste</Icon>{this.pasteLabel}
                        </MenuItem>
                    </NestedMenuItem>
                }
                {canExportPresentation &&
                    <NestedMenuItem
                        label="Export Slide"
                        icon={<Icon>cloud_download</Icon>}
                        divider
                    >
                        <MenuItem onClick={() => currentCanvasController.exportCanvas({ type: CanvasExportType.JPEG, slide: currentSlide })}>
                            <Icon>panorama</Icon>Export Slide to JPEG
                        </MenuItem>
                        <MenuItem
                            onClick={() => currentCanvasController.exportCanvas({ type: CanvasExportType.PPTX, slide: currentSlide })}
                        >
                            <Icon>cloud_download</Icon>Export Slide to PPTX
                        </MenuItem>
                        <MenuItem
                            onClick={() => currentCanvasController.exportCanvas({ type: CanvasExportType.GOOGLE, slide: currentSlide })}
                        >
                            <Icon>add_to_drive</Icon>Export Slide to Google Slides
                        </MenuItem>
                        <MenuItem
                            onClick={() => PresentationEditorController.exportPresentation()}
                        >
                            Export Entire Presentation...
                        </MenuItem>
                    </NestedMenuItem>
                }

                <MenuItem disabled={isLibrarySlide} onClick={() => PresentationEditorController.setSelectedPropertyPanelTab("versions")}>
                    <Icon>restore</Icon>Version History
                </MenuItem>

            </PopupMenu>
        );
    }
}

export default PresentationEditorController.withState(SlideActionsMenu);
