import React, { forwardRef, useState } from "react";
import type Stripe from "stripe";
import styled from "styled-components";

import { DialogActions, DialogTitle, TextField } from "@material-ui/core";
import { AddressElement } from "@stripe/react-stripe-js";
import { Appearance } from "@stripe/stripe-js";

import { WorkspacePlanTier } from "common/interfaces";
import { Button } from "js/Components/Button";
import BillingController, { BillingControllerState } from "js/controllers/BillingController";
import WorkspaceController, { WorkspaceControllerState } from "js/controllers/WorkspaceController";
import getLogger, { LogGroup } from "js/core/logger";
import { emailRegex } from "js/core/utilities/regex";
import { BeautifulDialog, DialogContent, ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import FetchingClickShield from "js/react/components/FetchingClickShield";
import { TaxIdForm } from "js/react/views/UserOptions/Billing/TaxIdForm";
import { guessUserCountry } from "js/react/views/UserOptions/Billing/utils";
import withElements from "js/react/views/UserOptions/Billing/withElements";
import withStripe from "js/react/views/UserOptions/Billing/withStripe";
import { _ } from "js/vendor";

const logger = getLogger(LogGroup.BILLING);

const appearance: Appearance = {
    labels: "floating",
    variables: {
        fontFamily: "Source Sans Pro, sans-serif",
        borderRadius: "0px",
        colorPrimary: "#11a9e2",
        colorDanger: "#ff0000",
        gridRowSpacing: "12px",
        colorTextSecondary: "#666",
        fontSizeSm: "14px",
        fontWeightNormal: "400",
        fontWeightBold: "600",
        colorText: "black",
    },
    rules: {
        ".Input": {
            fontSize: "16px",
            color: "black",
            backgroundColor: "#fff",
            border: "none",
            borderBottom: "1px solid #ced4da",
            padding: "7px 0",
            outline: "none",
            transition: "border-color 0.3s ease-in-out",
            boxShadow: "none",
        },
        ".Field--labelResting": {
            paddingTop: "0",
        },
        ".Label--empty": {
            margin: "10px 0",
            fontSize: "1rem",
            color: "var(--colorTextSecondary)",
        },
        ".Label--focused": {
            margin: "0",
            color: "#11a9e2",
        },
        ".Label--floating": {
            color: "var(--colorTextSecondary)",
            fontSize: "var(--labelRestingFontSize)",
            lineHeight: "16px",
            opacity: "1",
            padding: "0",
            transition: "all 300ms cubic-bezier(0.5, 0, 0.2, 1)",
        },
        ".Input:focus": {
            borderColor: "#11a9e2",
            boxShadow: "none",
        },
        ".Input:focus:hover": {
            borderColor: "#11a9e2",
            boxShadow: "none",
        },
        ".Input--invalid": {
            borderBottom: "1px solid red",
            boxShadow: "none",
        },
        ".Input:hover": {
            borderColor: "#11a9e2"
        }
    }
};

const ErrorMessage = styled.div`
    margin-top: 4px;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.112px;
    color: red;
`;

const StyledTextField = styled(TextField) <{ warning?: boolean }>`
    &&&{
        .MuiInputBase-input::-webkit-input-placeholder {
            font-style: normal;
        }

        .MuiInput-underline:not(.Mui-disabled)::before {
            border-bottom: ${({ warning }) => warning ? `2px solid red` : `2px solid #11a9e2`};
        }  

        .MuiInputLabel-shrink{
            transform: translateY(7px) scale(0.8888888888888888);
            font-size: 16px;
            font-weight: 600;
            opacity: 0.8;
        }

    }
`;

// TaxIdForm has inner style, avoid overriding it as much as possible
// because it might show unexpected behavior
const StyledTaxIdForm = styled(TaxIdForm)`
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin-bottom: 12px;

    .MuiInputBase-input::-webkit-input-placeholder {
        font-style: normal;
    }

    .MuiInput-underline:hover:not(.Mui-disabled)::before {
        border-bottom: 2px solid #11a9e2;
    }      

    .MuiInputLabel-shrink{
        transform: translateY(7px) scale(0.8888888888888888);
        font-size: 16px;
        font-weight: 600;
        opacity: 0.8;
    }
`;

const EmailContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin-bottom: 12px;
`;

interface UpdateBillingDetailsDialogProps {
    closeDialog: (updated: boolean) => void;
    preventClose?: boolean;
    hideBackdrop?: boolean;
}

export const UpdateBillingDetailsDialog = WorkspaceController.withInitializedState(BillingController.withInitializedState(withStripe(withElements(forwardRef(function UpdateBillingDetailsDialog(props: UpdateBillingDetailsDialogProps & BillingControllerState & WorkspaceControllerState, ref: React.RefObject<BeautifulDialog>) {
    const { closeDialog, hideBackdrop, preventClose, stripeData: { subscription, paymentMethods, customer }, plan } = props;

    const paymentMethod = subscription.default_payment_method ?? Object.values(paymentMethods)[0];

    const [fetching, setFetching] = useState(false);

    const initialAddress = customer.address ?? paymentMethod?.billing_details.address ?? null;
    const initialName = customer.name ?? "";
    const initialEmail = customer.email ?? "";
    const initialTaxId = customer.tax_ids?.data?.[0]?.value ?? "";
    const initialTaxIdType = customer.tax_ids?.data?.[0]?.type ?? null;

    const [address, setAddress] = useState<Stripe.Address>(initialAddress);
    const [isAddressValid, setIsAddressValid] = useState<boolean>(true);
    const [taxId, setTaxId] = useState<string>(initialTaxId);
    const [taxIdType, setTaxIdType] = useState<string>(initialTaxIdType);
    const [isTaxIdValid, setIsTaxIdValid] = useState<boolean>(true);
    const [email, setEmail] = useState<string>(initialEmail);
    const [showEmailError, setShowEmailError] = useState<boolean>(false);
    const [name, setName] = useState<string>(initialName);

    const isOrganization = [WorkspacePlanTier.TEAM, WorkspacePlanTier.ENTERPRISE].includes(plan.tier);

    const isEmailValid = emailRegex.test(email);

    const isAddressFieldDirty = !_.isEqualWith(address, initialAddress, (objValue, othValue) => {
        if ((objValue === "" && othValue === null) || (objValue === null && othValue === "")) {
            return true;
        }
    });
    const isTaxIdFieldDirty = !_.isEqual(taxId, initialTaxId);
    const isEmailFieldDirty = !_.isEqual(email, initialEmail);
    const isNameFieldDirty = !_.isEqual(name, initialName);

    const handleTaxIdChange = ({ taxId, isTaxIdValid, taxIdType }) => {
        setTaxId(taxId);
        setTaxIdType(taxIdType);
        setIsTaxIdValid(isTaxIdValid);
    };

    const handleAddressChange = ({ complete, value: { address, name } }) => {
        setAddress(address);
        setName(name);
        setIsAddressValid(complete);
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const handleBlur = () => {
        setShowEmailError(true);
    };

    const handleFocus = () => {
        setShowEmailError(false);
    };

    const handleUpdate = async () => {
        setFetching(true);

        try {
            await BillingController.updateBillingDetails({
                address: (isAddressFieldDirty && isAddressValid) ? address : undefined,
                name: isNameFieldDirty ? name : undefined,
                email: (isEmailFieldDirty && isEmailValid) ? email : undefined,
                taxId: (isTaxIdFieldDirty && isTaxIdValid && taxId) ? { type: taxIdType as Stripe.TaxId["type"], value: taxId } : undefined
            });
            closeDialog(true);
        } catch (error) {
            logger.error(error, "updateBillingDetails() failed");

            ShowErrorDialog({
                title: "Error",
                message: <>Sorry, we couldn't save your changes. Please contact us at <a href="mailto:support@beautiful.ai">support@beautiful.ai</a></>
            });

            setFetching(false);
        }
    };

    return (
        <BeautifulDialog
            hideBackdrop={hideBackdrop}
            preventClose
            closeDialog={() => closeDialog(false)}
        >
            <DialogTitle>
                Update Billing Details
            </DialogTitle>
            <DialogContent>
                <FetchingClickShield visible={fetching} backgroundColor="rgba(255,255,255,0.5)" />

                <EmailContainer>
                    <StyledTextField
                        label="Billing email"
                        warning={!isEmailValid}
                        fullWidth
                        onChange={handleEmailChange}
                        value={email}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                    />
                    {!isEmailValid && showEmailError && <ErrorMessage>{"Invalid Email"}</ErrorMessage>}
                </EmailContainer>
                <StyledTaxIdForm
                    countryCode={address.country ?? guessUserCountry()}
                    initialTaxId={taxId}
                    onChange={handleTaxIdChange}
                    disabled={false}
                />
                <AddressElement
                    options={{
                        mode: "billing",
                        defaultValues: {
                            name,
                            address
                        },
                        display: {
                            name: isOrganization ? "organization" : "full"
                        },
                    }}
                    onChange={handleAddressChange}
                />
            </DialogContent>
            <DialogActions>
                {!preventClose &&
                    <Button
                        unframed
                        disabled={fetching}
                        onClick={closeDialog}
                    >
                        Cancel
                    </Button>
                }
                <Button
                    blue
                    large
                    disabled={
                        fetching ||
                        (isAddressFieldDirty && !isAddressValid) ||
                        (isTaxIdFieldDirty && !isTaxIdValid) ||
                        (isTaxIdFieldDirty && isTaxIdValid && !taxId) ||
                        (isEmailFieldDirty && !isEmailValid) ||
                        (!isTaxIdFieldDirty && !isAddressFieldDirty && !isEmailFieldDirty)
                    }
                    onClick={handleUpdate}
                >
                    Save
                </Button>
            </DialogActions>
        </BeautifulDialog>
    );
}), { appearance }))));
