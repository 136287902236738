import React from "react";
import styled from "styled-components";

import { WorkspacePlanTier } from "common/interfaces";
import { Button } from "js/Components/Button";
import { Icon } from "js/Components/Icon";
import { getStaticUrl } from "js/config";
import WorkspaceController, { WorkspaceControllerState } from "js/controllers/WorkspaceController";
import AppController, { AppControllerState } from "js/core/AppController";
import { openPricingPage, openTeamUpgrade } from "js/core/utilities/externalLinks";
import { themeColors } from "js/react/sharedStyles";

import { BlockContainer, BlockContentContainer } from "./Containers";

const CTAHeader = styled.div`
    font-size: 18px;
    font-weight: 600;
    color: #333;
    margin-top: -15px;
`;

const CTAText = styled.div`
    font-size: 14px;
    color: rgb(103, 103, 103);
    text-align: center;
`;

const CTAGrid = styled.div`
    display: grid;
    grid-template-columns: 16px 200px 16px 200px;
    align-items: center;
    gap: 15px;
    font-size: 14px;
    color: rgb(103, 103, 103);
`;

const CTAButtonsContainer = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 5px;
`;

const CTAEnterpriseContent = AppController.withState(
    function CTAEnterpriseContent(props: AppControllerState) {
        const { user } = props;

        const handleLearnMore = () => {
            openPricingPage(user.analyticsPersonalPlan, { cta: "BillingPane" });
        };

        return (<BlockContentContainer>
            <img
                src={getStaticUrl("/images/billing/billing-cta-banner.avif")}
                height="70px"
            />
            <CTAHeader>Scale your team with our Enterprise plan.</CTAHeader>
            <CTAText>
                Perfect for larger organizations, with priority support and advanced
                <br />
                control, scalability and security.
            </CTAText>
            <Button unframed large color={themeColors.ui_blue} onClick={handleLearnMore}>
                Learn More
            </Button>
        </BlockContentContainer>);
    }
);

const CTATeamContent = AppController.withState(function CTATeamContent(props: AppControllerState) {
    const { user } = props;

    const handleCreateWorkspace = () => {
        openTeamUpgrade({ cta: "BillingPane" });
    };

    const handleLearnMore = () => {
        openPricingPage(user.analyticsPersonalPlan, { cta: "BillingPane" });
    };

    return (<BlockContentContainer>
        <img
            src={getStaticUrl("/images/billing/billing-cta-banner.avif")}
            height="70px"
        />
        <CTAHeader>Work together, faster.</CTAHeader>
        <CTAText>Organize your team, share content and control your brand.</CTAText>

        <CTAGrid>
            <Icon color={themeColors.ui_blue} large>check</Icon>
            <div>Collaborative Workspace</div>
            <Icon color={themeColors.ui_blue} large>check</Icon>
            <div>Centralized Slide Library</div>
            <Icon color={themeColors.ui_blue} large>check</Icon>
            <div>Custom Company Theme</div>
            <Icon color={themeColors.ui_blue} large>check</Icon>
            <div>Custom Template Library</div>
        </CTAGrid>
        <CTAButtonsContainer>
            <Button blue large onClick={handleCreateWorkspace}>
                Create A Workspace
            </Button>
            <Button unframed color={themeColors.ui_blue} onClick={handleLearnMore}>
                Learn More
            </Button>
        </CTAButtonsContainer>
    </BlockContentContainer >);
});

export const CTABlock = WorkspaceController.withInitializedState(function CTABlock(props: WorkspaceControllerState) {
    const { plan } = props;

    if (plan.tier === WorkspacePlanTier.ENTERPRISE) {
        return null;
    }

    return (<BlockContainer>
        {plan.tier === WorkspacePlanTier.TEAM && <CTAEnterpriseContent />}
        {plan.tier !== WorkspacePlanTier.TEAM && <CTATeamContent />}
    </BlockContainer>);
});
