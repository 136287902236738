import React, { Component } from "react";
import styled from "styled-components";

import { ElementTextBlockPositionType, HeaderPositionType, TrayType } from "common/constants";
import { ActionPermissionsObject } from "common/interfaces";
import { Icon } from "js/Components/Icon";
import { getStaticUrl } from "js/config";
import WorkspaceController from "js/controllers/WorkspaceController";
import { switchSlideTemplate } from "js/core/services/sharedModelManager";
import { stopPropagation } from "js/core/utilities/stopPropagation";
import { trackActivity } from "js/core/utilities/utilities";
import { PropertyPanelContainer, PropertySection, SectionTitle } from "js/EditorComponents/PropertyPanel";
import { PropertyPanelButton } from "js/EditorComponents/PropertyPanelButton";
import CanvasPreview from "js/react/components/CanvasPreview";
import { ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";
import { themeColors } from "js/react/sharedStyles";
import { _ } from "js/vendor";

import { PropertyPanelHeader } from "./PropertyPanelHeader";

export const LAYOUT_PANEL_WIDTH = 200;

const InnerContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 15px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
`;

const VariationsThumbnail = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;

    img {
        width: 110px;
        width: 71px;
        border: solid 1px #ccc;
    }

    label {
        margin-top: 6px;
        text-transform: uppercase;
        font-size: 9px;

        max-width: 110px;
        max-width: 71px;
        text-align: center;
    }

    &:hover {
        img {
            outline: 1px solid ${themeColors.ui_blue};
        }
    }
`;

const Warning = styled.div`
    font-size: 14px;
    font-weight: 600;
    background: #f1f1f1;
    padding: 10px;
    margin-bottom: 10px;
`;

const CanvasPreviewContainer = styled.div`
    position: relative;
    width: 120px;
    height: 67.5px;
`;

export class IdeasPanel extends Component {
    state = { variations: [] }

    componentDidMount() {
        this.loadVariations();
        if (this.props.currentSlide) {
            this.props.currentSlide.presentation.on("slideTemplateChanged", this.loadVariations);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentSlide != prevProps.currentSlide) {
            this.loadVariations();
        }
    }

    componentWillUnmount() {
        if (this.props.currentSlide) {
            this.props.currentSlide.presentation.off("slideTemplateChanged", this.loadVariations);
        }
    }

    loadVariations = () => {
        const { canvas, currentSlide } = this.props;

        this.setState({ variations: canvas.getSlideIdeas() });

        let layoutPreviews = [];
        for (let layout of LayoutIdeas) {
            let slideModel = _.merge(_.cloneDeep(currentSlide.attributes), layout);
            layoutPreviews.push(
                <CanvasPreviewContainer>
                    <CanvasPreview slideModel={slideModel} scale={120 / 1280} />
                </CanvasPreviewContainer>
            );
        }
        this.setState({
            layouts: layoutPreviews
        });
    }

    handleSelectVariation = async variation => {
        const { canvas, currentSlide } = this.props;

        const currentTemplate = currentSlide.get("template_id");
        const targetTemplate = variation.template;

        try {
            if (!variation.model) {
                await switchSlideTemplate(canvas, variation.template, variation.templateProps);
            } else {
                let model = variation.model;

                if (model.primary) {
                    const element = canvas.getPrimaryElement();
                    if (model.replaceModel) {
                        // we have to keep the reference to element.model
                        Object.keys(element.model).forEach(key => delete element.model[key]);
                        Object.assign(element.model, model.primary);
                    } else {
                        // trim any arrays in the model that are being modified so we remove orphaned children
                        let clearArrayProps = (targetModel, sourceModel, path) => {
                            let sourceNode = path ? _.get(sourceModel, path) : sourceModel;
                            if (sourceNode && typeof sourceNode === "object") {
                                for (let key of Object.keys(sourceNode)) {
                                    if (sourceNode[key] instanceof Array) {
                                        let targetNode = path ? _.get(targetModel, path) : targetModel;
                                        targetNode[key] = _.take(targetNode[key], sourceNode[key].length);
                                    } else {
                                        clearArrayProps(targetModel, sourceModel, path ? path + "." + key : key);
                                    }
                                }
                            }
                        };
                        clearArrayProps(element.model, model.primary, null);

                        _.merge(element.model, model.primary);
                    }
                }

                let transition = variation.transition ?? true;
                if (targetTemplate && targetTemplate !== currentTemplate) {
                    transition = false;
                    canvas.model.template_id = targetTemplate;
                }

                if (variation.callbackMethod) {
                    canvas.getPrimaryElement()[variation.callbackMethod](variation.callbackArguments, variation);
                }

                canvas.markStylesAsDirty();

                await canvas.updateCanvasModel(transition);

                if (model.postCanvasUpdateCallback) {
                    await model.postCanvasUpdateCallback();
                }
            }

            trackActivity("Slide", "SwitchVariation", null, null, { template_id: variation.template }, { audit: false });
        } catch (err) {
            ShowWarningDialog({
                title: "Sorry, we were unable to make this change",
                message: err.message,
            });
        }
    }

    render() {
        const { canvasController } = this.props;
        const { variations } = this.state;

        const slideTemplate = canvasController.canvas.slideTemplate;

        const canConvertToAuthoring = WorkspaceController.actionPermissions[ActionPermissionsObject.CONVERT_TO_CLASSIC].use;

        return (
            <PropertyPanelContainer>
                <PropertyPanelHeader>Slide Ideas</PropertyPanelHeader>
                <PropertySection color="#f9f9f9">
                    <SectionTitle><Icon>lightbulb</Icon>Suggested Smart Slides</SectionTitle>
                    {variations.length > 0 && (
                        <InnerContainer>
                            {variations.map((variation, index) => this.renderThumbnail(variation, index))}
                        </InnerContainer>
                    )}
                    {variations.length === 0 && (
                        <Warning>Sorry, we don't have any suggestions for this type of slide right now.</Warning>
                    )}
                </PropertySection>
                {slideTemplate.canSwitchTemplate && (
                    <PropertyPanelButton icon="change_circle"
                        title="Switch to another slide..."
                        description="Switch any other smart slide and we'll try to preserve your existing content as best we can."
                        onMouseDown={stopPropagation()}
                        onClick={() => canvasController.switchTemplate()}
                    />
                )}
                {slideTemplate.canConvertToAuthoring && canConvertToAuthoring && (
                    <PropertyPanelButton icon="category"
                        title="Convert to classic"
                        description="Turn off the design AI so you can control all layout and styling options yourself."
                        onMouseDown={stopPropagation()}
                        onClick={() => canvasController.convertToClassic()}
                    />
                )}
            </PropertyPanelContainer>
        );
    }

    renderThumbnail = (variation, index) => {
        const { canvas } = this.props;

        let thumbnailUrl;
        if (variation.icon) {
            thumbnailUrl = getStaticUrl(`/images/template-icons/${variation.icon}.svg`);
        } else {
            let slideTemplate = canvas.slideTemplates[variation.template];
            if (slideTemplate) {
                thumbnailUrl = getStaticUrl(`/images/template-icons/${slideTemplate.icon}.svg`);
            }
        }

        return (
            <VariationsThumbnail key={index} onClick={() => this.handleSelectVariation(variation)}>
                <img src={thumbnailUrl} />
                <label>{variation.label}</label>
            </VariationsThumbnail>
        );
    }
}

const LayoutIdeas = [{
    layout: {
        headerPosition: HeaderPositionType.TOP,
        trayLayout: TrayType.LEFT_TRAY,
        elementTextBlockPosition: ElementTextBlockPositionType.NONE
    },
    states: [{
        tray: {
            trayWidth: 450,
            items: [{
                componentType: "TextAndImage",
                childElement: {
                    content_type: "image",
                    content_value: "ae571e55b17a417ee0941644ec14cde685d585d79666d2ba430dbe41b2c142e6-I"
                }
            }]
        },
    }]
}, {
    layout: {
        headerPosition: HeaderPositionType.TOP,
        trayLayout: TrayType.RIGHT_INLINE,
        elementTextBlockPosition: ElementTextBlockPositionType.NONE
    },
    states: [{
        tray: {
            trayWidth: 400,
            items: [{
                componentType: "TextAndImage",
                childElement: {
                    content_type: "image",
                    content_value: "ae571e55b17a417ee0941644ec14cde685d585d79666d2ba430dbe41b2c142e6-I"
                }
            }]
        },
    }]
}, {
    layout: {
        headerPosition: HeaderPositionType.LEFT,
        trayLayout: TrayType.NONE,
        elementTextBlockPosition: ElementTextBlockPositionType.NONE
    },
}, {
    layout: {
        headerPosition: HeaderPositionType.TOP,
        trayLayout: TrayType.NONE,
        elementTextBlockPosition: ElementTextBlockPositionType.TRAY
    },
    states: [{
        elementTextBlock: {
            items: [{
                componentType: "TextAndImage",
                childElement: {
                    text: [{
                        blocks: [{
                            type: "text",
                            html: "This is a sample text block",
                            textStyle: "title"
                        }]
                    }]
                }
            }]
        }
    }]
}];

