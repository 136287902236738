import React from "react";
import styled from "styled-components";

import BillingController, { BillingControllerState } from "js/controllers/BillingController";
import WorkspaceController, { WorkspaceControllerState } from "js/controllers/WorkspaceController";
import { WorkspacePlanTier } from "common/interfaces";

import { CTABlock } from "./Components/CTABlock";
import { CurrentPlanBlock } from "./Components/CurrentPlanBlock";
import { PaymentMethodBlock } from "./Components/PaymentMethodBlock";
import { PaymentHistory } from "./Components/PaymentHistory";
import { PaneContainer, PaneHeader, PaneContentContainer } from "../Components/Containers";

const ContentBlocksContainer = styled.div`
    display: grid;
    column-gap: 20px;
    grid-auto-flow: column;
`;

export default BillingController.withInitializedState(WorkspaceController.withInitializedState(function BillingPane(props: WorkspaceControllerState & BillingControllerState) {
    const { plan } = props;

    return (<PaneContainer>
        <PaneHeader>{[WorkspacePlanTier.ENTERPRISE, WorkspacePlanTier.TEAM].includes(plan.tier) ? "Team Billing" : "Billing"}</PaneHeader>
        <PaneContentContainer>
            <ContentBlocksContainer>
                <CurrentPlanBlock />
                <PaymentMethodBlock />
                <CTABlock />
            </ContentBlocksContainer>
            <PaymentHistory />
        </PaneContentContainer>
    </PaneContainer>);
}));
