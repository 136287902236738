import { DialogTitle } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { SalesforceUrls } from "common/constants";
import getObjectHash from "common/utils/getObjectHash";
import WorkspaceController, { WorkspaceControllerState } from "js/controllers/WorkspaceController";
import AppController, { AppControllerState } from "js/core/AppController";
import getLogger, { LogGroup } from "js/core/logger";
import { trackActivity } from "js/core/utilities/utilities";
import { BeautifulDialog, DialogContent, ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import { WorkspaceIntegrationType, IWorkspaceSalesforceIntegrationConfig } from "common/interfaces";

import {
    BlueButton,
    Icon,
    InputWithLabel,
    OrangeButton,
    Section,
    SectionsContainer,
    Text,
    TitleText
} from "./DialogComponents";

const logger = getLogger(LogGroup.INTEGRATIONS);

const ConnectionInfoSection = AppController.withState(WorkspaceController.withInitializedState(function NonVerifiedDomainSection(props: AppControllerState & WorkspaceControllerState) {
    const { workspace, workspaceId } = props;

    const [fetching, setFetching] = useState(false);

    useEffect(() => {
        trackActivity("SalesforceIntegration", "Open", null, null, { orgId: workspaceId });

        return () => {
            trackActivity("SalesforceIntegration", "Cancel", null, null, { orgId: workspaceId });
        };
    }, []);

    const handleLinkToSalesforceApp = () => {
        trackActivity("SalesforceIntegration", "Launch", null, null, { orgId: workspaceId });

        window.open(SalesforceUrls.APP_INSTALL_URL, "_blank");
    };

    const handleDisconnectSalesforce = async () => {
        trackActivity("SalesforceIntegration", "Disable", null, null, { orgId: workspaceId });

        setFetching(true);
        try {
            await WorkspaceController.disconnectWorkspaceIntegration({ integrationType: WorkspaceIntegrationType.SALESFORCE });
        } catch (err) {
            logger.error(err, "[ConnectionInfoSection] failed to disconnect Salesforce integration");

            ShowErrorDialog({
                error: "Error",
                message: err.status === 400 ? err.message : "Failed to disconnect Salesforce integration"
            });
        } finally {
            setFetching(false);
        }
    };

    if (!workspace.integrations.salesforce?.enabled) {
        return (
            <Section>
                <TitleText>Step 1: Add Beautiful.AI to Salesforce</TitleText>
                <Text>Click the button below to launch the Beautiful.ai installation flow for Salesforce.</Text>
                <BlueButton onClick={handleLinkToSalesforceApp} disabled={fetching}>
                    Add Beautiful.AI to Salesforce
                </BlueButton>
            </Section>
        );
    }

    if (workspace.integrations.salesforce?.enabled) {
        return (
            <Section>
                <TitleText><Icon green>check</Icon> Salesforce integration is enabled for your workspace</TitleText>
                <Text>Click the button below to disable Salesforce integration.</Text>
                <OrangeButton onClick={handleDisconnectSalesforce} disabled={fetching}>
                    Disconnect Salesforce
                </OrangeButton>
            </Section>
        );
    }
}));

const ConnectionConfigSection = AppController.withState(WorkspaceController.withInitializedState(function ConnectionConfigSection(props: AppControllerState & WorkspaceControllerState) {
    const { workspace } = props;

    const [fetching, setFetching] = useState(false);
    const [salesforceConfig, setSalesforceConfig] = useState<IWorkspaceSalesforceIntegrationConfig>(workspace.integrations.salesforce);

    useEffect(() => {
        setSalesforceConfig(workspace.integrations.salesforce);
    }, [getObjectHash(workspace.integrations.salesforce)]);

    const isSalesforceConfigDirty = getObjectHash(salesforceConfig) !== getObjectHash(workspace.integrations.salesforce);

    const handleConnectSalesforce = async () => {
        setFetching(true);
        try {
            await WorkspaceController.connectWorkspaceIntegration({ integrationType: WorkspaceIntegrationType.SALESFORCE, config: salesforceConfig });
        } catch (err) {
            logger.error(err, "[ConnectionConfigSection] failed to connect Salesforce integration");

            ShowErrorDialog({
                error: "Error",
                message: err.status === 400 ? err.message : "Failed to connect Salesforce integration"
            });
        } finally {
            setFetching(false);
        }
    };

    if (workspace.integrations.salesforce?.enabled) {
        return null;
    }

    const normalizedSalesforceConfig = salesforceConfig ?? { domainName: "", prn: "", enabled: false };

    return (
        <Section>
            <TitleText>Step 2: Connect Salesforce to Beautiful.AI</TitleText>
            <Text>Enter your domain name and admin username to connect Salesforce to Beautiful.ai.</Text>
            <InputWithLabel
                label="Domain Name"
                tooltip="The part of your salesforce workspace URL that comes before '.lightning.force.com'"
                value={normalizedSalesforceConfig.domainName}
                onChange={event => setSalesforceConfig({ ...normalizedSalesforceConfig, domainName: event.target.value })}
            />
            <InputWithLabel
                label="Admin Username"
                tooltip="Username of one of your Salesforce admin users. Usernames are usually email addresses."
                value={normalizedSalesforceConfig.prn}
                onChange={event => setSalesforceConfig({ ...normalizedSalesforceConfig, prn: event.target.value })}
            />
            <BlueButton onClick={handleConnectSalesforce} disabled={!normalizedSalesforceConfig.domainName || !normalizedSalesforceConfig.prn || !isSalesforceConfigDirty || fetching}>
                Connect Salesforce
            </BlueButton>
        </Section>
    );
}));

export function SalesforceDialog({ closeDialog }: { closeDialog: () => void; }) {
    return (
        <BeautifulDialog closeDialog={closeDialog} closeButton>
            <DialogTitle>Salesforce integration</DialogTitle>
            <DialogContent>
                <SectionsContainer>
                    <ConnectionInfoSection />
                    <ConnectionConfigSection />
                </SectionsContainer>
            </DialogContent>
        </BeautifulDialog>
    );
}
