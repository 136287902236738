import React, { useEffect, useState } from "reactn";
import styled from "styled-components";

import { ActionPermissionsObject, PermissionActionType } from "common/interfaces";
import { TextField } from "js/Components/TextField";
import WorkspaceController, { WorkspaceControllerState } from "js/controllers/WorkspaceController";
import getLogger, { LogGroup } from "js/core/logger";
import { ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import FetchingClickShield from "js/react/components/FetchingClickShield";

const logger = getLogger(LogGroup.WORKSPACES);

const Container = styled.div`
    position: relative;

    >div.MuiFormControl-root {
        min-width: 450px;

        >div.MuiInput-root {
            font-size: 18px;

            &.Mui-disabled {
                color: #888;
            }
        }

        >label.MuiFormLabel-root {
            font-size: 18px;
            color: #aaa;
        }
    }
`;

export const WorkspaceNameInput = WorkspaceController.withInitializedState(
    function WorkspaceNameInput(props: WorkspaceControllerState) {
        const { workspace } = props;

        const [workspaceName, setWorkspaceName] = useState(workspace.name);
        const [fetching, setFetching] = useState(false);

        const disabled = !WorkspaceController.actionPermissions[ActionPermissionsObject.WORKSPACE_NAME][PermissionActionType.MANAGE];

        useEffect(() => {
            setWorkspaceName(workspace.name);
        }, [workspace.name]);

        const handleChange = (value: string) => {
            setWorkspaceName(value);
        };

        const handleBlur = async () => {
            const trimmedWorkspaceName = workspaceName.trim();

            if (workspace.name === trimmedWorkspaceName) {
                return;
            }

            if (trimmedWorkspaceName.length === 0) {
                // We're puling current bubbling event in order to get the click event (if there is one)
                // that triggered blur
                // @ts-ignore
                if (window.event?.target?.tagName !== "BUTTON") {
                    ShowErrorDialog({
                        title: "Error",
                        message: "Organization name cannot be empty"
                    });
                }
                setWorkspaceName(workspace.name);
                return;
            }

            try {
                setFetching(true);

                await WorkspaceController.updateWorkspace({ name: trimmedWorkspaceName });
            } catch (err) {
                logger.error(err, "[WorkspaceNameInput] handleBlur() failed to update workspace name", { workspaceId: workspace.id });

                setWorkspaceName(workspace.name);

                ShowErrorDialog({
                    title: "Error",
                    message: "Failed to update organization name"
                });
            } finally {
                setFetching(false);
            }
        };

        return (<Container>
            <TextField
                label="ORGANIZATION NAME"
                value={workspaceName}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disabled || fetching}
                InputProps={{
                    inputProps: {
                        maxLength: 50,
                        "data-test-id": "workspace-name-input"
                    }
                }}
            />
            <FetchingClickShield visible={fetching} backgroundColor="white" />
        </Container>);
    }
);
