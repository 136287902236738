import React, { Component } from "react";
import styled from "styled-components";

import {
    ActionPermissionsObject,
    PermissionActionType,
} from "common/interfaces";
import { Button } from "js/Components/Button";
import WorkspaceController, { WorkspaceControllerState } from "js/controllers/WorkspaceController";
import AppController, { AppControllerState } from "js/core/AppController";
import Api from "js/core/api";
import getLogger from "js/core/logger";
import * as zoomAuth from "js/core/oauth/zoomAuth";
import { trackActivity } from "js/core/utilities/utilities";
import { ShowConfirmationDialog, ShowDialog, ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import { UIPane, UIPaneContents, UIPaneHeader } from "js/react/components/UiComponents";

import { SSODialog } from "./Components/SSODialog";
import { SalesforceDialog } from "./Components/SalesforceDialog";

const logger = getLogger();

const CardsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
`;

const SectionCard = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #fff;
    padding: 30px 20px;
    align-items: center;
`;

const SectionContent = styled.div`
    display: flex;
    flex-direction: column;
`;

const SectionTitle = styled.div`
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
`;

const SectionDescription = styled.div`
    font-size: 14px;
    color: #666;
`;

type IntegrationsPaneProps = AppControllerState & WorkspaceControllerState;

class IntegrationsPane extends Component<IntegrationsPaneProps, { isZoomConnected: boolean }> {
    state = {
        isZoomConnected: false
    };

    componentDidMount = async () => {
        const zoom = await Api.getZoomTokens.get();
        this.setState({ isZoomConnected: zoom?.hasTokens });
    }

    handleSSOClick = () => {
        ShowDialog(SSODialog);
    }

    handleSalesforceClick = () => {
        ShowDialog(SalesforceDialog);
    }

    handleZoomClick = () => {
        const { isZoomConnected } = this.state;

        if (!isZoomConnected) {
            trackActivity("ZoomIntegration", "Open", null, null, {});
            zoomAuth.grantOfflineAccess()
                .then(() => {
                    this.setState({ isZoomConnected: true });
                })
                .catch(err => {
                    if (err.message.includes("Failed to read a named property 'lastSearch'")) {
                        logger.info("User closed the Zoom popup, check if the connection was successful");
                        Api.getZoomTokens.get().then(zoom => {
                            if (zoom?.hasTokens) {
                                this.setState({ isZoomConnected: true });
                            }
                        });
                        return;
                    }
                    ShowErrorDialog({ title: "Failed to connect Zoom", message: err.message });
                });
        } else {
            ShowConfirmationDialog({
                title: "Disconnect Zoom",
                message: "Are you sure you want to disconnect Zoom?",
                okButtonLabel: "Disconnect",
                acceptCallback: async () => {
                    try {
                        trackActivity("ZoomIntegration", "Disable", null, null, {});
                        await Api.unlinkZoomAuth.delete();

                        logger.info("Zoom tokens deleted");
                        this.setState({ isZoomConnected: false });
                    } catch (err) {
                        logger.error(err, "handleDisconnectZoom() failed");
                        ShowErrorDialog({ title: "Failed to disconnect Zoom", message: err.message });
                    }
                }
            });
        }
    }

    render() {
        const { workspace } = this.props;

        const isSsoEnabled = workspace.sso.enabled;
        const isStrictSSOEnabled = workspace.sso.enabled && workspace.sso.strict;

        const isSalesforceEnabled = workspace.integrations.salesforce?.enabled;
        const isZoomConnected = this.state.isZoomConnected;

        return (
            <UIPane>
                <UIPaneHeader>Integrations</UIPaneHeader>
                <UIPaneContents>
                    <CardsContainer>
                        {WorkspaceController.actionPermissions[ActionPermissionsObject.SSO][PermissionActionType.MANAGE] && (
                            <SectionCard>
                                <SectionContent>
                                    <SectionTitle>Single Sign-On</SectionTitle>
                                    <SectionDescription>
                                        {isSsoEnabled
                                            ? `SSO is enabled and ${isStrictSSOEnabled ? `required` : `optional`} for your organization.`
                                            : "SSO is not yet enabled for your organization."}
                                    </SectionDescription>
                                </SectionContent>
                                <Button blue onClick={this.handleSSOClick}>
                                    {isSsoEnabled ? "Edit Configuration" : "Enable SSO"}
                                </Button>
                            </SectionCard>
                        )}
                        {WorkspaceController.actionPermissions[ActionPermissionsObject.INTEGRATIONS][PermissionActionType.MANAGE] && (
                            <SectionCard>
                                <SectionContent>
                                    <SectionTitle>Salesforce</SectionTitle>
                                    <SectionDescription>
                                        {isSalesforceEnabled
                                            ? "Salesforce is enabled for your organization"
                                            : "Salesforce is not yet enabled for your organization"}
                                    </SectionDescription>
                                </SectionContent>
                                <Button blue={!isSalesforceEnabled} warning={isSalesforceEnabled} onClick={this.handleSalesforceClick}>
                                    {isSalesforceEnabled ? "Disconnect Salesforce" : "Enable Salesforce"}
                                </Button>
                            </SectionCard>
                        )}
                        {/* {WorkspaceController.actionPermissions[ActionPermissionsObject.INTEGRATIONS][PermissionActionType.MANAGE] && (
                            <SectionCard>
                                <SectionContent>
                                    <SectionTitle>Zoom</SectionTitle>
                                    <SectionDescription>
                                        {isZoomConnected
                                            ? "Zoom is enabled on your account"
                                            : "Zoom is not yet enabled on your account"}
                                    </SectionDescription>
                                </SectionContent>
                                <Button blue={!isZoomConnected} warning={isZoomConnected} onClick={this.handleZoomClick}>
                                    {isZoomConnected ? "Disconnect Zoom" : "Launch Zoom"}
                                </Button>
                            </SectionCard>
                        )} */}
                    </CardsContainer>
                </UIPaneContents>
            </UIPane >
        );
    }
}

export default AppController.withState(WorkspaceController.withInitializedState(IntegrationsPane));
