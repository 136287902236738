import React, { Fragment } from "react";

import { ASSET_MAX_SIZE, AssetType, TrayType } from "common/constants";
import getLogger from "js/core/logger";
import { ds } from "js/core/models/dataService";
import { clipboardRead, ClipboardType, clipboardWrite } from "js/core/utilities/clipboard";
import { getImageType, isValidMediaType } from "js/core/utilities/imageUtilities";
import { Key } from "js/core/utilities/keys";
import { app } from "js/namespaces";
import {
    ShowDialog,
    ShowUnsupportedFileTypesDialog,
    ShowWarningDialog
} from "js/react/components/Dialogs/BaseDialog";
import ProgressDialog from "js/react/components/Dialogs/ProgressDialog";
import { _ } from "js/vendor";

import PresentationEditorController from "./PresentationEditorController";

const logger = getLogger();

function getClipboardElement(element) {
    if (!element) return false;

    if (element.clipboardElement) {
        return element.clipboardElement;
    }
    for (let childElement of Object.values(element.elements)) {
        return getClipboardElement(childElement);
    }
}

function getTargetForPastedFile(element) {
    if (!element) return false;
    if (element && element.canPasteImage && element.canEdit) {
        return element;
    }
    for (let childElement of Object.values(element.elements)) {
        if (!childElement.isInstanceOf("DecorationElement")) return getTargetForPastedFile(childElement);
    }
}

function getCopyableModel(element) {
    let model = _.omit(element.model, ["id"]);

    // special case so we don't select the entire model when just a media element is selected
    // TODO: this should be revised as part of the model normalization project
    if (element.isInstanceOf("MediaElement")) {
        model = _.pick(element.model, ["content_value", "content_type"]);
    }

    if (model.content_type === AssetType.IMAGE) {
        model.aoiLeft = null;
        model.aoiRight = null;
        model.aoiTop = null;
        model.aoiBottom = null;
        model.scale = null;
    }

    return model;
}

export class ClipboardController {
    static onCopyOrCut(event) {
        // Defer to dialog handling of clipboard
        if (
            app.dialogManager.openDialogs.length ||
            PresentationEditorController.activePanel
        ) {
            return;
        }

        if (
            event.target.nodeName === "TEXTAREA" ||
            event.target.nodeName === "INPUT" ||
            (
                event.target.nodeName === "DIV" &&
                event.target.getAttribute("contenteditable") === "true"
            )
        ) {
            // Defer to text input/element handling of clipboard
            return;
        }

        const canvasController = PresentationEditorController.canvasController;
        const canvas = canvasController.canvas;
        const primaryElement = canvas.getPrimaryElement();

        // let the authoringLayer handle copying from AuthoringCanvas
        if (primaryElement.type == "AuthoringCanvas") {
            if (primaryElement.authoringLayer) {
                primaryElement.authoringLayer.copyToClipboard(event.which == Key.KEY_X);
            }
            return;
        }

        const selectedElement = PresentationEditorController.selectionLayerController.selectedElements.length === 1 ? PresentationEditorController.selectionLayerController.selectedElements[0] : null;

        if (selectedElement) {
            let element;
            let layoutModel;
            let rootElement = selectedElement.getRootElement();
            // special case when copying from a tray
            if (rootElement.type == "TrayContainer" && rootElement.elements.trayElement?.itemCount == 1) {
                element = rootElement;
                layoutModel = {
                    trayLayout: canvas.model.layout.trayLayout
                };
            } else if (selectedElement.type === "TextElement" && selectedElement.parentElement.isCallout) {
                // Special case when copying from a callout
                element = getClipboardElement(selectedElement.parentElement);
            } else {
                // get the clipboard element from the current selected element (if any)
                element = getClipboardElement(selectedElement);
            }

            if (element) {
                let model = getCopyableModel(element);
                logger.info("copy element onto clipboard", { type: element.type, model, layoutModel });

                let clipboardType = ClipboardType.ELEMENT_MODEL;

                if (rootElement.isInstanceOf("AuthoringCanvas")) {
                    clipboardType = ClipboardType.AUTHORING;
                    clipboardWrite({ [clipboardType]: [model] });
                } else {
                    clipboardWrite({ [clipboardType]: { elementType: element.type, model, layoutModel } });
                }

                if (event.which == Key.KEY_X) {
                    // try to cut element or asset

                    if (element.isInstanceOf("TrayContainer")) {
                        // delete the tray
                        canvas.model.layout.trayLayout = TrayType.NONE;
                    } else if (element.isInstanceOf("CollectionItemElement")) {
                        // delete the item from the collection
                        element.parentElement.deleteItem(element.model.id);
                    } else if (element.isInstanceOf("MediaElement")) {
                        // delete the asset
                        element.model.content_value = null;
                        element.model.content_type = null;
                        element.model.aoiLeft = element.model.aoiRight = element.model.aoiTop = element.model.aoiBottom = element.model.scale = null;
                    }
                    try {
                        element.canvas.updateCanvasModel(false);
                    } catch (err) {
                        ShowWarningDialog({ title: "Unable to cut element", message: `An error occurred while trying to remove the item: ${err}}` });
                    }
                }
            } else {
                ShowWarningDialog({ title: "The selected element does not support copy/paste actions currently" });
            }
        } else {
            if (event.which == Key.KEY_X) {
                this.cutSlides();
            } else {
                this.copySlides();
            }
        }
    }

    static async onPaste(event) {
        // Defer to dialog handling of clipboard
        if (
            app.dialogManager.openDialogs.length ||
            PresentationEditorController.activePanel ||
            event.target.parentNode.classList.contains("commentText")
        ) {
            return;
        }

        if (
            event.target.nodeName === "TEXTAREA" ||
            event.target.nodeName === "INPUT" ||
            (
                event.target.nodeName === "DIV" &&
                event.target.getAttribute("contenteditable") === "true"
            )
        ) {
            // Defer to text input/element handling of clipboard
            return;
        }

        let clipboard = event?.originalEvent?.clipboardData || event?.clipboardData;
        let pastedFiles = clipboard?.files;

        if (pastedFiles.length) {
            pasteFile(pastedFiles);
            return;
        }

        // Read the clipboard from either the event or the navigator API.
        //   Reading from event is needed so Safari clipboard works.
        let clipboardData = null;
        clipboardData = await clipboardRead([ClipboardType.ELEMENT_MODEL], event);
        if (clipboardData) {
            return pasteElementModel(clipboardData);
        }
        clipboardData = await clipboardRead([ClipboardType.ASSET], event);
        if (clipboardData) {
            return pasteAsset(clipboardData);
        }
        clipboardData = await clipboardRead([ClipboardType.SLIDES], event);
        if (clipboardData) {
            return ClipboardController.insertSlides(clipboardData);
        }
        clipboardData = await clipboardRead([ClipboardType.AUTHORING], event);
        const text = await navigator.clipboard.readText();
        if (clipboardData || text) {
            return pasteAuthoringElement(clipboardData ?? text);
        }
    }

    static cutSlides() {
        const { presentation, currentSlide } = PresentationEditorController;
        presentation.cutSlides([currentSlide]);
    }

    static copySlides() {
        const { presentation, currentSlide } = PresentationEditorController;
        presentation.copySlides([currentSlide]);
    }

    static async insertSlides(clipboardData, insertIndex = null) {
        const { presentation, currentSlide } = PresentationEditorController;

        insertIndex = insertIndex ?? (currentSlide.getIndex() + 1);
        return await presentation.pasteSlides(insertIndex, clipboardData, true);
    }

    static async pasteSlides(event, pasteIndex) {
        let clipboardData = await clipboardRead([ClipboardType.SLIDES], event);
        return await ClipboardController.insertSlides(clipboardData, pasteIndex);
    }

    static async uploadAssetFromPaste(file, customErrorDialogMessage) {
        if (file) {
            if (file.type.indexOf("image") == 0 && file.size > ASSET_MAX_SIZE) {
                return ShowWarningDialog({
                    title: "Upload Error",
                    message: `Something went wrong with the upload. Please try dragging and dropping your image instead. Or, reduce the file size (<${ASSET_MAX_SIZE / 1024 / 1024}MB) and try again.`
                });
            }

            if (!isValidMediaType(file).isValidMedia) {
                return ShowUnsupportedFileTypesDialog();
            }

            const dialog = ShowDialog(ProgressDialog, {
                title: "Importing asset...",
            });

            const fileType = getImageType(file);
            try {
                let asset = await ds.assets.getOrCreateImage({
                    file,
                    fileType,
                    assetType: AssetType.IMAGE,
                    metadata: {
                        source: "paste"
                    }
                });
                return asset;
            } catch (err) {
                logger.error(err, "handlePasteImage() ds.assets.getOrCreateImage() failed", { fileType });
                if (customErrorDialogMessage) {
                    customErrorDialogMessage();
                } else {
                    ShowWarningDialog({
                        title: "Sorry!",
                        message: "We were unable to upload this image. Please try again.",
                    });
                }
                return false;
            } finally {
                dialog.props.closeDialog();
            }
        }
    }
}

async function pasteElementModel({ elementType, model, layoutModel }) {
    let hasAsset = model.hasOwnProperty("content_value");

    const selectionLayerController = PresentationEditorController.selectionLayerController;
    const selectedElement = selectionLayerController.selectedElements.length === 1 ? selectionLayerController.selectedElements[0] : null;

    const canvasController = PresentationEditorController.canvasController;
    const canvas = canvasController.canvas;

    logger.info("paste element model from clipboard", { type: elementType, model, layoutModel });

    // special case when pasting a tray from clipboard
    if (elementType == "TrayContainer") {
        if (!canvas.slideTemplate.availableTrayLayouts.contains(layoutModel.trayLayout)) {
            return ShowWarningDialog({ title: "Unable to paste tray onto this slide", message: "This slide does not support the tray on the clipboard." });
        }
        canvas.model.layout = _.merge(canvas.model.layout, layoutModel);
        canvas.model.elements.tray = model;

        try {
            await canvas.updateCanvasModel(false);
        } catch (err) {
            ShowWarningDialog({ title: "Unable to paste tray onto this slide", message: `An error occurred while attempting to add the data on the clipboard to the slide: ${err}` });
        }
        return;
    }

    // get the target clipboard element using either the selected element or the primary element if nothing is selected
    const clipboardElement = (selectedElement || canvas.getPrimaryElement()).clipboardElement;

    if (clipboardElement) {
        if (clipboardElement.handlePasteModel) {
            try {
                clipboardElement.handlePasteModel(model);
            } catch (err) {
                ShowWarningDialog({ title: "Unable to paste onto this slide", message: `An error occurred while attempting to add the data on the clipboard to the slide: ${err}` });
            }
        }

        // if (clipboardElement.isInstanceOf("AuthoringCanvas")) {
        //     if (hasAsset) {
        //         try {
        //             let asset = await ds.assets.getAssetById(model.content_value);
        //             clipboardElement.authoringLayer.pasteAssetFromClipboard(asset);
        //         } catch (err) {
        //             ShowWarningDialog({ title: "Unable to paste media onto this slide", message: `An error occured while attempting to add the media on the clipboard to the slide: ${err}` });
        //         }
        //     } else {
        //         ShowWarningDialog({ title: "Unable to paste this onto this Classic slide", message: `Classic slides allow pasting of media elements or other elements copied from Classic slides only.` });
        //     }
        //     return;
        // }
        //
        // // check if we are pasting into a collection element and can paste a new item
        // if (clipboardElement.isInstanceOf("CollectionElement") && clipboardElement.canPasteNewElement && clipboardElement.addItem) {
        //     if (clipboardElement.itemCount >= clipboardElement.maxItemCount) {
        //         return ShowWarningDialog({ title: "Unable to paste item onto this slide", message: "This slide already contains the maximum number of allowed items" });
        //     }
        //
        //     try {
        //         let newItemModel = clipboardElement.addItem(model);
        //         await canvas.updateCanvasModel(false);
        //
        //         let newItem = clipboardElement.findChildById(newItemModel.id);
        //         selectionLayerController.setSelectedElements([newItem]);
        //     } catch (err) {
        //         ShowWarningDialog({ title: "Unable to paste item onto this slide", message: `An error occurred while attempting to add the data on the clipboard to the collection: ${err}` });
        //     }
        //     return;
        // }
        //
        // // paste over existing model
        // clipboardElement.model = _.merge(clipboardElement.model, model);
        // canvas.updateCanvasModel(false);
    } else if (hasAsset) {
        // if no valid clipboardElement was found, check to see if there is any valid target for pasting the asset

        let targetElement = getTargetForPastedFile(selectedElement || canvas.getPrimaryElement());
        if (targetElement) {
            targetElement.model.content_type = model.content_type;
            targetElement.model.content_value = model.content_value;
            targetElement.model.aoiBottom = targetElement.model.aoiTop = targetElement.model.aoiLeft = targetElement.model.aoiRight = targetElement.model.scale = null;

            canvas.updateCanvasModel(false);
        } else {
            ShowWarningDialog({
                title: "Unable to paste asset",
                message: "Please select an element on your slide that support media assets before pasting."
            });
        }
    } else {
        ShowWarningDialog({ title: "Unable to paste onto this slide", message: `This slide doesn't support pasting this type of data.` });
    }
}

async function pasteFile(pastedFiles) {
    const canvasController = PresentationEditorController.canvasController;
    const canvas = canvasController.canvas;

    const selectionLayerController = PresentationEditorController.selectionLayerController;
    const selectedElement = selectionLayerController.selectedElements.length === 1 ? selectionLayerController.selectedElements[0] : null;
    const rolloverElement = selectionLayerController.rolloverElements.length === 1 ? selectionLayerController.rolloverElements[0] : null;

    const showErrorDialog = () => ShowWarningDialog({
        title: "Unable to paste file from clipboard",
        message: (
            <Fragment>
                <p style={{ textAlign: "left" }}>
                    The file on the clipboard can not be pasted onto this slide.
                    Supported image assets can be pasted
                    into
                    <ul>
                        <li>Smart slides with a media element (PhotoCollage, Headline, Icons with Text, Photos with
                            Text, etc.)
                        </li>
                        <li>Classic slides</li>
                    </ul>
                </p>
            </Fragment>
        )
    });

    // if there is a file on the clipboard, try to paste it into any selected element
    if (pastedFiles.length) {
        let targetElement = getTargetForPastedFile(selectedElement ?? rolloverElement ?? canvas.getPrimaryElement());

        // There is a case when the user selects an authoring element, then pastes an image from the clipboard.
        // The target will return as empty because the authoring element is not a valid target for pasting.
        // In this case, we want to paste the image into the root element hence the check for the AuthoringCanvas type.
        if (!targetElement && canvas.getPrimaryElement().isInstanceOf("AuthoringCanvas")) {
            targetElement = getTargetForPastedFile(canvas.getPrimaryElement());
        }

        if (targetElement) {
            const asset = await ClipboardController.uploadAssetFromPaste(pastedFiles[0], showErrorDialog);
            // we return if the asset is false, meaning the upload failed, we already showed a dialog to the user
            if (!asset) {
                return;
            } else {
                if (targetElement.isInstanceOf("AuthoringCanvas")) {
                    // let authoringLayer handle the asset paste
                    targetElement.authoringLayer.pasteAssetFromClipboard(asset);
                } else {
                    targetElement.model.aoiBottom = null;
                    targetElement.model.aoiTop = null;
                    targetElement.model.aoiLeft = null;
                    targetElement.model.aoiRight = null;
                    targetElement.model.scale = null;
                    // Get the default overlay for the target element and call the getElementModelUpdatesForAsset method
                    if (targetElement.uiRefs?.defaultOverlayRef?.current?.getElementModelUpdatesForAsset) {
                        targetElement.uiRefs?.defaultOverlayRef?.current?.getElementModelUpdatesForAsset(asset);
                    } else {
                        // set content model
                        targetElement.model.content_type = "image";
                        targetElement.model.content_value = asset.id;
                        targetElement.canvas.updateCanvasModel(false);
                    }
                }
                return;
            }
        }
    }
}

async function pasteAuthoringElement(data) {
    const { elementParentName } = data[0];
    const canvasController = PresentationEditorController.canvasController;
    const canvas = canvasController.canvas;

    // Determine the primary element based on the slide template and element parent name
    const isAuthoringCanvas = canvas.slideTemplate.elementType === "AuthoringCanvas";
    const primaryElement = canvas.getPrimaryElement();
    const calloutsElement = canvas.getCalloutsElement() ?? primaryElement;

    const authoringOrCalloutsElement = isAuthoringCanvas ? primaryElement : calloutsElement;

    // Special Check if the element is a callout on an authoring canvas
    const isCalloutOnAuthoring = elementParentName === "Callouts" && canvas.slideTemplate.elementType === "AuthoringCanvas";

    // Attempt to paste from clipboard if conditions are met
    const canPasteFromClipboard = authoringOrCalloutsElement.name === elementParentName &&
    authoringOrCalloutsElement.pasteFromClipboard &&
    !isCalloutOnAuthoring;

    if (canPasteFromClipboard) {
        authoringOrCalloutsElement.pasteFromClipboard(data);
    } else {
        ShowWarningDialog({
            title: "Oops! We aren’t able to paste your clipboard content.",
            message: "This slide does not support the element type you are attempting to paste."
        });
    }
}

async function pasteAsset(data) {
    let hasAsset = data.hasOwnProperty("content_value");

    const selectionLayerController = PresentationEditorController.selectionLayerController;
    const selectedElement = selectionLayerController.selectedElements.length === 1 ? selectionLayerController.selectedElements[0] : null;
    const canvasController = PresentationEditorController.canvasController;
    const canvas = canvasController.canvas;

    if (hasAsset) {
        let targetElement = getTargetForPastedFile(selectedElement ?? canvas.getPrimaryElement());
        if (targetElement) {
            targetElement.model.content_type = data.content_type;
            targetElement.model.content_value = data.content_value;
            targetElement.model.aoiBottom = targetElement.model.aoiTop = targetElement.model.aoiLeft = targetElement.model.aoiRight = targetElement.model.scale = null;

            targetElement.canvas.updateCanvasModel(false);
        } else {
            ShowWarningDialog({
                title: "Unable to paste asset",
                message: "Please select an element on your slide that support media assets before pasting."
            });
        }
    }
}
