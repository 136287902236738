import React, { Component } from "react";

import { Icon } from "js/Components/Icon";
import { MenuItem } from "js/Components/Menu";
import { Popup, PopupContent, PopupPreview } from "js/Components/Popup";
import { ToggleSwitch } from "js/Components/ToggleSwitch";
import { WithLabel } from "js/Components/WithLabel";
import { isInternalBAIUser, isPPTAddin } from "js/config";
import AppController from "js/core/AppController";
import DebugController from "js/core/DebugController";
import { ShowUpgradeDialog } from "js/react/components/Dialogs/BaseDialog";
import { FlexBox } from "js/react/components/LayoutGrid";
import ProBadge from "js/react/components/ProBadge";
import { UpgradePlanDialogType } from "js/react/views/MarketingDialogs/UpgradePlanDialog";

import { ActionPermissionsObject } from "common/interfaces";
import { PropertyPanelButton } from "js/EditorComponents/PropertyPanelButton";
import WorkspaceController from "js/controllers/WorkspaceController";
import { PropertyPanelHeader } from "js/editor/ElementEditor/PropertyPanelHeader";
import { CanvasExportType } from "js/editor/PresentationEditor/CanvasController";
import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";
import { PropertyPanelContainer, PropertySection, SectionDescription, SectionTitle } from "../../EditorComponents/PropertyPanel";

import { stopPropagation } from "js/core/utilities/stopPropagation";

class ManageSlidePanel extends Component {
    render() {
        const { presentation, currentCanvasController, currentSlide, isSingleSlideEditor } = this.props;

        const workspaceId = presentation.getWorkspaceId();
        const canManageSharedSlides = WorkspaceController.actionPermissions[ActionPermissionsObject.SHARED_RESOURCES].manage;
        const canExportPresentation = WorkspaceController.actionPermissions[ActionPermissionsObject.EXPORT_PRESENTATION].use;
        const canViewRevisionHistory = WorkspaceController.actionPermissions[ActionPermissionsObject.REVISION_HISTORY].use;
        const canUpgradeToViewRevisionHistory = WorkspaceController.canUpgradeToRemoveActionRestriction(ActionPermissionsObject.REVISION_HISTORY, "use");
        const isLibrarySlide = currentSlide.isLibrarySlide();
        const isSlidePlaceholder = currentSlide.get("template_id") === "slidePlaceholder";
        const isSlideSkipped = presentation.isSlideSkipped(currentSlide);

        return (
            <PropertyPanelContainer onMouseDown={stopPropagation()}>
                <PropertyPanelHeader>Manage Slide</PropertyPanelHeader>
                {!isLibrarySlide && !isSingleSlideEditor && canManageSharedSlides && (
                    <PropertyPanelButton icon="present_to_all"
                        title="Share Slide With Team"
                        description="Add this slide to your team resources so other members of your team can reuse it."
                        disabled={isSlidePlaceholder}
                        onClick={currentCanvasController.convertToSharedSlide}
                    />
                )}

                {isLibrarySlide && !isSingleSlideEditor && (
                    <PropertyPanelButton icon="info"
                        title="Shared Slide Settings"
                        description="View and edit your Shared Slide properties."
                        disabled={!WorkspaceController.isOwner && !WorkspaceController.isLibrarian}
                        onClick={currentCanvasController.viewSharedSlideProperties}
                    />
                )}

                {!isSingleSlideEditor && !isPPTAddin && (
                    <>
                        <PropertySection>
                            <div>
                                <SectionTitle><Icon>{isSlideSkipped ? "visibility_off" : "visibility"}</Icon>Skip Slide</SectionTitle>
                                <SectionDescription>You can skip this slide during playback.</SectionDescription>
                            </div>
                            <WithLabel label="Skip this Slide during playback">
                                <ToggleSwitch value={isSlideSkipped}
                                    onChange={value => PresentationEditorController.setSkipSlide(currentSlide, value)}
                                />
                            </WithLabel>
                        </PropertySection>
                    </>
                )}

                {!isPPTAddin && (
                    <PropertyPanelButton icon="chat" title={`${PresentationEditorController.isNotesPanelActive ? "Hide" : "Show"} Speaker Notes`}
                        onClick={() => PresentationEditorController.toggleNotes()}
                    />
                )}

                {!isSingleSlideEditor && !isPPTAddin && canExportPresentation && (
                    <Popup overrideContainerStyles={{ flexShrink: 0 }}>
                        <PopupPreview>
                            <PropertyPanelButton icon="cloud_download"
                                title="Export Slide..."
                                description="Export this slide to PPT, Google or PDF."
                            />
                        </PopupPreview>
                        <PopupContent>
                            {closePopup => (
                                <>
                                    <MenuItem onClick={() => {
                                        currentCanvasController.exportCanvas({ type: CanvasExportType.JPEG, slide: currentSlide });
                                        closePopup();
                                    }}
                                    >
                                        <Icon>panorama</Icon>Export Slide to JPEG
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        currentCanvasController.exportCanvas({ type: CanvasExportType.PPTX, slide: currentSlide });
                                        closePopup();
                                    }}
                                    >
                                        <Icon>cloud_download</Icon>Export Slide to PPTX
                                    </MenuItem>
                                    <MenuItem divider onClick={() => {
                                        currentCanvasController.exportCanvas({ type: CanvasExportType.GOOGLE, slide: currentSlide });
                                        closePopup();
                                    }}
                                    >
                                        <Icon>add_to_drive</Icon>Export Slide to Google Slides
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        PresentationEditorController.exportPresentation();
                                        closePopup();
                                    }}
                                    >
                                        Export Entire Presentation...
                                    </MenuItem>
                                </>
                            )}
                        </PopupContent>
                    </Popup>
                )}

                {!isSingleSlideEditor && (
                    <FlexBox>
                        <PropertyPanelButton
                            disabled={isLibrarySlide}
                            icon="restore"
                            title="Revert to Previous Version..."
                            description="Show the version history for this slide and revert to a previous version if desired."
                            onClick={() => {
                                if (canViewRevisionHistory) {
                                    PresentationEditorController.setSelectedPropertyPanelTab("versions");
                                } else if (canUpgradeToViewRevisionHistory) {
                                    ShowUpgradeDialog({
                                        type: UpgradePlanDialogType.UPGRADE_PLAN,
                                        analytics: { cta: "revertToPreviousVersion", ...presentation.getAnalytics() },
                                        workspaceId
                                    });
                                }
                            }}
                        />
                        <ProBadge
                            upgradeType={UpgradePlanDialogType.UPGRADE_PLAN}
                            show={canUpgradeToViewRevisionHistory}
                            analytics={{ cta: "revertToPreviousVersion", ...presentation.getAnalytics() }}
                            workspaceId={workspaceId}
                            style={{ position: "absolute", width: 30, right: 5 }}
                        />
                    </FlexBox>
                )}

                {isInternalBAIUser(AppController.user.getEmail()) && (
                    <PropertyPanelButton icon="bug_report"
                        title="Debug Panel"
                        description="Activate Debug Panel"
                        onClick={() => {
                            DebugController.showDebugButton();
                            PresentationEditorController.setSelectedPropertyPanelTab("debug");
                        }}
                    />
                )}
                {isInternalBAIUser(AppController.user.getEmail()) && (
                    <PropertyPanelButton icon="photo_camera"
                        title="Open in Renderer"
                        description="Open in Renderer"
                        onClick={() => {
                            const search = new URLSearchParams();
                            search.set("presentationId", presentation.id);
                            search.set("slideIndex", PresentationEditorController.getCurrentSlideIndex() + 1);
                            search.set("legacy", (currentSlide.get("migrationVersion") ?? 0) < 10.02);

                            window.open(`/admin/renderer?${search.toString()}`, "_blank");
                        }}
                    />
                )}
            </PropertyPanelContainer>
        );
    }
}

export default PresentationEditorController.withState(ManageSlidePanel);
