import React, { Component } from "react";
import styled from "styled-components";

import { AdvanceSlideOnType } from "common/constants";

import {
    Mouse as MouseIcon, MusicNote as MusicNoteIcon, VideoCall,
} from "@material-ui/icons";

import { BoxButton } from "js/react/views/AnimationPanel/Components/BoxButton";
import { DurationControl } from "js/react/views/AnimationPanel/Components/DurationControl";
import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";
import { PropertyPanelHeader } from "../../ElementEditor/PropertyPanelHeader";
import { HorizontalPropertyList, PropertyPanelContainer, PropertySection } from "../../../EditorComponents/PropertyPanel";
import { WithLabel } from "../../../Components/WithLabel";
import { ToggleButtons, VerticalToggleButton } from "../../../Components/ToggleButtons";
import { Icon } from "../../../Components/Icon";

const Container = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    width: 100%;
    background: #f1f1f1;
`;

const AdvanceTypeSelectContainer = styled.div`
    //width: 250px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 10px;
`;

class AdvanceNextSlidePanel extends Component {
    handleChangeType = advanceOn => {
        const { currentSlide } = this.props;
        currentSlide.update({ advanceOn });
        // this.forceUpdate();
    }

    handleChangeDelay = advanceDelay => {
        const { currentSlide } = this.props;
        currentSlide.update({ advanceDelay });
        // this.forceUpdate();
    }

    render() {
        const { currentSlide } = this.props;

        const hasAudioAsset = currentSlide.has("audioAsset");
        const hasVideoOverlay = (
            // currentSlide?.dataState?.elements?.primary.videoAssetId != null || // video slide
            currentSlide?.dataState?.elements?.videoOverlay?.videoAssetId != null // video bubble overlay
        );

        let advanceOn = currentSlide.get("advanceOn") ?? AdvanceSlideOnType.CLICK;
        if (advanceOn === AdvanceSlideOnType.AUDIO && !hasAudioAsset) {
            advanceOn = AdvanceSlideOnType.CLICK;
        }
        let advanceDelay = currentSlide.get("advanceDelay") ?? 5;

        return (<PropertyPanelContainer>
            <PropertyPanelHeader>
                Advance to Next Slide
            </PropertyPanelHeader>
            <PropertySection>
                <ToggleButtons value={advanceOn} onChange={this.handleChangeType}>
                    <VerticalToggleButton value={AdvanceSlideOnType.CLICK}>
                        <Icon>mouse</Icon>
                        On Click
                    </VerticalToggleButton>
                    <VerticalToggleButton value={AdvanceSlideOnType.DELAY}>
                        <DurationControl value={advanceDelay}
                            onChange={this.handleChangeDelay} />
                        Timed
                    </VerticalToggleButton>
                    {hasAudioAsset &&
                        <VerticalToggleButton value={AdvanceSlideOnType.AUDIO}>
                            <Icon>music_note</Icon>
                            After Audio
                        </VerticalToggleButton>
                    }
                    {hasVideoOverlay &&
                        <VerticalToggleButton value={AdvanceSlideOnType.VIDEO}>
                            <Icon>video_call</Icon>
                            After Video
                        </VerticalToggleButton>
                    }
                </ToggleButtons>

                {/*<HorizontalPropertyList gap={10}>*/}
                {/*    <BoxButton*/}
                {/*        selected={advanceOn === AdvanceSlideOnType.CLICK}*/}
                {/*        onClick={() => this.handleChangeType(AdvanceSlideOnType.CLICK)}*/}
                {/*        IconType={MouseIcon}*/}
                {/*        backgroundColor="#eeeeee"*/}
                {/*        selectedBackgroundColor="#dbe9f0"*/}
                {/*        labelText={"on click"}*/}
                {/*        width={80}*/}
                {/*        height={"58px"}*/}
                {/*    />*/}
                {/*    <BoxButton*/}
                {/*        selected={advanceOn === AdvanceSlideOnType.DELAY}*/}
                {/*        onClick={() => this.handleChangeType(AdvanceSlideOnType.DELAY)}*/}
                {/*        CustomControl={<DurationControl value={advanceDelay}*/}
                {/*                                        onChange={this.handleChangeDelay} />}*/}
                {/*        backgroundColor="#eeeeee"*/}
                {/*        selectedBackgroundColor="#dbe9f0"*/}
                {/*        labelText={"timed"}*/}
                {/*        width={80}*/}
                {/*        height={"58px"}*/}
                {/*    />*/}
                {/*    {hasAudioAsset && <BoxButton*/}
                {/*        selected={advanceOn === AdvanceSlideOnType.AUDIO}*/}
                {/*        onClick={() => this.handleChangeType(AdvanceSlideOnType.AUDIO)}*/}
                {/*        IconType={MusicNoteIcon}*/}
                {/*        backgroundColor="#eeeeee"*/}
                {/*        selectedBackgroundColor="#dbe9f0"*/}
                {/*        labelText={"after audio"}*/}
                {/*        width={80}*/}
                {/*        height={"58px"}*/}
                {/*    />}*/}
                {/*    {hasVideoOverlay && <BoxButton*/}
                {/*        selected={advanceOn === AdvanceSlideOnType.VIDEO}*/}
                {/*        onClick={() => this.handleChangeType(AdvanceSlideOnType.VIDEO)}*/}
                {/*        IconType={VideoCall}*/}
                {/*        backgroundColor="#eeeeee"*/}
                {/*        selectedBackgroundColor="#dbe9f0"*/}
                {/*        labelText={"after video"}*/}
                {/*        width={80}*/}
                {/*        height={"58px"}*/}
                {/*    />}*/}
                {/*</HorizontalPropertyList>*/}
            </PropertySection>
        </PropertyPanelContainer>);
    }
}

export default PresentationEditorController.withState(AdvanceNextSlidePanel);
