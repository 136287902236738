import React, { Component, useEffect } from "react";
import styled, { css } from "styled-components";
import { DragPreviewImage, useDrag } from "react-dnd";

import { stopPropagation } from "js/core/utilities/stopPropagation";
import { AuthoringElementType, CalloutType, CALLOUTS_MAPPING } from "common/constants";
import { getStaticUrl } from "js/config";
import * as geom from "js/core/utilities/geom";
import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";
import { WithLabel } from "js/Components/WithLabel";
import { StaticImage } from "js/Components/StaticImage";

import { addCalloutToContainer } from "./AddElementDropZone";

const ITEM_WIDTH = 73;
const ITEM_HEIGHT = 65;
const CONTAINER_PADDING = 10;

const GridItem = css`
 > div {
        width: ${ITEM_WIDTH}px;
        height: ${ITEM_HEIGHT}px;
    }
`;

const Container = styled.div`
    overflow: auto;
    display: ${props => props.horizontal ? "flex" : "grid"};
    grid-template-columns: repeat(3, minmax(${ITEM_WIDTH}px, 1fr));
    grid-column-gap: 20px;
    grid-row-gap: 0px;
    padding: ${CONTAINER_PADDING}px;
    gap: 20px;

    ${props => props.horizontal ? null : GridItem};

`;

const AnnotationType = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    text-align: center;
    padding: 5px 0px;
`;

const TypeImage = styled(StaticImage)`
    max-width: ${ITEM_WIDTH}px;
`;

const ItemContainer = styled.div`
    padding: 0px 0px 5px 0px;

    &:hover {
        background: #d6eff9;
    }
`;

export const AddType = ({ label, value, image, currentCanvas, onClose, containerElement }) => {
    const [{ isDragging }, drag, preview] = useDrag(() => ({
        type: "elements",
        item: { label, value, onClose },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId()
        }),
    }));

    useEffect(() => {
        const img = new Image();
        img.src = getStaticUrl(image);
        img.crossOrigin = "anonymous";
        const ctx = document.createElement("canvas").getContext("2d");

        img.onload = () => {
            const { width, height } = img;
            ctx.canvas.width = width > 100 ? 100 : width;
            ctx.canvas.height = width > 100 ? (100 / (width / height)) : height;

            ctx.drawImage(img, 0, 0, ctx.canvas.width, ctx.canvas.height);
            img.src = ctx.canvas.toDataURL();
            preview(img);
        };
    }, []);

    useEffect(() => {
        PresentationEditorController.setShowAddElementDropZone(isDragging);
    }, [isDragging]);

    return (
        <ItemContainer>
            <WithLabel small below center label={label} gap={0}>
                <DragPreviewImage connect={preview} />
                <AnnotationType ref={drag} onMouseDown={stopPropagation()} onMouseUp={() => {
                    const { width, height } = containerElement.getDefaultModel(AuthoringElementType.CALLOUT, { calloutType: value });
                    addCalloutToContainer(containerElement, value, new geom.Point((currentCanvas.CANVAS_WIDTH / 2) - (width / 2), (currentCanvas.CANVAS_HEIGHT / 2) - (height / 2)));
                    onClose() && onClose();
                }}>
                    <TypeImage src={image} />
                </AnnotationType>
            </WithLabel>
        </ItemContainer>
    );
};

class AddCalloutPanel extends Component {
    render() {
        let { containerElement, currentCanvasController, setIsDragging, onClose, horizontal = false, showMore } = this.props;

        if (!containerElement) {
            containerElement = currentCanvasController.canvas.layouter.elements.callouts;
        }

        const allowedNodeTypes = containerElement.getAllowedCalloutTypes();

        // To maintain the order of the node types
        const calloutTypes = [
            CalloutType.BOX,
            CalloutType.CIRCLE,
            CalloutType.DIAMOND,
            CalloutType.CAPSULE,
            CalloutType.TEXT,
            CalloutType.BULLET_TEXT,
            CalloutType.NUMBERED_TEXT,
            CalloutType.CONTENT_AND_TEXT,
            CalloutType.CONTENT,
            // CalloutType.LINE,
            // CalloutType.DASHED_LINE,
            CalloutType.LINE_ARROW
        ].filter(type => allowedNodeTypes.contains(type));

        return (
            <>
                <Container horizontal={horizontal}>
                    {calloutTypes.map(callout => (
                        <AddType
                            {...CALLOUTS_MAPPING[callout]}
                            containerElement={containerElement}
                            currentCanvas={currentCanvasController.canvas}
                            key={callout}
                            setIsDragging={setIsDragging}
                            onClose={onClose}
                        />
                    ))}
                </Container>
            </>
        );
    }
}

export default AddCalloutPanel;

