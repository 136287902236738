import React from "react";

import WorkspaceController, { WorkspaceControllerState } from "js/controllers/WorkspaceController";
import BillingController, { BillingControllerState } from "js/controllers/BillingController";
import { ActionPermissionsObject, WorkspacePlanTier, PermissionActionType } from "common/interfaces";
import FetchingClickShield from "js/react/components/FetchingClickShield";

import { PaneContainer, PaneHeader, PaneContentContainer } from "../Components/Containers";
import { TopSection } from "./Components/TopSection";
import { UsersList } from "./Components/UsersList";

export default WorkspaceController.withInitializedState(BillingController.withState(
    function ManageTeamPane(props: WorkspaceControllerState & BillingControllerState) {
        const { plan } = props;

        if (![WorkspacePlanTier.ENTERPRISE, WorkspacePlanTier.TEAM].includes(plan.tier)) {
            return null;
        }

        // We need the state of the billing controller to be loaded only in the cases when the user has billing access
        const hasBillingAccess = WorkspaceController.actionPermissions[ActionPermissionsObject.BILLING][PermissionActionType.MANAGE];
        const isBillingControllerLoading = hasBillingAccess && !BillingController.initialized;

        return (<PaneContainer>
            <FetchingClickShield visible={isBillingControllerLoading} backgroundColor="#eee" />
            <PaneHeader>Manage Team</PaneHeader>
            <PaneContentContainer>
                <TopSection />
                <UsersList />
            </PaneContentContainer>
        </PaneContainer>);
    }
));
