import React, { useState } from "react";
import styled from "styled-components";

import { DialogActions, DialogTitle } from "@material-ui/core";

import { Button } from "js/Components/Button";
import BillingController, { BillingControllerState } from "js/controllers/BillingController";
import WorkspaceController, { WorkspaceControllerState } from "js/controllers/WorkspaceController";
import getLogger, { LogGroup } from "js/core/logger";
import { BeautifulDialog, DialogContent, ShowErrorDialog, ShowSnackBar } from "js/react/components/Dialogs/BaseDialog";
import FetchingClickShield from "js/react/components/FetchingClickShield";

export const logger = getLogger(LogGroup.BILLING);

const StyledDialogContent = styled(DialogContent)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
`;

export interface RemoveUnusedSeatsDialogProps {
    closeDialog: () => void;
}

export const RemoveUnusedSeatsDialog = WorkspaceController.withInitializedState(BillingController.withInitializedState(React.forwardRef(
    function RemoveUnusedSeatsDialog(props: RemoveUnusedSeatsDialogProps & WorkspaceControllerState & BillingControllerState, ref: React.Ref<any>) {
        const { closeDialog, plan } = props;

        const [removingSeats, setRemovingSeats] = useState(false);

        const unusedSeatCount = BillingController.paidSeatCount - BillingController.usedSeatCount;

        const handleRemoveSeats = async () => {
            setRemovingSeats(true);
            try {
                await BillingController.updateSubscriptionQuantity(BillingController.usedSeatCount);

                ShowSnackBar({ message: `Removed ${unusedSeatCount} ${plan.name} Pro Seat${unusedSeatCount > 1 ? "s" : ""} from your team` });

                closeDialog();
            } catch (err) {
                logger.error(err, "[RemoveUnusedSeatsDialog] Failed to remove seats");

                ShowErrorDialog({
                    title: "Failed to remove seats",
                    message: err.message
                });
            }
            setRemovingSeats(false);
        };

        const handleInviteMember = () => {
        };

        return (<BeautifulDialog closeDialog={closeDialog} ref={ref}>
            <FetchingClickShield visible={removingSeats} backgroundColor="#fff" />
            <DialogTitle>Remove unused {plan.name} Pro Seats</DialogTitle>
            <StyledDialogContent>
                <div>This will remove {unusedSeatCount} unused seat{unusedSeatCount > 1 ? "s" : ""} from you plan. To add a new seat, <a onClick={handleInviteMember}>invite a member</a>.</div>
                <div>This change will be reflected in your next bill.</div>
            </StyledDialogContent>
            <DialogActions>
                <Button onClick={closeDialog} unframed large>
                    Cancel
                </Button>
                <Button blue large onClick={handleRemoveSeats}>
                    Remove Seats
                </Button>
            </DialogActions>
        </BeautifulDialog>);
    }
)));
