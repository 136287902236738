import { OutlinedInput } from "@material-ui/core";
import React, { Component } from "react";
import styled from "styled-components";

import { SlideStatus, SlideStatusProps } from "common/constants";
import { ActionPermissionsObject } from "common/interfaces";
import { Box } from "js/Components/Box";
import { Divider } from "js/Components/Divider";
import { Icon } from "js/Components/Icon";
import { StaticImage } from "js/Components/StaticImage";
import WorkspaceController from "js/controllers/WorkspaceController";
import getLogger, { LogGroup } from "js/core/logger";
import { ds } from "js/core/models/dataService";
import NotificationsService from "js/core/services/notifications";
import { Key } from "js/core/utilities/keys";
import { stopPropagation } from "js/core/utilities/stopPropagation";
import { trackActivity } from "js/core/utilities/utilities";
import { app } from "js/namespaces";
import { UserProfile } from "js/react/components/Avatar";
import { ShowConfirmationDialog, ShowDialogAsync, ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import InviteEditCollaboratorDialog from "js/react/components/Dialogs/InviteEditCollaboratorDialog";
import { Gap } from "js/react/components/Gap";
import { createInviteSuggestionContext } from "js/react/components/InviteInput/InviteContext";
import { InviteSuggestions } from "js/react/components/InviteInput/InviteSuggestions";
import { FlexBox } from "js/react/components/LayoutGrid";

import PresentationEditorController from "../../../editor/PresentationEditor/PresentationEditorController";

const logger = getLogger(LogGroup.EDITOR);

const StyledUserProfileContainer = styled.div`
    display: flex;
    gap: 10px;
    flex-direction: column;
`;

class AssignSlidePane extends Component {
    state = {
        inviteContext: null,
        slideStatus: null,
        assignedUser: null,
        collaborators: [],
        activeCollaborator: [],
        teammates: []
    }

    componentDidMount() {
        this.slideStatusSubscription = ds.getObservables().slideStatus$
            .subscribe(status => this.setState({ slideStatus: status || SlideStatus.NONE }));

        this.assignedToSlideUserSubscription = ds.getObservables().assignedToSlideUser$
            .subscribe(assignedUser => this.setState({ assignedUser }));

        this.collaboratorsAndTeammatesSubscription = ds.getObservables().collaboratorsAndTeammates$
            .subscribe(({ collaborators = [], teammates }) => {
                this.setState({
                    collaborators: collaborators,
                    teammates
                });
            });

        this.collaboratorsSubscription = ds.getObservables().collaborators$
            .subscribe(activeCollaborator => {
                this.setState({
                    activeCollaborator: Object.values(activeCollaborator).filter(({ uid }) => uid !== app.user.id)
                });
            });

        const { presentation } = this.props;
        const prohibitExternalWorkspaceCollaboration = !WorkspaceController.actionPermissions[ActionPermissionsObject.EXTERNAL_WORKSPACES_COLLABORATION].use;

        const inviteContext = createInviteSuggestionContext({
            labelCategoryNew: "Teammates",
            includeFolders: false,
            includeCurrentUser: true,
            presentationId: presentation.id,
            oldFirst: true,
            prohibitExternalWorkspaceCollaboration,
            saveContext: inviteContext => this.setState({ inviteContext }),
        });

        this.setState({ inviteContext });
    }

    componentWillUnmount() {
        this.collaboratorsSubscription.unsubscribe();
        this.slideStatusSubscription.unsubscribe();
        this.assignedToSlideUserSubscription.unsubscribe();
        this.collaboratorsAndTeammatesSubscription.unsubscribe();
        this.state.inviteContext?.destroy();
    }

    handleUnassignUser = () => {
        const { currentSlide } = this.props;

        currentSlide.update({ assignedUser: null, assignedPendingUser: null });
    }

    handleAssignUserInputChange = event => {
        const { inviteContext } = this.state;
        inviteContext.setFilter(event.target.value);
    }

    handleAssignTeammate = user => {
        ShowDialogAsync(InviteEditCollaboratorDialog, {
            title: `Would you like to invite ${user.displayName || user.email} to collaborate on and edit this presentation?`,
            message: `Only collaborators with edit permission can be assigned a slide.`,
            user
        }).then(wasInvited => {
            if (wasInvited) {
                this.assignUserToSlide(user);
            }
        });
    }

    handleAssignUser = item => {
        const { collaborators, teammates } = this.state;

        let user = collaborators.find(x => x.email === item.email);
        if (user) {
            this.handleAssignCollaborator(user);
            return;
        }

        user = teammates.find(x => x.email === item.email);
        if (!user) {
            user = {
                uid: item.uid || item.id,
                email: item.email,
                isPending: true,
            };
        }
        this.handleAssignTeammate(user);
    }

    handleAssignCollaborator = user => {
        if (user.permissionType === "view") {
            ShowDialogAsync(InviteEditCollaboratorDialog, {
                title: `Would you like to give ${user.displayName || user.email} edit permission?`,
                message: `This collaborator currently has view only access. Only collaborators with edit permission can be assigned a slide.`,
                user,
                old_value: user.permissionType
            }).then(wasInvited => {
                if (wasInvited) {
                    this.assignUserToSlide(user);
                }
            });
        } else {
            this.assignUserToSlide(user);
        }
    }

    assignUserToSlide = user => {
        const { currentSlide: slide, presentation } = this.props;

        const assignUser = () => {
            if (user.isPending && slide.get("assignedPendingUser") !== user.email) {
                slide.update({ assignedUser: null, assignedPendingUser: user.email, pendingUserAssignedBy: app.user.id });
                if (slide.isLibrarySlide()) {
                    app.mainView.editorView.handleSaveSharedSlide();
                }
            } else if (slide.get("assignedUser") !== user.uid) {
                slide.update({ assignedUser: user.uid, assignedPendingUser: null, pendingUserAssignedBy: null });
                if (slide.isLibrarySlide()) {
                    app.mainView.editorView.handleSaveSharedSlide();
                }
                // Notifying the assignee
                NotificationsService.notifyOnUserAssignedToSlide(user.uid, presentation.id, slide.id)
                    .catch(err => logger.error(err, "[CollaborationBar] NotificationsService.notifyOnUserAssignedToSlide() failed", { slideId: slide?.id }));
            }
            const props = {
                slide_id: slide.id,
                email: user.email,
                user_id: user.uid || null
            };
            trackActivity("Collab", "Assign", null, null, props, { audit: true });
        };

        if (slide.isLibrarySlide()) {
            ShowConfirmationDialog({
                title: "Assigning user will save this Team Slide, are you sure?",
                message: "Changes will be applied to all presentations that use this slide.",
                cancelButtonLabel: "Cancel",
                acceptCallback: assignUser,
                cancelCallback: () => { }
            });
        } else {
            assignUser();
        }
    }

    setSlideStatus = async status => {
        const { presentation, currentSlide } = this.props;
        if (currentSlide.get("status") === status) {
            return;
        }
        this.state.inviteContext?.destroy();

        // Checking if the slide is a shared team slide
        if (currentSlide.isLibrarySlide()) {
            ShowErrorDialog({ title: "Unable to update slide status", message: "This is a shared team slide and cannot be updated" });
            return;
        }

        // Updating the slide status
        currentSlide.update({ status });

        // Notifying the status change to owners and collaborators
        NotificationsService.notifyOnSlideStatusChanged(presentation.id, currentSlide.id, status)
            .catch(err => logger.error(err, "[CollaborationBar] NotificationsService.notifyOnSlideStatusChanged() failed", { slideId: currentSlide?.id }));

        // Tracking the activity
        const props = {
            presentation_id: presentation.id,
            slide_id: currentSlide.id,
            status
        };
        trackActivity("Collab", "SlideStatus", null, null, props, { audit: true });
    }

    handleAssignUserKeyDown = event => {
        const { inviteContext } = this.state;

        switch (event.keyCode) {
            case Key.BACKSPACE:
                if (!inviteContext.filter) {
                    // Prevent further events
                    event.nativeEvent.stopImmediatePropagation();
                    event.nativeEvent.preventDefault();

                    inviteContext.popItem();
                }
                break;
            case Key.ENTER:
            case Key.TAB:
                // Only select on ENTER/TAB when the suggestions
                //   are open and have available suggestions
                if (inviteContext.selectableSuggestions.length) {
                    // Prevent further events
                    event.nativeEvent.stopImmediatePropagation();
                    event.nativeEvent.preventDefault();

                    const selectedUser = inviteContext.selectPendingSelection();
                    this.handleAssignUser(selectedUser);
                } else if (inviteContext.filter.length) {
                    // Prevent further events
                    event.nativeEvent.stopImmediatePropagation();
                    event.nativeEvent.preventDefault();

                    inviteContext.selectFilterAsEmail();
                }
                break;
            case Key.SPACE:
            case Key.COMMA:
            case Key.SEMICOLON:
            case Key.UP_ARROW:
            case Key.DOWN_ARROW:
                // Prevent further events
                event.nativeEvent.stopImmediatePropagation();
                event.nativeEvent.preventDefault();

                switch (event.keyCode) {
                    case Key.SPACE:
                    case Key.COMMA:
                    case Key.SEMICOLON:
                        inviteContext.selectFilterAsEmail();
                        break;
                    case Key.UP_ARROW:
                        inviteContext.pendingSelectPrev();
                        break;
                    case Key.DOWN_ARROW:
                        inviteContext.pendingSelectNext();
                        break;
                }
                break;
        }
    }

    render() {
        const { slideStatus, assignedUser, inviteContext, isAssignUserInputFocused } = this.state;

        return (
            <>
                <Box padding={15} gap={12}>
                    <span className="label-text">Slide status</span>
                    <Gap size={12} />
                    <FlexBox row between gap={15}>
                        {Object.values(SlideStatus)
                            .filter(status => SlideStatusProps[status])
                            .map(status => (
                                <FlexBox
                                    className="slide-status-option" key={status} column between gap={5} width={34}
                                    onClick={() => this.setSlideStatus(status)}
                                >
                                    <div className={`slide-status-icon${status === slideStatus ? " slide-status-icon-selected" : ""}`}>
                                        <Icon><StaticImage src={`/images/slide-status/${SlideStatusProps[status].icon}.svg`} /></Icon>
                                    </div>
                                    <span className={`slide-status-label${status === slideStatus ? " slide-status-label-selected" : ""}`}>{SlideStatusProps[status].label}</span>
                                </FlexBox>
                            ))}
                    </FlexBox>
                </Box>
                <Divider />
                <Box padding={15} gap={12} className="assigned-user">
                    {assignedUser && <>
                        <FlexBox row between gap={12}>
                            <span className="label-text">Assigned to</span>
                            <Icon className="remove-assigned-user" onClick={this.handleUnassignUser}>close</Icon>
                        </FlexBox>
                        <Gap size={12} />
                        <UserProfile profile={assignedUser} />
                        <Gap size={24} />
                    </>}
                    <OutlinedInput
                        placeholder="Assign slide to..."
                        value={inviteContext?.filter}
                        startAdornment={<Icon blue>search</Icon>}
                        onMouseDown={stopPropagation()}
                        onKeyDown={this.handleAssignUserKeyDown}
                        onFocus={() => this.setState({ isAssignUserInputFocused: true })}
                        onBlur={() => this.setState({ isAssignUserInputFocused: false })}
                        onChange={this.handleAssignUserInputChange}
                    />
                </Box>
                {inviteContext && isAssignUserInputFocused && <>
                    <Divider />
                    <Box padding={8} gap={12}>
                        <InviteSuggestions
                            inviteContext={inviteContext}
                            sourceName={"AssignUser"}
                            showUpgradeCTA={false}
                            onSelect={this.handleAssignUser}
                        />
                    </Box>
                </>}
            </>
        );
    }
}

export default PresentationEditorController.withState(AssignSlidePane);
