import React, { Component } from "react";

import WorkspaceController from "../../../controllers/WorkspaceController";
import AppController from "../../../core/AppController";
import { stopPropagation } from "../../../core/utilities/stopPropagation";
import PresentationEditorController from "../../../editor/PresentationEditor/PresentationEditorController";
import { PropertyPanelContainer } from "../../../EditorComponents/PropertyPanel";
import { PropertyPanelButton } from "../../../EditorComponents/PropertyPanelButton";
import { app } from "../../../namespaces";
import { ShowWarningDialog } from "../../../react/components/Dialogs/BaseDialog";
import { PropertyPanelHeader } from "../../ElementEditor/PropertyPanelHeader";
import { ActionPermissionsObject } from "../../../../common/interfaces";

export const StylePanel = AppController.withState(class StylePanel extends Component {
    handleEditTheme = async () => {
        const { presentation } = this.props;

        const isTeamTheme = presentation.has("sharedThemeId");
        if (isTeamTheme && !WorkspaceController.isOwner && !WorkspaceController.isLibrarian) {
            ShowWarningDialog({
                title: "Sorry, you don’t have permission to edit this Team Theme.",
                message: "Team Themes are shared and only editable by team Owners and Librarians.",
                acceptButtonText: "Got it"
            });
            return;
        }

        if (window.isPPTAddin) {
            const themeUpdated = await AppController.showThemeEditor(presentation);
            if (themeUpdated) {
                await PresentationEditorController.updateTheme();
            }
            return;
        }

        AppController.navigateToThemeEditor(presentation.id);
    }

    handleSwitchTheme = () => {
        const { currentCanvasController } = this.props;

        currentCanvasController.lockSlideForCollaborators(30);
        app.themeManager.showSwitchThemeDialog({
            onClose: () => {
                currentCanvasController.unlockSlideForCollaborators();
            }
        });
    }

    render() {
        const { presentation, isSingleSlideEditor } = this.props;

        const isTeamTheme = presentation.has("sharedThemeId");
        const canEditTheme = isTeamTheme
            ? WorkspaceController.actionPermissions[ActionPermissionsObject.SHARED_RESOURCES].manage
            : true;

        return (
            <PropertyPanelContainer fullHeight>
                <PropertyPanelHeader>Styles</PropertyPanelHeader>
                {!isTeamTheme && !isSingleSlideEditor && (
                    <PropertyPanelButton
                        title="Edit Theme..."
                        icon="palette"
                        description="Edit the current theme for this presentation."
                        onClick={this.handleEditTheme}
                        onMouseDown={stopPropagation()}
                    />
                )}
                {isTeamTheme && !isSingleSlideEditor && (
                    <PropertyPanelButton
                        title="Edit Team Theme..."
                        icon="link"
                        description={canEditTheme ? "Edit team theme." : "Only Team Librarians and Members can edit shared team themes."}
                        iconBackdropColor={!canEditTheme ? "red" : "transparent"}
                        onClick={this.handleEditTheme}
                        onMouseDown={stopPropagation()}
                    />
                )}
                <PropertyPanelButton
                    title="Switch Theme..."
                    icon="cycle"
                    description="Switch this presentation to another theme."
                    onClick={this.handleSwitchTheme}
                    onMouseDown={stopPropagation()}
                />
            </PropertyPanelContainer>
        );
    }
});
