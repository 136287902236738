import type Stripe from "stripe";
import React from "react";
import styled from "styled-components";

import { getStaticUrl } from "js/config";
import { Icon } from "js/Components/Icon";
import { themeColors } from "js/react/sharedStyles";

const Container = styled.div<{ small?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
        height: ${props => props.small ? "26px" : "40px"};
        clip-path: inset(2px 2px 2px 2px);
    }

    .bai-icon {
        font-size: ${props => props.small ? "26px" : "34px"};
    }
`;

export function CreditCardIcon({ card, small }: { card: Stripe.PaymentMethod.Card | Stripe.Charge.PaymentMethodDetails.Card, small?: boolean }) {
    const iconNumber = {
        amex: 22,
        diners: 10,
        discover: 14,
        jcb: 16,
        mastercard: 2,
        visa: 1
    }[card.brand];

    return (<Container small={small}>
        {iconNumber && <img src={getStaticUrl(`/images/card_brand_icons/${iconNumber}.png`)} />}
        {!iconNumber && <Icon color={themeColors.ui_blue}>credit_card</Icon>}
    </Container>);
}
