import { DialogActions, DialogTitle } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

import { IWorkspaceUserInvite } from "common/interfaces";
import { Button } from "js/Components/Button";
import WorkspaceController from "js/controllers/WorkspaceController";
import getLogger, { LogGroup } from "js/core/logger";
import withProgressDialog from "js/core/utilities/withProgressDialog";
import { BeautifulDialog, DialogContent, ShowErrorDialog, ShowSnackBar } from "js/react/components/Dialogs/BaseDialog";
import { themeColors } from "js/react/sharedStyles";

import { Profile } from "./Profile";

const logger = getLogger(LogGroup.TEAMS);

const DialogActionsBetween = styled(DialogActions)`
    &&& {
        justify-content: space-between;
    }
`;

interface ResendInviteDialogProps {
    closeDialog: () => void;
    invite: IWorkspaceUserInvite;
}

export const ResendInviteDialog = React.forwardRef(
    function ResendInviteDialog(props: ResendInviteDialogProps, ref: React.Ref<any>) {
        const { closeDialog, invite } = props;

        const handleResendInvite = () => {
            withProgressDialog({
                title: "Resending invite...",
                message: "Please wait while we resend the invite...",
                action: async () => {
                    try {
                        await WorkspaceController.resendUserInvite({ inviteId: invite.id });

                        ShowSnackBar({ message: `Invitation Resent to ${invite.email}` });
                    } catch (err) {
                        logger.error(err, "[ResendInviteDialog] Failed to resend invite");

                        ShowErrorDialog({
                            title: "Failed to resend invite",
                            message: "Please try again later."
                        });
                    }

                    closeDialog();
                }
            });
        };

        const handleCopyInviteLink = () => {
            withProgressDialog({
                title: "Preparing invite link...",
                message: "Please wait while we prepare the invite link...",
                action: async () => {
                    try {
                        await WorkspaceController.copyUserInviteLinkToClipboard({ invite });

                        ShowSnackBar({ message: "Invitation Copied to Clipboard" });
                    } catch (err) {
                        logger.error(err, "[ResendInviteDialog] Failed to copy invite link");

                        ShowErrorDialog({
                            title: "Failed to copy invite link",
                            message: "Please try again later."
                        });
                    }

                    closeDialog();
                }
            });
        };

        return (
            <BeautifulDialog closeDialog={closeDialog} ref={ref}>
                <DialogTitle>Resend invitation?</DialogTitle>
                <DialogContent>
                    <Profile email={invite.email} />
                </DialogContent>
                <DialogActionsBetween>
                    <Button unframed large onClick={handleCopyInviteLink}>Copy Invite Link</Button>
                    <Button unframed large color={themeColors.ui_blue} onClick={handleResendInvite}>Send Email</Button>
                </DialogActionsBetween>
            </BeautifulDialog >
        );
    }
);
