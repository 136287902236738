import { DialogActions, DialogTitle, FormControlLabel, MenuItem, Radio, RadioGroup, Select } from "@material-ui/core";
import React, { useState } from "react";
import styled from "styled-components";

import { IWorkspaceUserExtended } from "common/interfaces";
import { Button } from "js/Components/Button";
import { Divider } from "js/Components/Divider";
import WorkspaceController, { WorkspaceControllerState } from "js/controllers/WorkspaceController";
import getLogger, { LogGroup } from "js/core/logger";
import withProgressDialog from "js/core/utilities/withProgressDialog";
import { BeautifulDialog, DialogContent, ShowConfirmationDialog, ShowErrorDialog, ShowSnackBar } from "js/react/components/Dialogs/BaseDialog";
import { themeColors } from "js/react/sharedStyles";

import { Profile } from "./Profile";

const logger = getLogger(LogGroup.TEAMS);

interface RemoveUserFromWorkspaceDialogProps {
    closeDialog: () => void;
    user: IWorkspaceUserExtended;
}

const ContentContainer = styled(DialogContent)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
`;

const BoldText = styled.div`
    font-weight: 600;
    font-size: 14px;
    color: #333333;
`;

const UserSelectContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-left: 28px;

    >div.MuiInputBase-root {
        width: 350px;
    }
`;

export const RemoveUserFromWorkspaceDialog = WorkspaceController.withInitializedState(React.forwardRef(
    function RemoveUserFromWorkspaceDialog(props: RemoveUserFromWorkspaceDialogProps & WorkspaceControllerState, ref: React.Ref<any>) {
        const { closeDialog, user, users } = props;

        const targetUsers = users.filter(u => u.uid !== user.uid).sort((a, b) => a.email.localeCompare(b.email));

        const [transferOption, setTransferOption] = useState<"self" | "other" | "revoke">("self");
        const [targetUser, setTargetUser] = useState<IWorkspaceUserExtended | null>(targetUsers[0]);

        const handleRemoveUser = async () => {
            if (transferOption === "revoke") {
                const accepted = await ShowConfirmationDialog({
                    title: "Are you sure you want to delete this user's presentations?",
                    message: `Any presentations this user owns in this workspace will be deleted and any collaboration permissions revoked. This action cannot be undone.`,
                    okButtonLabel: "Delete Presentations",
                });
                if (!accepted) {
                    return;
                }
            }

            withProgressDialog({
                title: "Removing member...",
                message: "Please wait while we remove the member...",
                action: async () => {
                    try {
                        await WorkspaceController.deleteWorkspaceUser({ uid: user.uid, transferOption, targetUid: transferOption === "other" ? targetUser?.uid : undefined });

                        ShowSnackBar({
                            message: "Member removed successfully",
                            type: "success"
                        });
                        closeDialog();
                    } catch (error) {
                        logger.error(error, "Failed to remove user from workspace");

                        ShowErrorDialog({
                            title: "Failed to remove member",
                            message: "Please try again. If the issue persists, please reach out to support@beautiful.ai."
                        });
                    }
                }
            });
        };

        const handleTargetUserSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
            setTargetUser(targetUsers.find(user => user.email === event.target.value) ?? null);
        };

        const handleTransferOptionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
            setTransferOption(event.target.value as "self" | "other" | "revoke");
        };

        return (
            <BeautifulDialog closeDialog={closeDialog} ref={ref}>
                <DialogTitle>Remove member?</DialogTitle>
                <ContentContainer>
                    <div>This user will no longer have access to shared Team Resources.</div>
                    <Profile email={user.email} displayName={user.displayName} />
                    <Divider />
                    <BoldText>What would you like to do with this user's presentations?</BoldText>
                    <RadioGroup
                        value={transferOption}
                        onChange={handleTransferOptionChange}
                    >
                        <FormControlLabel
                            value="self"
                            control={<Radio color="primary" />}
                            label={"Transfer presentations to their personal workspace"}
                        />
                        <FormControlLabel
                            value="other"
                            control={<Radio color="primary" />}
                            label={"Transfer presentations to: "}
                        />
                        <UserSelectContainer>
                            <Select
                                SelectDisplayProps={{ style: { textTransform: "unset" } }}
                                variant="outlined"
                                disableUnderline
                                onChange={handleTargetUserSelectChange}
                                value={targetUser.email}
                                renderValue={value => value}
                            >
                                {targetUsers.map((user, idx) => (
                                    <MenuItem key={idx} value={user.email}>
                                        {user.email}
                                    </MenuItem>
                                ))}
                            </Select>
                        </UserSelectContainer>
                        <FormControlLabel
                            value="revoke"
                            control={<Radio color="primary" />}
                            label="Delete presentations and revoke collaboration permissions"
                        />
                    </RadioGroup>
                </ContentContainer>
                <DialogActions>
                    <Button unframed large onClick={closeDialog}>Nevermind</Button>
                    <Button unframed large color={themeColors.ui_blue} onClick={handleRemoveUser}>Remove Member</Button>
                </DialogActions>
            </BeautifulDialog >
        );
    }
));
