import { DialogTitle, Tooltip } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { IWorkspace } from "common/interfaces";
import getObjectHash from "common/utils/getObjectHash";
import WorkspaceController, { WorkspaceControllerState } from "js/controllers/WorkspaceController";
import Api from "js/core/api";
import AppController, { AppControllerState } from "js/core/AppController";
import getLogger, { LogGroup } from "js/core/logger";
import { ClipboardType, clipboardWrite } from "js/core/utilities/clipboard";
import { BeautifulDialog, DialogContent, ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";

import { v4 as uuid } from "uuid";

import {
    Icon,
    InputAndButtonWithLabel,
    InputWithLabel,
    Section,
    SectionsContainer,
    BlueButton,
    OrangeButton,
    Text,
    TitleText,
    ToggleSwitchWithLabel
} from "./DialogComponents";

const logger = getLogger(LogGroup.SSO);

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 30px;
`;

const DomainSection = AppController.withState(WorkspaceController.withInitializedState(function NonVerifiedDomainSection(props: AppControllerState & WorkspaceControllerState) {
    const { workspace } = props;

    const [isVerificationKeyCopied, setIsVerificationKeyCopied] = useState(false);
    const [fetching, setFetching] = useState(false);

    const handleCopyVerificationKey = () => {
        setIsVerificationKeyCopied(true);
        clipboardWrite({
            [ClipboardType.TEXT]: workspace.domain.verificationKey,
        });
        setTimeout(() => {
            setIsVerificationKeyCopied(false);
        }, 1000);
    };

    const handleVerifyDomain = async () => {
        setFetching(true);
        try {
            await WorkspaceController.verifyDomain();
        } catch (err) {
            logger.error(err, "[DomainSection] WorkspaceController.verifyDomain() failed");

            ShowErrorDialog({
                error: "Error",
                message: err.status === 400 ? err.message : "Failed to verify domain"
            });
        } finally {
            setFetching(false);
        }
    };

    if (workspace.domain.verified) {
        return (
            <Section>
                <TitleText>Domain verification</TitleText>
                <Text>
                    <b>Domain:</b> {workspace.domain.domain} is verified <Icon blue>verified</Icon>
                </Text>
            </Section>
        );
    }

    return (
        <Section>
            <TitleText>Domain verification</TitleText>
            <Text>
                <b>Domain:</b> {workspace.domain.domain}
            </Text>
            <Text muted>
                We need to verify ownership of your domain to ensure you can administer accounts
                for {workspace.domain.domain}. This is required to enable Single Sign On.
            </Text>
            <Text muted>
                Please add the following TXT record to your domain's DNS records.
            </Text>
            <Text>
                <b>Name/Host/Alias:</b> Please leave blank.
            </Text>
            <Text>
                <b>Domain verification key:</b> {workspace.domain.verificationKey}&nbsp;
                <Tooltip title="Add a TXT record containing this value to your DNS configuration to get your domain verified. Click to copy to clipboard." placement="bottom-start">
                    <Icon onClick={handleCopyVerificationKey} clickable blue>
                        {isVerificationKeyCopied ? "check" : "file_copy"}
                    </Icon>
                </Tooltip>
            </Text>
            <Text warning>
                <Icon>error</Icon>
                Domain is not verified, last attempt was {workspace.domain.verifiedAt ? moment(workspace.domain.verifiedAt).fromNow() : "never"}
            </Text>
            {workspace.domain.verificationError && <Text warning>{workspace.domain.verificationError}</Text>}
            <BlueButton
                disabled={fetching}
                onClick={handleVerifyDomain}
                data-test-id="verify-domain-button"
            >
                Verify domain now
            </BlueButton>
        </Section >
    );
}));

const SAMLSection = AppController.withState(WorkspaceController.withInitializedState(function SAMLSection(props: AppControllerState & WorkspaceControllerState) {
    const { workspace } = props;

    const [metadataUrl, setMetadataUrl] = useState("");
    const [fetching, setFetching] = useState(false);
    const [ssoConfig, setSsoConfig] = useState<IWorkspace["sso"]>(workspace.sso);

    useEffect(() => {
        setSsoConfig(workspace.sso);
    }, [getObjectHash(workspace)]);

    const isSsoConfigDirty = getObjectHash(ssoConfig) !== getObjectHash(workspace.sso);

    const handleParseMetadata = async () => {
        setFetching(true);

        try {
            await WorkspaceController.parseSamlMetadata({ metadataUrl });
            setMetadataUrl("");
        } catch (err) {
            logger.error(err, "[SAMLSection] WorkspaceController.parseSamlMetadata() failed");

            ShowErrorDialog({
                error: "Error",
                message: err.status === 400 ? err.message : "Failed to parse metadata"
            });
        } finally {
            setFetching(false);
        }
    };

    const handleSaveSamlConfig = async () => {
        setFetching(true);

        try {
            await WorkspaceController.updateWorkspace({ sso: ssoConfig });
        } catch (err) {
            logger.error(err, "[SAMLSection] WorkspaceController.updateWorkspace() failed");

            ShowErrorDialog({
                error: "Error",
                message: err.status === 400 ? err.message : "Failed to save SAML configuration"
            });
        } finally {
            setFetching(false);
        }
    };

    const handleDisableSso = async () => {
        setFetching(true);

        try {
            await WorkspaceController.updateWorkspace({ sso: { samlConfig: { certs: null, entryPoint: null, idpIssuer: null } } });
        } catch (err) {
            logger.error(err, "[SAMLSection] WorkspaceController.updateWorkspace() failed");

            ShowErrorDialog({
                error: "Error",
                message: err.status === 400 ? err.message : "Failed to disable SSO"
            });
        } finally {
            setFetching(false);
        }
    };

    if (!workspace.domain.verified) {
        return (
            <Section>
                <TitleText>SAML SSO</TitleText>
                <Text warning>Please verify your domain before setting up SSO.</Text>
            </Section>
        );
    }

    return (
        <Section>
            <TitleText>SAML SSO</TitleText>
            {workspace.sso.enabled && <Text data-test-id="sso-enabled-text"><Icon green>check</Icon> SSO is enabled for your workspace.</Text>}
            {!workspace.sso.enabled && <Text data-test-id="sso-disabled-text"><Icon orange>error</Icon> SSO is disabled for your workspace, enter missing configuration below.</Text>}
            <InputAndButtonWithLabel
                label="IDP Metadata URL"
                tooltip="Entering this url will automatically fill out the next two fields. If you don’t have this you can skip this and manually enter the information in the following fields."
                value={metadataUrl}
                onChange={e => setMetadataUrl(e.target.value)}
                onClick={handleParseMetadata}
                buttonText="Parse Metadata"
                inputDisabled={fetching}
                buttonDisabled={!metadataUrl || fetching}
                data-test-id="idp-metadata-url"
            />
            <InputWithLabel
                label="SAML SSO Endpoint"
                tooltip="This is the URL we will redirect your users when they try to login in via Beautiful.ai instead of your Identity Provider."
                value={ssoConfig.samlConfig?.entryPoint}
                onChange={e => setSsoConfig({ ...ssoConfig, samlConfig: { ...ssoConfig.samlConfig, entryPoint: e.target.value } })}
                disabled={fetching}
            />
            <InputWithLabel
                label="Issuer URL"
                tooltip="This is the unique identifier of your Identity Provider. We use this to validate the SAML assertions we receive are issued from your identity provider."
                value={ssoConfig.samlConfig?.idpIssuer}
                onChange={e => setSsoConfig({ ...ssoConfig, samlConfig: { ...ssoConfig.samlConfig, idpIssuer: e.target.value } })}
                disabled={fetching}
            />
            <InputWithLabel
                label="Certificate"
                tooltip="This is the public key that your IdP will send with every SAML Response. We will use this to ensure the Response we received is from your IdP (matches this X509 Certificate and that the assertion has not been tampered)."
                value={ssoConfig.samlConfig?.certs?.join("\n")}
                onChange={() => { }}
                disabled={true}
            />
            <ToggleSwitchWithLabel
                label="Allow IDP initiated flow"
                tooltip="Enabling IdP initiated access will allow users to login into Beautiful.ai via your identity provider's application portal. Disabling access will only allow SP (Service Provider) Initiated access (i.e. users will have to start their authentication process at beautiful.ai/login)."
                value={!!ssoConfig.allowIdpInitiated}
                onChange={allowIdpInitiated => setSsoConfig({ ...ssoConfig, allowIdpInitiated })}
                disabled={fetching}
            />
            <ToggleSwitchWithLabel
                label="Enforce strict SSO"
                tooltip="With Strict SSO enabled, users will be required to login with SSO. With Strict SSO disabled, users will be able to login with both SSO and email/password. Please note: If there are users (including you) from your organization who have been using beautiful.ai prior to SSO, they will be locked out if they don’t have access provisioned in your identity provider’s platform. We recommend keeping  the Strict SSO option disabled first and testing SSO functionality before enabling Strict SSO."
                value={!!ssoConfig.strict}
                onChange={strict => setSsoConfig({ ...ssoConfig, strict })}
                disabled={fetching}
            />
            <ButtonsContainer>
                {workspace.sso.enabled &&
                    <OrangeButton
                        onClick={handleDisableSso}
                        data-test-id="disable-sso-button"
                    >
                        Disable SSO
                    </OrangeButton>
                }
                <BlueButton
                    disabled={!isSsoConfigDirty || fetching}
                    onClick={handleSaveSamlConfig}
                >
                    Save changes
                </BlueButton>
            </ButtonsContainer>
        </Section>
    );
}));

const ProvisioningSection = AppController.withState(WorkspaceController.withInitializedState(function ProvisioningSection(props: AppControllerState & WorkspaceControllerState) {
    const { workspace } = props;
    const [isEnterprise, setIsEnterprise] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [userProvisioning, setUserProvisioning] = useState<IWorkspace["sso"]["userProvisioning"]>({
        ...workspace.sso.userProvisioning,
        scimToken: workspace.sso.userProvisioning?.scimToken ?? uuid(),
    });

    useEffect(() => {
        setUserProvisioning({
            ...workspace.sso.userProvisioning,
            scimToken: workspace.sso.userProvisioning?.scimToken ?? uuid(),
        });
    }, [getObjectHash(workspace)]);

    useEffect(() => {
        const fetchEnterpriseStatus = async () => {
            try {
                const isEnterprise = await Api.orgIsEnterprise.get({ orgId: workspace.id });
                setIsEnterprise(isEnterprise);
            } catch (err) {
                logger.error(err, "[ProvisioningSection] Failed to fetch enterprise status");
            }
        };

        fetchEnterpriseStatus();
    }, [workspace.id]);

    const isUserProvisioningDirty = getObjectHash(userProvisioning) !== getObjectHash(workspace.sso.userProvisioning);

    const handleSaveUserProvisioning = async () => {
        setFetching(true);

        try {
            await WorkspaceController.updateWorkspace({ sso: { userProvisioning } });
        } catch (err) {
            logger.error(err, "[ProvisioningSection] WorkspaceController.updateWorkspace() failed");
        } finally {
            setFetching(false);
        }
    };

    if (!workspace.sso.enabled) {
        return (
            <Section>
                <TitleText>User provisioning</TitleText>
                <Text warning>Please enable SSO before setting up user provisioning.</Text>
            </Section>
        );
    }

    return (
        <Section>
            <TitleText>User provisioning</TitleText>
            <ToggleSwitchWithLabel
                label="JIT provisioning"
                tooltip="When enabled, new users will be automatically provisioned when they sign in via your identity provider."
                value={userProvisioning.allowJit}
                onChange={allowJit => setUserProvisioning({ ...userProvisioning, allowJit })}
                disabled={fetching}
                data-test-id="jit-provisioning-toggle"
            />
            {(isEnterprise || userProvisioning.allowScim) && (
                <>
                    <ToggleSwitchWithLabel
                        label="SCIM provisioning"
                        tooltip="When enabled, your identity provider will be able to push and update users in your workspace."
                        value={userProvisioning.allowScim}
                        onChange={allowScim => setUserProvisioning({ ...userProvisioning, allowScim })}
                        disabled={fetching}
                        data-test-id="scim-provisioning-toggle"
                    />
                    <InputWithLabel
                        label="SCIM Token"
                        tooltip="This is the token that your identity provider will use to authenticate with Beautiful.ai when pushing and updating users."
                        value={userProvisioning.scimToken}
                        onChange={e => setUserProvisioning({ ...userProvisioning, scimToken: e.target.value })}
                        disabled={fetching}
                        data-test-id="scim-token"
                    />
                </>
            )}
            <BlueButton
                disabled={!isUserProvisioningDirty || fetching}
                onClick={handleSaveUserProvisioning}
                data-test-id="save-user-provisioning-button"
            >
                Save changes
            </BlueButton>
        </Section>
    );
}));

export function SSODialog({ closeDialog }: { closeDialog: () => void; }) {
    return (
        <BeautifulDialog closeDialog={closeDialog} closeButton>
            <DialogTitle>Single Sign-On</DialogTitle>
            <DialogContent>
                <SectionsContainer>
                    <DomainSection />
                    <SAMLSection />
                    <ProvisioningSection />
                </SectionsContainer>
            </DialogContent>
        </BeautifulDialog>
    );
}
