import styled from "styled-components";

export const BlockContainer = styled.div`
    color: #333;
    padding: 20px 20px 20px 20px;
    position: relative;
    font-size: 18px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
`;

export const BlockHeader = styled.div`
    width: 100%;
    color: #222;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .05em;
`;

export const BlockContentContainer = styled.div<{ left?: boolean, top?: boolean, dense?: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: ${props => props.left ? "flex-start" : "center"};
    justify-content: ${props => props.top ? "flex-start" : "center"};
    height: 100%;
    gap: ${props => props.dense ? "10px" : "20px"};
`;

export const BlockButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
`;
