import Cookies from "js-cookie";

import { auth } from "js/firebase/auth";
import { openWindowAndWaitForItToBeClosed } from "js/core/utilities/selfClosingWindow";

export class ZoomAuthError extends Error { }
export class ZoomAuthFlowWasInterruptedError extends ZoomAuthError { }

/**
 * Grants our app offline access for the given scope, executes the redirect ouath flow in a separate window
 * and saves the tokens
 */
export async function grantOfflineAccess() {
    const userIdToken = await auth().currentUser.getIdToken();
    Cookies.set("user_id_token", userIdToken, { sameSite: "strict", expires: new Date(Date.now() + 60 * 1000) });

    const url = `${window.location.origin}/zoom/auth` +
        `?continueUrl=${encodeURIComponent("/close")}` +
        "&requestOfflineAccess=true";

    await openWindowAndWaitForItToBeClosed(url);
}
