import React, { useState } from "react";
import styled from "styled-components";

import { DialogActions, DialogTitle } from "@material-ui/core";

import { Button } from "js/Components/Button";
import { IconButton } from "js/Components/IconButton";
import { NumericStepper } from "js/Components/NumericStepper";
import { WithLabel } from "js/Components/WithLabel";
import BillingController, { BillingControllerState } from "js/controllers/BillingController";
import WorkspaceController, { WorkspaceControllerState } from "js/controllers/WorkspaceController";
import getLogger, { LogGroup } from "js/core/logger";
import { BeautifulDialog, DialogContent, ShowErrorDialog, ShowSnackBar } from "js/react/components/Dialogs/BaseDialog";
import FetchingClickShield from "js/react/components/FetchingClickShield";
import { WorkspacePlanTier } from "common/interfaces";

import { AdditionalSeatsInvoiceInfo } from "./AdditionalSeatsInvoiceInfo";

export const logger = getLogger(LogGroup.BILLING);

const StyledDialogContent = styled(DialogContent)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;

    a {
        color: #11A9E2;
        text-decoration: none;
        cursor: pointer;
    }
`;

const StepperContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
`;

export interface AddSeatsDialogProps {
    closeDialog: () => void;
}

export const AddSeatsDialog = WorkspaceController.withInitializedState(BillingController.withInitializedState(React.forwardRef(
    function AddSeatsDialog(props: AddSeatsDialogProps & WorkspaceControllerState & BillingControllerState, ref: React.Ref<any>) {
        const { closeDialog, plan } = props;

        if (!BillingController.canChangeSubscriptionQuantity) {
            return (<BeautifulDialog closeDialog={closeDialog} ref={ref}>
                <DialogTitle>Add {plan.name} Pro Seats</DialogTitle>
                <StyledDialogContent>
                    <div>Please <a href={`mailto:${plan.tier === WorkspacePlanTier.ENTERPRISE ? "accounts@beautiful.ai" : "support@beautiful.ai"}`}>contact us</a> to add more seats.</div>
                </StyledDialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} unframed large>
                        Close
                    </Button>
                </DialogActions>
            </BeautifulDialog>);
        }

        const [quantity, setQuantity] = useState(1);
        const [addingSeats, setAddingSeats] = useState(false);

        const totalQuantity = BillingController.paidSeatCount + quantity;

        const handleAddSeats = async () => {
            setAddingSeats(true);
            try {
                await BillingController.updateSubscriptionQuantity(totalQuantity);

                ShowSnackBar({ message: `Added ${quantity} ${plan.name} Pro Seat${quantity > 1 ? "s" : ""} to your team` });

                closeDialog();
            } catch (err) {
                logger.error(err, "[AddSeatsDialog] Failed to add seats");

                ShowErrorDialog({
                    title: "Failed to add seats",
                    message: err.message
                });
            }
            setAddingSeats(false);
        };

        return (<BeautifulDialog closeDialog={closeDialog} ref={ref}>
            <FetchingClickShield visible={addingSeats} backgroundColor="#fff" />
            <DialogTitle>Add {plan.name} Pro Seats</DialogTitle>
            <StyledDialogContent>
                <div>You are currently using {BillingController.usedSeatCount} of {BillingController.paidSeatCount} Pro seats purchased.</div>
                {/* @ts-ignore */}
                <WithLabel label="How many seats would you like to add?" top large>
                    <StepperContainer>
                        <IconButton icon="remove" disabled={quantity <= 1} dimmed={quantity <= 1} onClick={() => setQuantity(quantity - 1)} />
                        <NumericStepper
                            value={quantity}
                            onChange={setQuantity}
                            min={1} max={1000}
                        />
                        <IconButton icon="add" onClick={() => setQuantity(quantity + 1)} />
                    </StepperContainer>
                </WithLabel>
                {!addingSeats && <AdditionalSeatsInvoiceInfo additionalSeatCount={quantity} />}
            </StyledDialogContent>
            <DialogActions>
                <Button onClick={closeDialog} unframed large>
                    Cancel
                </Button>
                <Button blue large onClick={handleAddSeats}>
                    Add Seats
                </Button>
            </DialogActions>
        </BeautifulDialog>);
    }
)));
