import { ThemeProvider } from "@material-ui/core/styles";
import React, { Component, useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Transition } from "react-transition-group";
import styled from "styled-components";

import { presentations as presentationsApi } from "apis/callables";
import { MetricName, PresentationActivityType } from "common/interfaces";
import { Icon } from "js/Components/Icon";
import { isDevelopment, isPPTAddin } from "js/config";
import PresentationLibraryController from "js/controllers/PresentationLibraryController";
import AppController from "js/core/AppController";
import getLogger, { LogGroup } from "js/core/logger";
import { PresentationNotFoundError, PresentationPermissionDeniedError, getPresentation } from "js/core/models/presentation";
import { getExperiments } from "js/core/services/experiments";
import * as geom from "js/core/utilities/geom";
import { Key } from "js/core/utilities/keys";
import { trackActivity } from "js/core/utilities/utilities";
import { EditorPropertyPanel } from "js/editor/ElementEditor/EditorPropertyPanel";
import { app } from "js/namespaces";
import { ShowDialog, ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import { AbsoluteBox, FlexBox } from "js/react/components/LayoutGrid";
import Spinner from "js/react/components/Spinner";
import UndoRedo from "js/react/components/UndoRedo";
import { dialogTheme } from "js/react/materialThemeOverrides";
import { EditorCommentsPane } from "js/react/views/CommentsPane/EditorCommentsPane";
import PresentationSettingsContainer from "js/react/views/PresentationSettings/PresentationSettingsContainer";
import RequestAccessDialog, { RequestAccessDialogContextType } from "js/react/views/RequestAccessDialog/RequestAccessDialog";
import { $, _ } from "js/vendor";

import { Divider } from "../../Components/Divider";
import { Gap5 } from "../../react/components/Gap";
import { FailedToRenderCanvasError } from "./CanvasController";
import { ClipboardController } from "./ClipboardController";
import { AddElementDropZone } from "./Components/AddElementDropZone";
import { CanvasWrapper } from "./Components/CanvasWrapper";
import { BottomPanel, ElementPanelContainer, LEFT_PANEL_WIDTH, LeftPanel, RIGHT_PANEL_WIDTH, RightPanel, SIDE_MENU_BAR_WIDTH } from "./Components/EditorPanels";
import PresentationMenuBar, { MenuBarIconButton } from "./Components/PresentationMenuBar";
import ShortcutPanel from "./Components/ShortCutPanel";
import SlideActions from "./Components/SlideActions";
import SlideGridWrapper from "./Components/SlideGridWrapper";
import SpeakerNotes from "./Components/SpeakerNotes";
import PresentationEditorController, { PanelType } from "./PresentationEditorController";
import SelectionLayer from "./SelectionLayer";
import { TabKeyController } from "./TabKeyController";

const logger = getLogger(LogGroup.EDITOR);

const SECONDARY_CANVAS_SCALE = 0.5;
const SECONDARY_SLIDE_OPACITY = 0.2;

const SLIDE_TRANSITION_DURATION_MS = 350;
const TRANSITION = `${SLIDE_TRANSITION_DURATION_MS}ms ease`;

const CONTROLS_HEIGHT = 70;

const SELECTION_LAYER_TRANSITION_DURATION_MS = 300;

const Container = styled.div`
    width: 100%;
    height: 100%;
    background: #4b4e55;
    pointer-events: auto;
    display: flex;
    flex-direction: column;
`;

const InnerContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;

const EditorContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const CanvasContainer = styled.div`
    width: 100%;
    height: 100%;
    pointer-events: auto;
    position: absolute;
    margin-top: 40px;
`;

const RightCanvasControls = styled.div`
    position: absolute;
    width: 100px;
    height: ${props => props.canvasBounds.height}px;
    transform: ${props => `translateX(${props.canvasBounds.right}px) translateY(${props.canvasBounds.top}px)}`};
    opacity: ${props => props.visible ? 1 : 0};
    transition: ${props => props.transition ? TRANSITION : "none"};
    margin-top: 40px;

    .MuiIcon-root {
        color: white;
    }
`;

const LibrarySlideWarning = styled.div`
    font-size: 14px;
    font-weight: 600;
    color: white;
    background: orangered;
    padding: 7px 16px;
    border-radius: 10px;
    display: flex;
    align-items: center;

    .bai-icon {
        color: white;
        margin-right: 5px;
    }
`;

const SlideGridContainer = styled.div`
    background: #4b4e55;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 100;
    transition: opacity 500ms;
    opacity: ${({ state }) => (["entering", "entered"].includes(state) ? 1 : 0)};
`;

const PositionedCanvas = styled.div.attrs(({ layout, showTransition }) => ({
    style: {
        transform: `translateX(${layout.x}px) translateY(${layout.y}px) scale(${layout.scale})`,
        transition: showTransition ? `opacity 350ms, transform ${TRANSITION}` : "none"
    }
}))`
    position: absolute;
    transform-origin: 0 0;
    pointer-events: auto;
`;

const SelectionLayerContainer = styled.div.attrs(({ canvasBounds, visible }) => ({
    style: {
        opacity: visible ? 1 : 0,
        width: canvasBounds.width,
        height: canvasBounds.height,
        top: canvasBounds.top,
        left: canvasBounds.left,
        transition: `opacity ${visible ? SELECTION_LAYER_TRANSITION_DURATION_MS : 0}ms`
    }
}))`
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 9;
`;

const SideMenuBar = styled.div`
    position: absolute;
    width: ${SIDE_MENU_BAR_WIDTH}px;
    height: 100%;
    background: #222;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    flex-shrink: 0;
    z-index: 100;
`;

const SideMenuBarButton = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
    cursor: pointer;

    label {
        font-size: 9px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: ${props => props.selected ? "#51c6fa" : "#ddd"};
        margin-top: 5px;
    }

    .bai-icon {
        font-size: 22px;
        color: ${props => props.selected ? "#51c6fa" : "white"};
        //font-variation-settings: 'FILL' 1;
    }

    &:hover {
        background: #333;
    }
`;

const PresentationEditor = AppController.withState(
    PresentationEditorController.withState(
        class PresentationEditor extends Component {
            constructor() {
                super();

                this.canvasContainerRef = React.createRef();
                this.editorContainerRef = React.createRef();

                this.handleCurrentSlideChangePromiseChain = Promise.resolve();

                this.canvasWrappers = {};

                this.state = {
                    canvasWidth: 1280,
                    canvasHeight: 720,
                    slideLayoutProps: [],
                    canvasPadding: 140,
                    isReady: false,
                    availableBounds: new geom.Rect(0, 0, 0, 0),
                    primaryCanvasBounds: new geom.Rect(0, 0, 0, 0),
                    isTransitioningBottomPanel: false,
                    isResizingBottomPanel: false,
                    isTransitioningRightPanel: false,
                    bottomPanelHeight: 0
                };

                this.throttledWheelEvent = _.throttle(
                    event => {
                        const { showSlideGrid, showEditorPopup } = this.props;

                        if (app.dialogManager.openDialogs.length || PresentationEditorController.getPopupOpenState()) return;
                        if (showSlideGrid || showEditorPopup) return;

                        let direction = 0;
                        if (event.originalEvent.deltaY < 0) {
                            PresentationEditorController.goPrevSlide();
                            direction = -1;
                        } else if (event.originalEvent.deltaY > 0) {
                            PresentationEditorController.goNextSlide();
                        }
                    },
                    250,
                    { leading: true, trailing: false }
                );

                this.filteredWheelEvent = event => {
                    // Magic Mouse generates a lot of low value deltas at even the smallest
                    //   gesture. MX Master mouse's smallest delta is 4.000244140625. So we
                    //   skip anything lower than 4 to verify intent to scroll.
                    const delta = Math.abs(event.originalEvent.deltaY);
                    if (delta < 4) {
                        return;
                    }

                    this.throttledWheelEvent(event);
                };
            }

            async componentDidMount() {
                logger.info("[PresentationEditor] mounted");

                const { onLoaded, presentation } = this.props;

                // Event handlers
                $(window).on("keydown.editor", this.handleKeyDown);
                $(window).on("resize.editor", this.handleResize);
                $(window).on("paste.editor", ClipboardController.onPaste);
                $(window).on("contextmenu.editor", this.handleContextMenu);

                $(window).on("wheel.editor", this.filteredWheelEvent);

                await this.setStateAsync({ isReady: true });

                await this.calculateSlideLayout(false);

                this.recordOpenCloseActivity("open");

                const {
                    template_recommendations: { enabled: hasTemplateRecommendations }
                } = await getExperiments(["template_recommendations"]);
                const props = {
                    source_presentation_id: presentation.get("sourcePresentationId") || "",
                    source_presentation_name: presentation.get("sourcePresentationName") || "",
                    is_from_recommended_template: presentation.get("metadata")?.isFromRecommendedTemplate ?? false,
                    object: "library_item",
                    object_label: "",
                    action: "clicked",
                    experiment_id: "5FC18A79E182FE7C764425D4F852F501",
                    experiment_group_assignment: hasTemplateRecommendations ? "variant-a" : "control"
                };

                trackActivity("Presentation", "Open", presentation.id, null, props, { audit: true });

                if (onLoaded) {
                    onLoaded();
                }
            }

            componentWillUnmount() {
                logger.info("[PresentationEditor] unmounting");

                this.recordOpenCloseActivity("close");

                $(window).off(".editor");
            }

            componentDidUpdate(prevProps, prevState, snapshot) {
                const { currentSlide, showPropertyPanel, selectedPropertyPanelTabProps } = this.props;
                const { bottomPanelHeight, isTransitioningBottomPanel, isTransitioningRightPanel } = this.state;

                if (prevProps.currentSlide !== currentSlide) {
                    this.handleCurrentSlideChange();
                    return;
                }

                if (prevProps.slides !== this.props.slides) {
                    this.calculateSlideLayout(false);
                    return;
                }

                if (
                    (isTransitioningBottomPanel && isTransitioningBottomPanel !== prevState.isTransitioningBottomPanel) ||
                    (isTransitioningRightPanel && isTransitioningRightPanel !== prevState.isTransitioningRightPanel) ||
                    (showPropertyPanel !== prevProps.showPropertyPanel) ||
                    (selectedPropertyPanelTabProps?.width !== prevProps.selectedPropertyPanelTabProps?.width)
                ) {
                    this.calculateSlideLayout(true);
                } else if (bottomPanelHeight !== prevState.bottomPanelHeight) {
                    this.calculateSlideLayout(false);
                }

                // enable to turn on selection opening closed edit panel - disabled for now
                // if (this.props.currentSelectionLayerController?.selectedElements.length && !showPropertyPanel) {
                //     PresentationEditorController.setSelectedPropertyPanelTab("element");
                // }
            }

            handleCurrentSlideChange() {
                // Keeping the current slide id to keep track of its changes
                const currentSlideId = this.props.currentSlide.id;

                // Keep track of the current slide change request for metrics
                this.currentSlideChangeRequest = {
                    slideId: currentSlideId,
                    timestamp: Date.now()
                };

                return new Promise((resolve, reject) => {
                    this.handleCurrentSlideChangePromiseChain = this.handleCurrentSlideChangePromiseChain
                        .then(async () => {
                            if (this.props.currentSlide.id !== currentSlideId) {
                                // Current slide changed while we were waiting for the prev call
                                return;
                            }

                            await Promise.all([
                                PresentationEditorController.hideCanvasControls(),
                                this.calculateSlideLayout(true)
                            ]);
                        })
                        .then(resolve)
                        .catch(reject);
                });
            }

            setStateAsync(stateUpdate) {
                return new Promise(resolve => this.setState(stateUpdate, resolve));
            }

            recordOpenCloseActivity = async action => {
                const { presentation } = this.props;

                if (!this.state.isReady || this.props.isSingleSlideEditor || isPPTAddin) return;

                try {
                    const {
                        template_recommendations: { enabled: hasTemplateRecommendations }
                    } = await getExperiments(["template_recommendations"]);

                    await presentationsApi.recordActivity({
                        id: this.props.presentation.id,
                        activity: action === "open"
                            ? PresentationActivityType.EDITOR_OPENED
                            : PresentationActivityType.EDITOR_CLOSED,
                        activityData: action === "open" ? {
                            source_presentation_id: presentation.get("sourcePresentationId") || "",
                            source_presentation_name: presentation.get("sourcePresentationName") || "",
                            is_from_recommended_template: presentation.get("metadata")?.isFromRecommendedTemplate ?? false,
                            object: "library_item",
                            object_label: "",
                            action: "clicked",
                            experiment_id: "5FC18A79E182FE7C764425D4F852F501",
                            experiment_group_assignment: hasTemplateRecommendations ? "variant-a" : "control"
                        } : null
                    });
                } catch (err) {
                    logger.error(err, "[PresentationEditor] failed to record activity");
                }
            }

            trackSlideNavigation = ({ method }) => {
                const { presentation } = this.props;
                const deviceType = app.isMobileOrTablet ? "mobile" : "desktop";

                return trackActivity("Editor", "Navigated", null, null, {
                    presentation_id: presentation.id,
                    method: method,
                    deviceType: deviceType,
                    ownerId: presentation.userId,
                    userId: app.user.id,
                    source: "editor",
                    referrer: (document.referrer && !document.referrer.includes(location.host)) ? document.referrer : "direct",
                });
            }

            handleKeyDown = event => {
                const { presentation, currentCanvasController, currentSelectionLayerController, showSlideGrid, handleKeyEvents } = this.props;

                if (showSlideGrid || app.dialogManager.openDialogs.length || event.target.tagName === "INPUT" && event.target.type !== "checkbox" || event.target.contentEditable === "true" || !handleKeyEvents) {
                    return;
                }

                switch (event.which) {
                    case Key.DELETE:
                    case Key.BACKSPACE:
                        let handleKeyboardShortcut;
                        if (currentSelectionLayerController.selectedElements.length === 1) {
                            handleKeyboardShortcut = currentSelectionLayerController.selectedElements[0].getSelectionElement()?.uiRefs.selectionRef.current?.handleKeyboardShortcut;
                        } else {
                            handleKeyboardShortcut = currentCanvasController.canvas.layouter.elements.primary.getSelectionElement()?.uiRefs.selectionRef.current?.handleKeyboardShortcut;
                        }
                        if (handleKeyboardShortcut) {
                            handleKeyboardShortcut(event);
                        }
                        event.preventDefault();
                        return;
                    case Key.LEFT_ARROW:
                    case Key.UP_ARROW:
                    case Key.PAGE_UP:
                        PresentationEditorController.goPrevSlide();
                        this.trackSlideNavigation({ method: "tab" });
                        return;
                    case Key.RIGHT_ARROW:
                    case Key.DOWN_ARROW:
                    case Key.PAGE_DOWN:
                        PresentationEditorController.goNextSlide();
                        this.trackSlideNavigation({ method: "tab" });
                        return;
                    case Key.HOME:
                        this.goToSlide(0);
                        return;
                    case Key.END:
                        this.goToSlide(presentation.slides.length - 1);
                        return;
                    case Key.SPACE:
                        PresentationEditorController.toggleSlideGrid();
                        return;
                    case Key.TAB:
                        TabKeyController.handleTabKey(event);
                        return;
                }

                if (event.metaKey || event.ctrlKey) {
                    switch (event.which) {
                        case Key.KEY_Z:
                            if (event.shiftKey) {
                                PresentationEditorController.redo();
                            } else {
                                PresentationEditorController.undo();
                            }
                            event.stopPropagation();
                            event.preventDefault();
                            return;
                        case Key.KEY_E:
                            if (event.metaKey || event.ctrlKey && event.altKey) {
                                app.themeManager.loadTheme(presentation)
                                    .then(() => currentCanvasController.canvas.loadStyles(true))
                                    .then(() => currentSelectionLayerController.setSelectedElements([]))
                                    .then(() => {
                                        currentCanvasController.canvas.getCanvasElement().markStylesAsDirty();
                                        currentCanvasController.canvas.clearAuthoringBlockPropsCache();
                                    })
                                    .then(() => currentCanvasController.reloadCanvas(null, true));
                            }
                            break;
                        case Key.KEY_N:
                            PresentationEditorController.showAddSlideDialog();
                            event.preventDefault();
                            return;
                        case Key.KEY_D:
                            currentCanvasController.canvas.layouter.elements.primary.getSelectionElement().overlay?.handleKeyboardShortcut(event);
                            event.preventDefault();
                            return;
                        case Key.KEY_C:
                        case Key.KEY_X:
                            ClipboardController.onCopyOrCut(event);
                            return;
                    }
                }
            }

            handleResize = () => {
                this.calculateSlideLayout(false);
            }

            handleContextMenu = event => {
                if (isDevelopment && event.altKey) {
                    $(".current_slide").addClass("dev-click-suppress");
                    $(".current_slide").find("div").addClass("dev-right-click");
                    $(".default-overlay-container").find("div").addBack().removeClass("dev-right-click").addClass("dev-click-suppress");
                    $("#callouts, #connectors").removeClass("dev-right-click").addClass("dev-click-suppress");
                    return;
                }

                // don't prevent contextMenu on contentEditables
                if ($(event.target).closest("[contenteditable = true]").length) return;

                if (app.isEditingText) return;

                event.preventDefault();
                event.stopPropagation();
            }

            goToSlide = index => PresentationEditorController.setCurrentSlideByIndex(index)

            removeFileDropOverlay = $editorContainer => {
                $editorContainer.off("dragover drop dragenter dragleave");
            }

            calculateSlideLayout = async transition => {
                const { slides, currentSlide, activeRightPanel, activeBottomPanel, canvasControllers, isSingleSlideEditor, showPropertyPanel, selectedPropertyPanelTabProps } = this.props;
                const { canvasWidth, canvasHeight, bottomPanelHeight } = this.state;
                const prevSlideLayoutProps = this.state.slideLayoutProps;

                let canvasPaddingLeft, canvasPaddingRight;

                if (isSingleSlideEditor) {
                    canvasPaddingLeft = 30;
                    canvasPaddingRight = 30;
                } else {
                    canvasPaddingLeft = 140;
                    canvasPaddingRight = 140;
                    if (window.innerWidth < 1400) {
                        this.setState({ isSmallWindow: true });
                        canvasPaddingLeft = showPropertyPanel ? window.innerWidth * .02 : window.innerWidth * .02 + 55;
                        canvasPaddingRight = Math.max(window.innerWidth * .07, 95);
                    } else {
                        this.setState({ isSmallWindow: false });
                        canvasPaddingLeft = window.innerWidth * (showPropertyPanel ? .05 : .08);
                        canvasPaddingRight = window.innerWidth * .09;
                    }
                }

                const currentSlideIndex = slides.indexOf(currentSlide);

                const calcPrimaryCanvasScale = availableBounds => {
                    const controlSpacing = 90;
                    let scale = Math.min((availableBounds.height - controlSpacing) / canvasHeight, availableBounds.width / canvasWidth);
                    scale = Math.min(scale, 2);
                    return scale;
                };

                const $canvasContainer = $(this.canvasContainerRef.current);
                const $editorContainerRef = $(this.editorContainerRef.current);
                let containerBounds = new geom.Rect(SIDE_MENU_BAR_WIDTH, 0, $canvasContainer.width() - SIDE_MENU_BAR_WIDTH, $canvasContainer.height() - 20);

                if (showPropertyPanel) {
                    containerBounds = containerBounds.deflate({ left: (selectedPropertyPanelTabProps?.width ?? LEFT_PANEL_WIDTH) });
                }

                this.removeFileDropOverlay($editorContainerRef);

                let availableBounds = containerBounds.deflate({ left: canvasPaddingLeft, right: canvasPaddingRight });

                let primaryCanvasScale = calcPrimaryCanvasScale(availableBounds);
                let primaryCanvasSize = new geom.Size(canvasWidth, canvasHeight).scale(primaryCanvasScale);
                let primaryCanvasBounds = new geom.Rect(availableBounds.centerH - primaryCanvasSize.width / 2, containerBounds.top, primaryCanvasSize);

                // recalculate with the actual available container bounds depending on the visibility and position of any active panel
                if (activeRightPanel) {
                    if (RIGHT_PANEL_WIDTH > availableBounds.width - primaryCanvasBounds.right) {
                        availableBounds = availableBounds.deflate({ right: RIGHT_PANEL_WIDTH });
                    }
                }

                if (activeBottomPanel && bottomPanelHeight) {
                    if (bottomPanelHeight > availableBounds.height - primaryCanvasBounds.bottom - CONTROLS_HEIGHT) {
                        availableBounds = availableBounds.deflate({ bottom: bottomPanelHeight });
                    }
                }

                // recalculate primary canvas props in case availableBounds was changed by panels
                primaryCanvasScale = calcPrimaryCanvasScale(availableBounds);
                primaryCanvasSize = new geom.Size(canvasWidth, canvasHeight).scale(primaryCanvasScale);
                primaryCanvasBounds = new geom.Rect(availableBounds.centerH - primaryCanvasSize.width / 2, availableBounds.top, primaryCanvasSize);

                // calculate secondary canvas props
                const secondaryCanvasScale = primaryCanvasScale * SECONDARY_CANVAS_SCALE;
                const secondaryCanvasWidth = canvasWidth * secondaryCanvasScale;

                // set starting x position scrolled to current slide
                let x = primaryCanvasBounds.left - currentSlideIndex * (secondaryCanvasWidth + (showPropertyPanel ? 30 : 100));

                // reset wrappers
                this.canvasWrappers = {};

                let previousSlideIsCurrentSlide = false;

                // go through the slides and build layout props
                const slideLayoutProps = [];
                for (const slide of slides) {
                    const slideLayout = {
                        id: slide.id,
                        model: slide
                    };

                    if (slide === currentSlide) {
                        slideLayout.scale = primaryCanvasScale;
                        slideLayout.opacity = 1;
                        slideLayout.blur = 0;
                        slideLayout.isCurrent = true;
                        previousSlideIsCurrentSlide = true;
                    } else {
                        slideLayout.scale = primaryCanvasScale * SECONDARY_CANVAS_SCALE;
                        slideLayout.opacity = SECONDARY_SLIDE_OPACITY;
                        slideLayout.blur = 10;
                    }

                    slideLayout.x = x;
                    slideLayout.y = (availableBounds.top + (primaryCanvasBounds.height - (canvasHeight * slideLayout.scale)) / 2).toFixed(3);
                    slideLayout.width = canvasWidth * slideLayout.scale;
                    slideLayout.height = canvasHeight * slideLayout.scale;

                    if (previousSlideIsCurrentSlide) {
                        x += canvasWidth * slideLayout.scale + 100;
                        previousSlideIsCurrentSlide = false;
                    } else {
                        if (showPropertyPanel) {
                            x += canvasWidth * slideLayout.scale + 30;
                        } else {
                            x += canvasWidth * slideLayout.scale + 100;
                        }
                    }

                    slideLayoutProps.push(slideLayout);

                    const canvasController = canvasControllers[slideLayout.id];

                    // We need to set the canvasScale for each canvas
                    canvasController.canvasScale = slideLayout.scale;
                    canvasController.maxCanvasScale = Math.max(primaryCanvasScale, slideLayout.scale);
                }

                if (prevSlideLayoutProps && transition) {
                    const prevCurrentPropsIndex = prevSlideLayoutProps.findIndex(props => props.isCurrent);
                    const prevCurrentProps = prevSlideLayoutProps[prevCurrentPropsIndex];

                    const currentPropsIndex = slideLayoutProps.findIndex(props => props.isCurrent);
                    const currentProps = slideLayoutProps[currentPropsIndex];

                    if (prevCurrentPropsIndex === currentPropsIndex) {
                        if (currentProps.x === prevCurrentProps.x &&
                            currentProps.y === prevCurrentProps.y &&
                            currentProps.scale === prevCurrentProps.scale) {
                            transition = false;
                        }
                    }
                }

                const stateUpdates = {
                    slideLayoutProps,
                    primaryCanvasBounds,
                    primaryCanvasScale,
                    transition,
                    availableBounds,
                };

                await this.setStateAsync(stateUpdates);

                if (!transition) {
                    await this.handleCanvasTransitionEnded();
                }
            }

            handleCanvasMouseDown = (event, slideLayoutModel, isCurrentCanvas) => {
                const { currentCanvasController, isSingleSlideEditor } = this.props;

                if (currentCanvasController.isTemplateObsolete && isCurrentCanvas) {
                    event.stopPropagation();
                    event.preventDefault();
                    return;
                }

                if (!isCurrentCanvas) {
                    event.stopPropagation();
                    PresentationEditorController.setCurrentSlide(slideLayoutModel);
                    return;
                }

                if (slideLayoutModel.isLibrarySlide() && !isSingleSlideEditor) {
                    event.stopPropagation();
                    currentCanvasController.editLibrarySlide();
                }
            }

            handleCanvasTransitionEnded = async () => {
                const { showCanvasControls, currentSlide, presentation } = this.props;
                const { transition } = this.state;

                if (transition) {
                    this.setState({ transition: false });
                }

                if (!showCanvasControls) {
                    await PresentationEditorController.showCanvasControls();
                }

                if (this.currentSlideChangeRequest?.slideId === currentSlide.id) {
                    logger.metric(MetricName.ADVANCE_TO_SLIDE_TIME, {
                        presentationId: presentation.id,
                        slideId: currentSlide.id,
                        advanceTimeMs: Date.now() - this.currentSlideChangeRequest.timestamp
                    });
                }

                this.currentSlideChangeRequest = null;
            }

            handleRightPanelTransitionState = transitionState => {
                this.setState({
                    isTransitioningRightPanel: ["entering", "exiting"].includes(transitionState)
                });
            }

            handleBottomPanelTransitionState = (transitionState, bottomPanelHeight) => {
                this.setState({
                    isTransitioningBottomPanel: ["entering", "exiting"].includes(transitionState),
                    bottomPanelHeight
                });
            }

            handleBottomPanelResizeState = (state, bottomPanelHeight) => {
                // if (state === "start" || state === "end") {
                this.setState({
                    bottomPanelHeight,
                    // isResizingBottomPanel: state !== "end"
                    isResizingBottomPanel: state == "resize"
                });
                // }
            }

            handleCloseRightPanel = () => {
                PresentationEditorController.closeRightPanel();
            }

            handleCloseBottomPanel = () => {
                const { currentSelectionLayerController } = this.props;
                currentSelectionLayerController.setSelectedElements([]);
                PresentationEditorController.closeBottomPanel();
            }

            handleCloseSlideGrid = async focusedSlide => {
                await PresentationEditorController.toggleSlideGrid();
                await PresentationEditorController.setCurrentSlide(focusedSlide);
            }

            handleToggleNotes = () => {
                PresentationEditorController.toggleNotes();
            }

            handleShowNarration = () => {
                PresentationEditorController.showPropertyPanel();
                PresentationEditorController.setSelectedPropertyPanelTab("animate");
            }

            handleShowSlideHelp = () => {
                const { currentCanvasController } = this.props;
                let TemplateHelp = currentCanvasController.canvas.getPrimaryElement().getElementHelp();

                ShowDialog(TemplateHelp);
            }

            handleSetPanel = async panelType => {
                let width;
                switch (panelType) {
                    case "slide":
                        width = 400;
                        break;
                    default:
                        width = LEFT_PANEL_WIDTH;
                        break;
                }

                await PresentationEditorController.setSelectedPropertyPanelTab(panelType, { width });
            }

            render() {
                const {
                    activeRightPanel,
                    activeBottomPanel,
                    showSlideGrid,
                    hidePanels,
                    currentSlide,
                    showCanvasControls,
                    showSelectionLayer,
                    currentCanvasController,
                    currentSelectionLayerController,
                    isSingleSlideEditor,
                    allowSharedSlideEditing,
                    isTransitioningBottomPanel,
                    showAddElementDropZone,
                    canvasControllers,
                    showPropertyPanel,
                    selectedPropertyPanelTab,
                    selectedPropertyPanelTabProps,
                    currentSlideLockOwner,
                    isCurrentSlideLockedForMe,
                    allowStyleChange
                } = this.props;
                const {
                    availableBounds,
                    isReady,
                    slideLayoutProps,
                    primaryCanvasBounds,
                    transition,
                    isTransitioningRightPanel,
                    isResizingBottomPanel,
                    isSmallWindow
                } = this.state;

                if (!isReady) {
                    return <Spinner />;
                }

                const isSelectionLayerVisible = (
                    showSelectionLayer &&
                    !transition &&
                    !isTransitioningRightPanel &&
                    !isTransitioningBottomPanel &&
                    !isResizingBottomPanel
                );

                const availableSpaceBelowPrimaryCanvas = availableBounds.height - primaryCanvasBounds.bottom;

                const RightPanels = [
                    {
                        type: PanelType.COMMENTS,
                        width: RIGHT_PANEL_WIDTH,
                        children: <EditorCommentsPane goToSlide={this.goToSlide} />
                    }
                ]
                    .filter(({ type }) => !hidePanels.includes(type))
                    .map(({ type, width, children }) => (
                        <RightPanel
                            key={type}
                            visible={activeRightPanel === type}
                            reportTransitionState={this.handleRightPanelTransitionState}
                            onClose={this.handleCloseRightPanel}
                            width={width}
                        >
                            {children}
                        </RightPanel>
                    ));

                const BottomPanels = [
                    {
                        type: PanelType.ELEMENT,
                        height: Math.max(availableSpaceBelowPrimaryCanvas - CONTROLS_HEIGHT, 300),
                        children: <ElementPanelContainer {...this.props} onClose={this.handleCloseBottomPanel} />
                    },
                    {
                        type: PanelType.NOTES,
                        height: Math.max(availableSpaceBelowPrimaryCanvas - CONTROLS_HEIGHT, 300),
                        children: <SpeakerNotes {...this.props} onClose={this.handleCloseBottomPanel} />
                    }
                ]
                    .filter(({ type }) => !hidePanels.includes(type))
                    .map(({ type, height, children }) => (
                        <BottomPanel
                            key={type}
                            visible={activeBottomPanel === type}
                            leftPanelVisible={showPropertyPanel}
                            height={height}
                            availableBounds={availableBounds}
                            reportTransitionState={this.handleBottomPanelTransitionState}
                            reportResizeState={this.handleBottomPanelResizeState}
                            onClose={this.handleCloseBottomPanel}
                        >
                            {children}
                        </BottomPanel>
                    ));

                return (
                    <DndProvider backend={HTML5Backend}>
                        <Container>
                            <ThemeProvider theme={dialogTheme}>
                                {!isSingleSlideEditor && <PresentationMenuBar />}
                            </ThemeProvider>
                            <InnerContainer>
                                <EditorContainer id="editor-container" ref={this.editorContainerRef}>
                                    <CanvasContainer
                                        id="canvas-container"
                                        ref={this.canvasContainerRef}
                                    >
                                        {primaryCanvasBounds && (
                                            <>
                                                {slideLayoutProps.map(slideLayout => {
                                                    if (!this.canvasWrappers[slideLayout.id]) {
                                                        this.canvasWrappers[slideLayout.id] = canvasControllers[slideLayout.id].withState(CanvasWrapper);
                                                    }
                                                    const CanvasWrapperWithState = this.canvasWrappers[slideLayout.id];
                                                    const isCurrentCanvas = slideLayout.model === currentSlide;
                                                    return (
                                                        <PositionedCanvas
                                                            key={slideLayout.id}
                                                            className="positioned-canvas"
                                                            layout={slideLayout}
                                                            showTransition={transition}
                                                            onTransitionEnd={isCurrentCanvas ? this.handleCanvasTransitionEnded : () => {
                                                            }}
                                                            onMouseDown={event => this.handleCanvasMouseDown(event, slideLayout.model, isCurrentCanvas)}
                                                        >
                                                            <CanvasWrapperWithState />
                                                        </PositionedCanvas>
                                                    );
                                                })}

                                                <AbsoluteBox left={primaryCanvasBounds.left + ((isSmallWindow || isSingleSlideEditor) ? 43 : 0)} top={primaryCanvasBounds.top - 30} style={{ transition: `left ${SLIDE_TRANSITION_DURATION_MS}ms` }}>
                                                    <UndoRedo />
                                                </AbsoluteBox>

                                                {primaryCanvasBounds && currentSlide && (
                                                    <SelectionLayerContainer
                                                        canvasBounds={primaryCanvasBounds}
                                                        visible={isSelectionLayerVisible}
                                                    >
                                                        <SelectionLayer isSingleSlideEditor={isSingleSlideEditor} />
                                                    </SelectionLayerContainer>
                                                )}

                                                {showAddElementDropZone && (
                                                    <AddElementDropZone
                                                        currentCanvasController={currentCanvasController}
                                                        currentSelectionLayerController={currentSelectionLayerController}
                                                    />
                                                )}

                                                {showCanvasControls && currentSlide.has("audioAsset") && (
                                                    <AbsoluteBox left={primaryCanvasBounds.right - 60} top={primaryCanvasBounds.bottom + 14}>
                                                        <MenuBarIconButton onClick={this.handleShowNarration}>
                                                            <Icon fill>record_voice_over</Icon>
                                                            <label className="hover-label">Slide Narration</label>
                                                        </MenuBarIconButton>
                                                    </AbsoluteBox>
                                                )}

                                                {showCanvasControls && !isPPTAddin && (
                                                    <AbsoluteBox left={primaryCanvasBounds.right - 20} top={primaryCanvasBounds.bottom + 15}>
                                                        <MenuBarIconButton onClick={this.handleToggleNotes}>
                                                            <Icon fill>{activeBottomPanel === PanelType.NOTES ? "speaker_notes_off" : "speaker_notes"}</Icon>
                                                            <label className="hover-label">Toggle Notes</label>
                                                        </MenuBarIconButton>
                                                    </AbsoluteBox>
                                                )}

                                                {currentSlide?.isLibrarySlide() && !isSingleSlideEditor && (
                                                    <AbsoluteBox left={primaryCanvasBounds.left} top={primaryCanvasBounds.top - 17} width={primaryCanvasBounds.width}>
                                                        <FlexBox fillWidth>
                                                            <LibrarySlideWarning>
                                                                <Icon white>lock</Icon>
                                                                This is a team shared slide and cannot be edited directly
                                                            </LibrarySlideWarning>
                                                        </FlexBox>
                                                    </AbsoluteBox>
                                                )}

                                                {isCurrentSlideLockedForMe && (
                                                    <AbsoluteBox left={primaryCanvasBounds.left} top={primaryCanvasBounds.top - 17} width={primaryCanvasBounds.width}>
                                                        <FlexBox fillWidth>
                                                            <LibrarySlideWarning>
                                                                <Icon white>lock</Icon>{currentSlideLockOwner.displayName} is editing this slide
                                                            </LibrarySlideWarning>
                                                        </FlexBox>
                                                    </AbsoluteBox>
                                                )}

                                            </>
                                        )}
                                    </CanvasContainer>

                                    {primaryCanvasBounds && !isSingleSlideEditor && (
                                        <RightCanvasControls
                                            canvasBounds={primaryCanvasBounds}
                                            visible={showCanvasControls}
                                            transition={transition}
                                        >
                                            <SlideActions />
                                        </RightCanvasControls>
                                    )}

                                    <ShortcutPanel />

                                    <SideMenuBar>
                                        <SideMenuBarButton onClick={() => this.handleSetPanel("element")} selected={showPropertyPanel && selectedPropertyPanelTab === "element"}>
                                            <Gap5 />
                                            <div className="bai-icon material-symbols-outlined">app_registration</div>
                                            <label>Edit</label>
                                        </SideMenuBarButton>
                                        <SideMenuBarButton onClick={() => this.handleSetPanel("add")} selected={showPropertyPanel && selectedPropertyPanelTab === "add"}>
                                            <div className="bai-icon material-symbols-outlined">add</div>
                                            <label>Add</label>
                                        </SideMenuBarButton>
                                        <SideMenuBarButton onClick={() => this.handleSetPanel("ideas")} selected={showPropertyPanel && selectedPropertyPanelTab === "ideas"}>
                                            <div className="bai-icon material-symbols-outlined">emoji_objects</div>
                                            <label>Ideas</label>
                                        </SideMenuBarButton>
                                        <SideMenuBarButton onClick={() => this.handleSetPanel("styles")} selected={showPropertyPanel && selectedPropertyPanelTab === "styles"}>
                                            <div className="bai-icon material-symbols-outlined">palette</div>
                                            <label>Style</label>
                                        </SideMenuBarButton>
                                        <SideMenuBarButton onClick={() => this.handleSetPanel("animate")} selected={showPropertyPanel && selectedPropertyPanelTab === "animate"}>
                                            <div className="bai-icon material-symbols-outlined">play_circle</div>
                                            <label>Playback</label>
                                        </SideMenuBarButton>
                                        <Divider color="#444" />
                                        <SideMenuBarButton onClick={() => this.handleSetPanel("manage-slide")} selected={showPropertyPanel && selectedPropertyPanelTab === "manage-slide"}>
                                            <div className="bai-icon material-symbols-outlined">more_vert</div>
                                            <label>Options</label>
                                        </SideMenuBarButton>
                                    </SideMenuBar>

                                    <ThemeProvider theme={dialogTheme}>

                                        <LeftPanel open={showPropertyPanel} width={selectedPropertyPanelTabProps?.width ?? 280}>
                                            <EditorPropertyPanel isSingleSlideEditor={isSingleSlideEditor}
                                                allowStyleChange={allowStyleChange}
                                                allowSharedSlideEditing={allowSharedSlideEditing}
                                            />
                                        </LeftPanel>

                                        {RightPanels}

                                        {BottomPanels}
                                    </ThemeProvider>

                                    {!isSingleSlideEditor && (
                                        <Transition in={showSlideGrid} timeout={500} mountOnEnter unmountOnExit>
                                            {state => (
                                                <SlideGridContainer state={state}>
                                                    <SlideGridWrapper onClose={this.handleCloseSlideGrid} />
                                                </SlideGridContainer>
                                            )}
                                        </Transition>
                                    )}
                                </EditorContainer>
                            </InnerContainer>
                        </Container>
                    </DndProvider>
                );
            }
        }
    )
);

const PresentationEditorInitializer =
    PresentationEditorController.withState(
        function PresentationEditorInitializer({
            presentationId,
            initialSlideIndex,
            isSingleSlideEditor,
            onLoaded,
            hidePanels,
            allowThemeChange,
            allowSharedSlideEditing = false,
            renderSettings,
            pane,
            dummyPresentation = null,
            allowStyleChange = true,
            // Comes from PresentationEditorController
            presentation,
            isInitialized,
        }) {
            useEffect(() => {
                const name = presentation?.get("name");
                if (name) {
                    document.title = "Beautiful.ai - " + name;
                } else {
                    document.title = "Beautiful.ai";
                }

                return () => {
                    document.title = "Beautiful.ai";
                };
            }, [presentation?.get("name")]);

            useEffect(() => {
                const loadStartTime = Date.now();

                (async () => {
                    // If we are on mobile, we need to load the presentation in the
                    // presentation player mode and not editor
                    if (app.isMobileOrTablet) {
                        AppController.playPresentation({ presentationId, slideIndex: initialSlideIndex });
                        return;
                    }

                    if (!presentationId) {
                        await PresentationEditorController.reset();
                        return;
                    }

                    const presentation = dummyPresentation ?? await getPresentation(presentationId, { permission: "write", autoSync: true });

                    // If collaborator with read-only access, redirect to player
                    if (presentation.permissions.read && !presentation.permissions.write) {
                        AppController.playPresentation({ presentationId, slideIndex: initialSlideIndex }, { goBackToLibrary: true });
                        return;
                    }

                    await PresentationEditorController.loadPresentation(presentation, initialSlideIndex, isSingleSlideEditor, allowThemeChange, hidePanels);

                    if (renderSettings) {
                        ShowDialog(PresentationSettingsContainer, { startPane: pane, presentation });
                    }

                    logger.metric(MetricName.EDITOR_LOAD_TIME, {
                        presentationId,
                        loadTimeMs: Date.now() - loadStartTime,
                        loadFailed: false
                    });
                })().catch(err => {
                    logger.error(err, "[PresentationEditorInitializer] failed to load presentation", err);

                    if (err instanceof PresentationPermissionDeniedError) {
                        ShowDialog(RequestAccessDialog, {
                            presentationOrLinkId: presentationId,
                            contextType: RequestAccessDialogContextType.EDITOR
                        });
                    } else if (err instanceof PresentationNotFoundError) {
                        ShowErrorDialog({
                            title: "Presentation not found",
                            message: "Presentation not found, make sure your link is correct.",
                            acceptCallback: () => AppController.showLibrary()
                        });
                    } else if (err instanceof FailedToRenderCanvasError) {
                        ShowErrorDialog({
                            title: "Failed to render presentation",
                            message: <>Sorry, we couldn't load some slides in your presentation, please contact us
                                at <a href="mailto:support@beautiful.ai">support@beautiful.ai</a></>,
                            acceptCallback: () => AppController.showLibrary()
                        });
                    } else {
                        ShowErrorDialog({
                            title: "Failed to load presentation",
                            message: "Sorry, something went wrong, please try again later.",
                            acceptCallback: () => AppController.showLibrary()
                        });
                    }

                    logger.metric(MetricName.EDITOR_LOAD_TIME, {
                        presentationId,
                        loadTimeMs: Date.now() - loadStartTime,
                        loadFailed: true
                    });
                });

                return () => {
                    (async () => {
                        // First, mark the presentation as dirty so that the thumbnails show spinners
                        if (PresentationLibraryController.isInitialized) {
                            PresentationLibraryController.markPresentationsDirty([presentationId]);
                        }

                        // Controller reset will trigger finishedEditing for current slide that may update the presentation
                        // timestamps
                        await PresentationEditorController.reset();

                        // Force refresh the presentation in the library so that the timestamps and thumbnails are updated
                        if (PresentationLibraryController.isInitialized) {
                            PresentationLibraryController.forceRefreshPresentations([presentationId]);
                        }
                    })();
                };
            }, [presentationId]);

            if (!isInitialized) {
                return <Spinner />;
            }

            return (
                <PresentationEditor isSingleSlideEditor={isSingleSlideEditor}
                    allowStyleChange={allowStyleChange}
                    allowSharedSlideEditing={allowSharedSlideEditing}
                    onLoaded={onLoaded}
                />
            );
        }
    );

export default PresentationEditorInitializer;

