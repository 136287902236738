import React, { useState } from "react";
import styled from "styled-components";
import { v4 as uuid } from "uuid";

import { DialogActions, DialogTitle } from "@material-ui/core";

import {
    IWorkspaceUserInvite,
    TeamMemberRole as LegacyTeamMemberRole,
    LegacyWorkspaceGroupIds,
    TeamMemberRole,
    WorkspaceUserRole,
    WorkspacePlanTier
} from "common/interfaces";
import { Button } from "js/Components/Button";
import { Dropdown } from "js/Components/Dropdown";
import { MenuItem } from "js/Components/Menu";
import BillingController, { BillingControllerState } from "js/controllers/BillingController";
import WorkspaceController, { WorkspaceControllerState } from "js/controllers/WorkspaceController";
import getLogger, { LogGroup } from "js/core/logger";
import { emailRegex } from "js/core/utilities/regex";
import AddTeamMembers from "js/react/components/AddTeamMembers";
import { BeautifulDialog, DialogContent, ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import FetchingClickShield from "js/react/components/FetchingClickShield";
import TextArea from "js/react/components/TextArea";
import { _ } from "js/vendor";
import { themeColors } from "js/react/sharedStyles";

import { AdditionalSeatsInvoiceInfo } from "./AdditionalSeatsInvoiceInfo";

export const logger = getLogger(LogGroup.BILLING);

const StyledDialogContent = styled(DialogContent)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
`;

const RoleDropdownContainer = styled.div`
    .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu {
        text-transform: uppercase;
        color: #333;
        font-weight: 600;
        font-size: 14px;
    }
`;

const InfoMessage = styled.div<{ red?: boolean }>`
    font-size: 14px;
    font-weight: 600;
    color: ${props => props.red ? themeColors.warning : "#00df00"};

    >a {
        text-decoration: none;
        color: ${themeColors.ui_blue};
        cursor: pointer;
    }
`;

export function RoleDropdown(props: {
    value: LegacyTeamMemberRole,
    onChange: (value: LegacyTeamMemberRole) => void,
    disabled?: boolean
}) {
    const { value, onChange, disabled } = props;

    return (<RoleDropdownContainer>
        <Dropdown variant="standard" value={value} onChange={onChange} disabled={disabled}>
            {Object.values(LegacyTeamMemberRole).map(role => (
                <MenuItem key={role} value={role}>{TeamMemberRole[role]}</MenuItem>
            ))}
        </Dropdown>
    </RoleDropdownContainer>);
}

interface InviteMember {
    id: string;
    email: string;
    role: LegacyTeamMemberRole;
}

export interface InviteUsersDialogProps {
    closeDialog: () => void;
}

export const InviteUsersDialog = WorkspaceController.withInitializedState(BillingController.withInitializedState(React.forwardRef(
    function InviteUsersDialog(props: InviteUsersDialogProps & WorkspaceControllerState & BillingControllerState, ref: React.Ref<any>) {
        const { closeDialog, workspace, users, plan } = props;

        const maxAdditionalSeatCount = BillingController.canChangeSubscriptionQuantity ? undefined : BillingController.availableSeatCount;

        const [inviting, setInviting] = useState(false);
        const [members, setMembers] = useState<InviteMember[]>([{ id: uuid(), email: "", role: maxAdditionalSeatCount === 0 ? LegacyTeamMemberRole.MEMBER : LegacyTeamMemberRole.MEMBER_LICENSED }]);
        const [customMessage, setCustomMessage] = useState("");

        const filteredMembers = members.filter(m => m.email);
        const membersAreValid = filteredMembers.length > 0 &&
            !filteredMembers.some(member => member.email.length > 0 && !emailRegex.test(member.email)) &&
            !filteredMembers.some(member => filteredMembers.some(otherMember => member.email && member.email.toLowerCase() === otherMember.email.toLowerCase() && member.id !== otherMember.id)) &&
            !filteredMembers.some(member => users.some(user => user.email.toLowerCase() === member.email.toLowerCase()));
        const additionalSeatCount = filteredMembers.filter(m => m.role !== LegacyTeamMemberRole.MEMBER && m.email).length - BillingController.availableSeatCount;

        const paidSeatsAvailable = BillingController.canChangeSubscriptionQuantity ? true : additionalSeatCount < 0;
        const availableRoles = paidSeatsAvailable ? Object.values(LegacyTeamMemberRole) : [LegacyTeamMemberRole.MEMBER];

        const canInvite = (BillingController.canChangeSubscriptionQuantity || additionalSeatCount <= 0) && membersAreValid;

        const handleInvite = async () => {
            setInviting(true);

            try {
                if (additionalSeatCount > 0) {
                    await BillingController.updateSubscriptionQuantity(BillingController.paidSeatCount + additionalSeatCount);
                }

                const users: Pick<IWorkspaceUserInvite, "email" | "role" | "groupIds">[] = filteredMembers.map(m => ({
                    email: m.email,
                    role: m.role === LegacyTeamMemberRole.OWNER
                        ? WorkspaceUserRole.OWNER
                        : m.role === LegacyTeamMemberRole.MEMBER
                            ? WorkspaceUserRole.GUEST
                            : WorkspaceUserRole.MEMBER,
                    groupIds: m.role === LegacyTeamMemberRole.LIBRARIAN ? [LegacyWorkspaceGroupIds.LIBRARIANS] : []
                }));
                await WorkspaceController.inviteWorkspaceUsers(users);

                closeDialog();
            } catch (err) {
                logger.error(err, "[InviteUsersDialog] Failed to invite members");

                ShowErrorDialog({
                    title: "Failed to invite members",
                    message: err.message
                });
            }

            setInviting(false);
        };

        const handleAddMember = () => {
            setMembers(_.cloneDeep([...members, { id: uuid(), email: "", role: paidSeatsAvailable ? LegacyTeamMemberRole.MEMBER_LICENSED : LegacyTeamMemberRole.MEMBER }]));
        };

        const handleUpdateMember = (id: string, propKey: keyof InviteMember, propValue: any) => {
            setMembers(_.cloneDeep(members.map(m => m.id === id ? { ...m, [propKey]: propValue } : m)));
        };

        const handleRemoveMember = (id: string) => {
            setMembers(_.cloneDeep(members.filter(m => m.id !== id)));
        };

        return (<BeautifulDialog closeDialog={closeDialog} ref={ref} data-test-id="invite-users-dialog">
            <FetchingClickShield visible={inviting} backgroundColor="#fff" />
            <DialogTitle>Invite people to join {workspace.name}</DialogTitle>
            <StyledDialogContent>
                <AddTeamMembers
                    members={members}
                    onAddMember={handleAddMember}
                    onUpdateMember={handleUpdateMember}
                    onRemoveMember={handleRemoveMember}
                    availableRoles={availableRoles}
                />
                {/* @ts-ignore */}
                <TextArea
                    isVisible
                    text={customMessage}
                    onChange={setCustomMessage}
                    placeholder="Add a note..."
                />
                {!inviting && additionalSeatCount > 0 && BillingController.canChangeSubscriptionQuantity &&
                    <AdditionalSeatsInvoiceInfo additionalSeatCount={additionalSeatCount} />
                }
                {!BillingController.canChangeSubscriptionQuantity && <>
                    {BillingController.availableSeatCount === 0 &&
                        <InfoMessage red>
                            You have no available {plan.name} Pro seats.
                            &nbsp;<a href={`mailto:${plan.tier === WorkspacePlanTier.ENTERPRISE ? "accounts@beautiful.ai" : "support@beautiful.ai"}`}>Contact us to add more seats.</a>
                        </InfoMessage>
                    }
                    {BillingController.availableSeatCount > 0 &&
                        <InfoMessage>
                            {BillingController.availableSeatCount} more {plan.name} Pro seat{BillingController.availableSeatCount === 1 ? "" : "s"} available.
                        </InfoMessage>
                    }
                </>}
            </StyledDialogContent>
            <DialogActions>
                <Button onClick={closeDialog} unframed large>
                    Cancel
                </Button>
                <Button blue large onClick={handleInvite} disabled={!canInvite} data-test-id="invite-users-dialog-invite-button">
                    {additionalSeatCount > 0 ? `Add seats + send invites` : "Invite organization members"}
                </Button>
            </DialogActions>
        </BeautifulDialog>);
    }
)));
